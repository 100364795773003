import React, { useState, useEffect } from "react";
import axios from "../api/baseurl";
import { useNavigate, useParams } from "react-router-dom";
import { AiOutlineArrowLeft, AiOutlineDelete } from "react-icons/ai";
import { IconButton, Tooltip, Modal, Button } from "@mui/material";
import AdminDashboard from "./admindashboard";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { toast } from "react-toastify";

function EditInvoice() {




    const { id } = useParams();
    const navigate = useNavigate();
    const [invoiceDetails, setInvoiceDetails] = useState({});
    const [consumerdata, setConsumerdata] = useState(null);
    const [barcodeModalOpen, setBarcodeModalOpen] = useState(false);
    const [barcode, setBarcode] = useState('');
    const [error, setError] = useState(null);
    const [productData, setProductData] = useState([]);
    console.log(productData);
    const [files, setFiles] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showTaxDetails, setShowTaxDetails] = useState(false);
    const [productList, setProductList] = useState([]);
    const [orgprofile, setOrgprofile] = useState([]);
    const orgcodes = JSON.parse(localStorage.getItem("data")).orgcode;




    useEffect(() => {
        if (id) {
            fetchQuotationData(id);
        }

    }, [id]);

    useEffect(() => {
        if (invoiceDetails?.consumername) {
            fetchConsumerData(invoiceDetails.consumername);
        }
        fetchProductList();
        fetchOrganization();
    }, [invoiceDetails]);

    const fetchQuotationData = async (id) => {
        setLoading(true);
        try {
            const token = JSON.parse(localStorage.getItem("data")).token;
            const response = await axios.get(`/api/invoice/id=${id}`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            if (response.data) {
                setInvoiceDetails(response.data);
                setProductData(response.data.invoicedetails || []); // Extract quotes data and set it to productData state
                setFiles(response.data.files || []); // Extract quotes data and set it to productData state

            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
            handleError(error);
        }
    };
    const fetchOrganization = async () => {
        setLoading(true);
        setError(null);
        try {
            const token = JSON.parse(localStorage.getItem("data")).token;
            const response = await axios.get(`/api/organizationprofiles/Orgcode=${orgcodes}`, {
                headers: { Authorization: `Bearer ${token}` }
            });
            setOrgprofile(response.data);
            console.log(response.data);

        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    const fetchConsumerData = async () => {
        setLoading(true);
        const Consumername = invoiceDetails.consumername;
        console.log(Consumername);
        try {

            const formattedConsumername = Consumername.replace(/\//g, '-');
            const token = JSON.parse(localStorage.getItem("data")).token;
            const response = await axios.get(`/api/consumerdetails/consumername=${formattedConsumername}`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            if (response.data && response.data.length > 0) {
                setConsumerdata(response.data[0]);
            } else {
                setConsumerdata(null);
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
            handleError(error);
        }
    };

    const fetchProductList = async () => {
        setLoading(true);
        try {
            const token = JSON.parse(localStorage.getItem("data")).token;
            const response = await axios.get(`/api/product/orgcode=${orgcodes}`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            if (response.data) {
                setProductList(response.data);
                setLoading(false);
            }

        } catch (error) {
            setLoading(false);
            toast.error("Error fetching product list");
        }
    };

    const handleFileClick = (filedata, filename = 'attachment') => {
        const byteCharacters = atob(filedata);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);

        const fileBlob = new Blob([byteArray], { type: 'application/octet-stream' });

        const link = document.createElement('a');
        link.href = URL.createObjectURL(fileBlob);
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };


    const handleFileUpload = (event) => {
        const uploadedFile = event.target.files[0];

        if (uploadedFile) {
            const reader = new FileReader();

            reader.onload = () => {
                const base64FileData = reader.result.split(',')[1];
                const newFile = {
                    filedata: base64FileData,

                };
                setFiles(prevFiles => [...prevFiles, newFile]);
            };

            reader.readAsDataURL(uploadedFile);
        }
    };



    const calculateSubtotal = () => {
        return productData.reduce((sum, product) => {
            const quantity = parseFloat(product.quantity) || 0;
            const price = parseFloat(product.price) || 0;
            return sum + (quantity * price);
        }, 0).toFixed(2);
    };

    const calculateSubtotalAfterDiscount = () => {
        const subtotal = parseFloat(calculateSubtotal());

        const discountAmount = invoiceDetails.discount;
        const subtotalAfterDiscount = subtotal - discountAmount;
        return subtotalAfterDiscount.toFixed(2);
    };

    const toggleTaxDetails = () => {
        setShowTaxDetails(!showTaxDetails);
    };

    const TotalTaxAmount = (productData) => {
        return productData.reduce((sum, product) => {
            const tax = parseFloat(product.tax);
            const Beforeamount = parseFloat(product.beforetax)
            const totaltax = (tax * Beforeamount) / 100;

            return sum + totaltax;
        }, 0).toFixed(2);
    };





    const calculateRoundingOff = () => {
        const totalBeforeRounding = parseFloat(calculateSubtotalAfterDiscount()) + parseFloat(TotalTaxAmount(productData));
        const decimalPart = totalBeforeRounding - Math.floor(totalBeforeRounding);

        let roundOffAmount;
        if (decimalPart > 0.5) {
            roundOffAmount = Math.ceil(totalBeforeRounding) - totalBeforeRounding;
        } else {
            roundOffAmount = Math.floor(totalBeforeRounding) - totalBeforeRounding;
        }


        return {
            roundedTotal: totalBeforeRounding + roundOffAmount,
            roundOff: roundOffAmount.toFixed(2)
        };
    };
    const resultValue = calculateRoundingOff();

    const handleError = (error) => {
        if (error.response && error.response.status === 404) {
            toast.error(error.response.data.message);
        } else {
            toast.error("An error occurred while fetching the data");
        }
    };

    const addNewProductRow = () => {
        const newProductRow = {
            product: "",
            productcode: "",
            description: "",
            quantity: "",
            uom: "",
            price: "",
            beforetax: "",
            tax: "",
            amount: "",
            editable: true,
        };
        setProductData((prevProductData) => [...prevProductData, newProductRow]);
    };

    const handleProductChange = (index, selectedProduct) => {
        const updatedProductData = productData.map((product, i) => {
            if (i === index) {
                // Check if selectedProduct and pprice exist before accessing price
                const price = selectedProduct?.pprice?.price ? parseFloat(selectedProduct.pprice.price) : 0;
                const quantity = 1; // Default quantity
                const gstRate = parseFloat(selectedProduct.gst) || 0;

                const cgstRate = gstRate / 2;
                const sgstRate = gstRate / 2;

                const subtotal = price * quantity;
                const cgstAmount = (subtotal * cgstRate) / 100;
                const sgstAmount = (subtotal * sgstRate) / 100;
                const totalAmount = subtotal + cgstAmount + sgstAmount;

                return {
                    ...product,
                    product: selectedProduct.productname || "",
                    productcode: selectedProduct.productcode || "",
                    description: selectedProduct.description || "",
                    uom: selectedProduct.uom || "",
                    quantity: 1,
                    price: price.toFixed(2),
                    beforetax: subtotal.toFixed(2),
                    tax: gstRate,
                    amount: totalAmount.toFixed(2),
                };
            }
            return product;
        });

        setProductData(updatedProductData);
    };



    const clearAllItems = () => {
        setProductData([]);
        localStorage.removeItem('productData');
    };
    const handleFieldChange = (index, fieldName, value) => {
        const updatedProductData = productData.map((product, i) => {
            if (i === index) {
                const updatedProduct = { ...product, [fieldName]: value };

                const price = parseFloat(updatedProduct.price) || 0;
                const quantity = parseFloat(updatedProduct.quantity) || 1;
                const gstRate = parseFloat(product.tax) || 0;

                const cgstRate = gstRate / 2;
                const sgstRate = gstRate / 2;

                const subtotal = price * quantity;

                const cgstAmount = (subtotal * cgstRate) / 100;

                const sgstAmount = (subtotal * sgstRate) / 100;
                const totalAmount = subtotal + cgstAmount + sgstAmount;

                updatedProduct.uom = updatedProduct.uom;
                updatedProduct.beforetax = subtotal.toFixed(2);
                updatedProduct.amount = totalAmount.toFixed(2);

                return updatedProduct;
            }
            return product;
        });

        setProductData(updatedProductData);
    };




    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setInvoiceDetails((prevDetails) => ({
            ...prevDetails,
            [name]: value
        }));
    };

    const handleBackClick = () => {
        navigate(-1);
    };
    // const handlePrint = () => {
    //     window.print();
    // };
    const handleClose = () => {
        navigate(-1);
    };

    const handleUpdateQuotation = async () => {




        if (!invoiceDetails.duedate) {
            toast.warning("Please fill duedate it's mandatory fields!");
            return;
        }
        if (!invoiceDetails.shibydate) {
            toast.warning("Please fill  shipbydate it's mandatory fields!");
            return;
        }
        setLoading(true);

        try {

            const token = JSON.parse(localStorage.getItem("data")).token;


            const requestBody = {
                id: invoiceDetails.id,
                consumername: invoiceDetails.consumername,
                shibydate: invoiceDetails.shibydate,
                invoicedate: invoiceDetails.invoicedate,
                duedate: invoiceDetails.duedate,
                memo: invoiceDetails.memo,
                totalAmount: calculateRoundingOff().roundedTotal,
                discount: invoiceDetails.discount || 0, // Discount if any, otherwise default to 0

                invoicedetails: productData.map((product) => ({
                    invoiceid: invoiceDetails.id,
                    id: product.id || null,
                    product: product.product,
                    productcode: product.productcode,
                    description: product.description,
                    quantity: product.quantity,
                    uom: product.uom,
                    price: product.price,
                    tax: product.tax,
                    amount: product.amount,
                    beforetax: product.beforetax
                })),
                files: files.map((file) => ({
                    invoiceid: invoiceDetails.id,
                    id: file.id || null,
                    filedata: file.filedata
                }))
            };


            const response = await axios.post(`/api/invoice/update`, requestBody, {
                headers: { Authorization: `Bearer ${token}` },
            });
            navigate(-1);
            toast.success("Invoice updated successfully!");
            setLoading(false);



        } catch (error) {
            setLoading(false);
            toast.error("Failed to update the Invoice.");
        }
    };


    const handleDeleteData = async (invoiceid) => {
        const token = JSON.parse(localStorage.getItem("data")).token;

        try {
            const response = await axios.delete(`/api/invoicedetails/delete/invoiceid=${invoiceid}`, {
                method: 'DELETE',
                headers: { Authorization: `Bearer ${token}` },
            });

            if (response.ok) {
                setInvoiceDetails(invoiceDetails.filter((invoice) =>  invoice.id !== invoiceid));

                return invoiceDetails;
            } else {
                console.error('Failed to delete the row:', response.status);
                return false;
            }
        } catch (error) {
            console.error('Error:', error);
            return false;
        }
    }
    const toggleBarcodeModal = () => {
        setBarcodeModalOpen(!barcodeModalOpen);
        setError('');
        setBarcode('');
    };

    const handleCheckProduct = async () => {
        setLoading(true);
        const token = JSON.parse(localStorage.getItem("data")).token;
        const productCode = barcode;
        try {

            const response = await axios.get(`/api/product/price/productcode=${productCode}`, {
                headers: { Authorization: `Bearer ${token}` },
            });


            if (response.data && response.data.length > 0) {
                const foundProduct = response.data[0];


                const price = foundProduct.productprice.price || 0;
                const gstRate = foundProduct.gst || 0;
                const quantity = 1;

                const cgstRate = gstRate / 2;
                const sgstRate = gstRate / 2;

                const subtotal = price * quantity;

                const cgstAmount = (subtotal * cgstRate) / 100;

                const sgstAmount = (subtotal * sgstRate) / 100;
                const totalAmount = subtotal + cgstAmount + sgstAmount;

                const newProductRow = {
                    product: foundProduct.productname,
                    productcode: foundProduct.productcode,
                    description: foundProduct.description,
                    quantity: quantity,
                    uom: foundProduct.uom,
                    price: price,
                    beforetax: price.toFixed(2),
                    tax: gstRate,
                    amount: totalAmount.toFixed(2), // Calculate amount using price
                    editable: true,
                };

                setProductData((prevProductData) => [...prevProductData, newProductRow]);
                toggleBarcodeModal();

                toast.success("Product found and added to the table");
                setLoading(false);
            } else {
                toast.warn("Product not found");
            }
        } catch (error) {
            setLoading(false);
            console.error("Error occurred during product fetch:", error);
            toast.error("Error fetching product");
        }
    };







    return (
        <div>
            <AdminDashboard />
            <br />
            <br />
            <br />
            <br />
            {loading && <div className="loading"></div>}

            <div className="col-12 mx-xl-auto ps-xl-10">
                <div
                    style={{
                        width: '100%',
                        height: '50px',
                        backgroundColor: 'lightgray',
                        marginBottom: '20px',
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <div>
                        <h4 style={{ marginRight: 0 }}>Invoice Details</h4>
                    </div>
                </div>

                <Tooltip title="Back" arrow>
                    <IconButton
                        onClick={handleBackClick}
                        sx={{
                            padding: '2px',
                            backgroundColor: 'darkgray',
                            color: 'blue',
                            borderRadius: '10px',
                            width: "60px",
                            height: "30px",
                            left: '3%',
                            '&:hover': { backgroundColor: 'darkgray' }
                        }}
                    >
                        <AiOutlineArrowLeft style={{ fontSize: '25px' }} />
                    </IconButton>
                </Tooltip>

                <div className="container" style={{ padding: '30px' }}>
                    <div className="row">
                        <div className="col-7" style={{ marginLeft: 'auto' }}>
                            <p style={{ marginBottom: '5px', fontWeight: 'bold' }}>{orgprofile?.name}</p>
                            <p style={{ marginBottom: '5px' }}>{`${orgprofile?.addressLine1}, ${orgprofile?.addressLine2}`}</p>
                            <p style={{ marginBottom: '5px' }}>{orgprofile?.pincode}</p>
                            <p style={{ marginBottom: '5px' }}>{orgprofile?.contact}</p>
                            <p style={{ marginBottom: '0' }}>GSTIN : {orgprofile?.gstin}</p>
                            <p style={{ marginBottom: '0' }}>#No : {invoiceDetails?.quotationid}</p>
                        </div>
                        <div className="col-5 d-flex flex-column justify-content-center align-items-center">
                            <img
                                className="img-fluid mb-1"
                                id="image"
                                src={`${orgprofile?.imageMeta},${orgprofile?.logo}`}
                                alt="Organization Logo"
                                style={{
                                    width: "100px",
                                    height: "100px",
                                    background: "#ccc",
                                    display: "block",
                                }}
                            />

                        </div>
                    </div>

                    {/* Consumer Details Section */}
                    <div style={{ padding: '20px' }}>
                        <h5>To</h5>
                        {consumerdata ? (
                            <div>
                                <p style={{ marginBottom: '5px' }}>{consumerdata.consumername}</p>
                                <p style={{ marginBottom: '5px' }}>{`${consumerdata.address1}, ${consumerdata.address2}`}</p>
                                <p style={{ marginBottom: '5px' }}>{consumerdata.address3}</p>
                                <p style={{ marginBottom: '5px' }}>{consumerdata.phone}</p>
                            </div>
                        ) : (
                            <p>Loading...</p>
                        )}
                    </div>

                    {/* Quotation Details Section */}
                    <div className="row" style={{ display: 'flex', gap: '0px', padding: '20px' }}>
                        <div className="col-md-2 mb-2">
                            <label>Invoice Date</label>
                            <input
                                type="date"
                                className={`form-control`}
                                name="invoicedate"
                                value={invoiceDetails.invoicedate || ''}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className="col-md-2 mb-2">
                            <label>Due Date</label>
                            <input
                                type="date"
                                className={`form-control`}
                                name="duedate"
                                value={invoiceDetails.duedate || ''}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className="col-md-2 mb-2">
                            <label>Ship By (expected)</label>
                            <input
                                type="date"
                                className={`form-control`}
                                name="shibydate"
                                value={invoiceDetails.shibydate || ''}
                                onChange={handleInputChange} />
                        </div>
                    </div>


                    <div className="col-12 mb-3" style={{ padding: '20px' }}>
                        <div className="card" style={{ width: "110%" }}>
                            <div className="card-body">
                                <table className="table table-bordered">
                                    <thead>
                                        <tr style={{ fontSize: "12px", backgroundColor: "lightgray", color: "black", textAlign: "center" }}>
                                            <th style={{ width: "10%" }}>Product</th>
                                            <th style={{ width: "10%" }}>Product Code</th>
                                            <th style={{ width: "30%" }}>Description</th>
                                            <th style={{ width: "10%" }}>Quantity</th>
                                            <th style={{ width: "10%" }}>Unit</th>
                                            <th style={{ width: "10%" }}>Unit Price</th>
                                            <th style={{ width: "10%" }}>before tax</th>
                                            <th style={{ width: "10%" }}>Tax</th>
                                            <th style={{ width: "10%" }}>Amount</th>
                                            <th style={{ width: "10%" }}>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {productData.map((product, index) => (
                                            <tr key={index}>
                                                <td>

                                                    <select
                                                        value={product.product}
                                                        onChange={(e) => {
                                                            const selectedProduct = productList.find(p => p.productname === e.target.value);
                                                            if (selectedProduct) {
                                                                handleProductChange(index, selectedProduct); // Pass the entire product object
                                                            }
                                                        }}
                                                        className="form-control"
                                                    >
                                                        <option value="">Select Product</option>
                                                        {productList.map((product) => (
                                                            <option key={product.productcode} value={product.productname}>
                                                                {product.productname}
                                                            </option>
                                                        ))}
                                                    </select>

                                                </td>
                                                <td>{product.productcode}</td>
                                                <td>
                                                    <textarea
                                                        type="text"
                                                        className="form-control"
                                                        value={product.description}
                                                        onChange={(e) => handleFieldChange(index, "description", e.target.value)}
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        type="number"
                                                        className="form-control"
                                                        value={product.quantity || ''} // Safeguard against empty values
                                                        onChange={(e) => handleFieldChange(index, 'quantity', e.target.value)}
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        value={product.uom}
                                                        onChange={(e) => handleFieldChange(index, "uom", e.target.value)}
                                                    />
                                                </td>
                                                <td>{product.price}</td>
                                                <td>{product.beforetax}</td>
                                                <td>
                                                    <div>
                                                        tax:{product.tax}%  , ₹ {product.tax * product.beforetax / 100}
                                                    </div>

                                                    <div>
                                                        CGST:{product.tax / 2}% ,₹ {(product.tax * product.beforetax / 100) / 2}
                                                    </div>
                                                    <div>
                                                        SGST:{product.tax / 2} % ,₹ {(product.tax * product.beforetax / 100) / 2}
                                                    </div>
                                                </td>
                                                <td>{product.amount}</td>

                                                <td>
                                                    <IconButton onClick={() => handleDeleteData(product.id)} size="small" color="secondary">
                                                        <AiOutlineDelete />
                                                    </IconButton>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>


                    <div className="col-12 mb-3">
                        <button className="btn btn-link" onClick={addNewProductRow}>+ Add Item</button>
                        <button className="btn btn-link" onClick={toggleBarcodeModal}>
                            Scan Barcode
                        </button>

                        <Modal open={barcodeModalOpen} onClose={toggleBarcodeModal}>
                            <div style={{ padding: '20px', backgroundColor: 'white', width: '300px', margin: '100px auto', borderRadius: '8px' }}>
                                <h4>Enter Product Code</h4>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={barcode}
                                    onChange={(e) => setBarcode(e.target.value)}
                                    placeholder="Enter product code"
                                />
                                <button className="btn btn-primary mt-3" onClick={handleCheckProduct}>
                                    Check Product
                                </button>
                                {error && <p className="text-danger mt-2">{error}</p>}
                                <button className="btn btn-secondary mt-2" onClick={toggleBarcodeModal}>
                                    Close
                                </button>
                            </div>
                        </Modal>



                        <button className="btn btn-link" onClick={clearAllItems}> Clear All Items</button>
                        {/* <button className="btn btn-link">Activate Reserved Stock</button> */}
                    </div>

                    <div className="col-md-6 mb-2" style={{ padding: '20px' }}>
                        {/* Step 3: Bind textarea to state */}
                        <textarea
                            type="text"
                            className={`form-control`}
                            name="memo"
                            value={invoiceDetails.memo || ''}
                            onChange={handleInputChange}
                        />

                    </div>

                    {/* <div style={{ padding: '20px' }}>
                        <h5>Attached Files</h5>
    
                        {files.length > 0 ? (
                            <ul>
                                {files.map((file, index) => (
                                    <li key={index}>
                                        <a
                                            onClick={() => handleFileClick(file.filedata, file.filename)} 
                                            style={{ textDecoration: 'underline', cursor: 'pointer' }}
                                        >
                                            {file.filename ? file.filename : `View File ${index + 1}`} 
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        ) : (
                            <p>No attached files</p>
                        )}
    
                        <div style={{ marginTop: '10px' }}>
                            <input
                                type="file"
                                onChange={handleFileUpload}
                            />
                        </div>
                    </div> */}




                    <div className="col-12 col-md-6 offset-md-7">



                        <div className="row">
                            <div className="col-8 text-end" >
                                <label>Subtotal :  ₹ {calculateSubtotal()}</label>

                            </div>
                            <div className="col-md-8 d-flex justify-content-end align-items-center">
                                <label htmlFor="discount">Discount: ₹</label>
                                <input
                                    type="text"
                                    // id="discount"
                                    name="discount"
                                    value={invoiceDetails.discount || ''}
                                    onChange={handleInputChange}
                                    style={{ maxWidth: '50px' }} // Adjust width if needed
                                />
                            </div>

                            <div className="col-8 text-end">
                                <label> Before Tax:  ₹  {calculateSubtotalAfterDiscount()}</label>
                            </div>


                            <div className="col-6 text-end">
                                <label> Tax</label>

                                <Tooltip title="Show tax breakdown">
                                    <IconButton onClick={toggleTaxDetails} style={{ marginLeft: '10px' }}>
                                        <InfoOutlinedIcon />
                                    </IconButton>
                                </Tooltip>:
                            </div>

                            <div className="col-6 d-flex align-items-center">
                                <label>₹ {TotalTaxAmount(productData)}</label>
                            </div>

                            {showTaxDetails && (
                                <div className="col-12 mt-2">
                                    <div style={{ backgroundColor: "#f9f9f9", padding: "10px", borderRadius: "5px" }}>
                                        <h6>Tax Breakdown:</h6>
                                        <div>CGST: ₹ {productData.reduce((sum, product) => sum + ((product.tax * product.beforetax / 100) / 2), 0).toFixed(2)}</div>
                                        <div>SGST: ₹ {productData.reduce((sum, product) => sum + ((product.tax * product.beforetax / 100) / 2), 0).toFixed(2)}</div>
                                    </div>
                                </div>
                            )}

                            <div className="col-6 text-end">TCS:</div>
                            <div className="col-6">₹ 0.00</div>

                            <div className="col-8 text-end">Rounding Off:
                                ₹ {resultValue.roundOff}
                            </div>

                            <div className="col-7 text-end fw-bold">Total: {resultValue.roundedTotal.toFixed(2)}</div>

                        </div>
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'flex-start', marginBottom: '10px' }}>
                        {/* <Button
                            variant="contained"
                            color="primary"
                            onClick={handlePrint}
                            style={{ marginLeft: '10px', height: '35px' }}
                        >
                            Print
                        </Button> */}


                        <div style={{ display: 'flex', justifyContent: 'flex-start', marginBottom: '10px' }}>
                            <Button
                                variant="contained"
                                color="success"
                                onClick={handleUpdateQuotation}
                                style={{ marginLeft: '10px' }}
                            >
                                Save
                            </Button>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'flex-start', marginBottom: '10px' }}>

                            <button
                                className="btn btn-secondary"
                                onClick={handleClose}
                                style={{ marginLeft: '10px', height: '35px' }}                                  >
                                Close
                            </button>


                        </div>
                    </div>

                </div>
            </div>
        </div>
    );



}
export default EditInvoice;