import React, { useEffect, useState } from "react";
import { Modal, Button, Tabs, Tab } from 'react-bootstrap';  
import axios from "../api/baseurl";
import { MdDeleteForever } from "react-icons/md";
import { BiEditAlt } from "react-icons/bi";
import { toast } from "react-toastify";
import ContacteditModalPopup from "./Contacteditmodal";
import Addcontact from "./Addcontact";
import { useNavigate, useParams } from "react-router-dom";
import StockisteditModalPopup from "./Stockistedit";
import Addstockist from "./Addstockist";
import AdminDashboard from "./admindashboard";
import StockistcontactpersonViewModal from "./Stockistcontactperson";
import AddVendorProduct from "./Createvendorproduct";
import StockistproducteditModalPopup from "./stoclistprodycredit";
import Stockistmapping from "./Stockistmapping";
import { IoMdAddCircleOutline } from "react-icons/io";
import ViewStockistmapping from "./Viewstockist";
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Pagination, Form, Card, Row, Col } from "react-bootstrap";
import { FaMedal, FaRupeeSign } from "react-icons/fa";
import { FiInfo } from "react-icons/fi";
import AdditionalPriceHistoryModal from "./additionalPriceHistoryModal";
import NewConPriceHistoryModal from "./newConPriceHistoryModal";
import ProductPriceUpdate from "./productPriceUpdate";
import ProductPointsHistory from "./productPointsHistoryModal";
import ProducteditModalPopup from "./productedit";
import PriceHistoryModal from "./PriceHistoryModal";
import  './vendorview.css';
function VendorView() {
    const { vendorId } = useParams();
    console.log("Vendor ID:", vendorId);
    const navigate = useNavigate();
    const [categoryFilter, setCategoryFilter] = useState("");
    const [vendor, setVendor] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedVendorId, setSelectedVendorId] = useState(null);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isEditModalOpen1, setIsEditModalOpen1] = useState(false);
    const [isModalOpen1, setIsModalOpen1] = useState(false);
    const [activeTab, setActiveTab] = useState('contactPersons');
    const [isUpdateVendorModalOpen, setIsUpdateVendorModalOpen] = useState(false);
    const [selectedStockistId, setSelectedStockistId] = useState(null);
    const [contactSearchTerm, setContactSearchTerm] = useState("");
    const [stockistSearchTerm, setStockistSearchTerm] = useState("");
    const [productSearchTerm, setProductSearchTerm] = useState("");
    const [isEditModalOpen2, setIsEditModalOpen2] = useState(false);
    const [isModalOpen2, setIsModalOpen2] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [isAddMemberModalOpen, setIsAddMemberModalOpen] = useState(false);
    const [isViewModalOpen, setIsViewModalOpen] = useState(false);
    const [stockists, setStockists] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [product, setProduct] = useState([]);
    const [superProducts, setSuperProducts] = useState([]);
    const [isProductEditModalOpen, setIsProductEditModalOpen] = useState(false);
    const [isProductPriceUpdateModal, setIsProductPriceUpdateModal] = useState(false);
    const [loader, setLoader] = useState(false);

    const [isPointsHistoryModalOpen, setIsPointsHistoryModalOpen] = useState(false);
    const [isPriceHistoryModalOpen, setIsPriceHistoryModalOpen] = useState(false);
    const [isAdditionalPriceHistoryModalOpen, setIsAdditionalPriceHistoryModalOpen] = useState(false);
    const [isNewConPriceHistoryModalOpen, setIsNewConPriceHistoryModalOpen] = useState(false);
    const [additionalPriceHistory, setAdditionalPriceHistory] = useState([]); 
    const [newConPriceHistory, setNewConPriceHistory] = useState([]); 
    const [priceHistory, setPriceHistory] = useState([]); 
    const [selectedProductId, setSelectedProductId] = useState(null);
    const [currentPage1, setCurrentPage1] = useState(0);
    const [totalPages1, setTotalPages1] = useState(0);
    const fetchVendorData = async (pageNumber = 0) => {
      setLoading(true);
    
      // Initialize error states for each API
      let vendorError = null;
      let stockistError = null;
      let productError = null;
      let superProductsError = null;
    
      try {
        const token = JSON.parse(localStorage.getItem("data")).token;
        const orgcode = JSON.parse(localStorage.getItem("data")).orgcode;
    
        // Fetch vendor data
        try {
          const vendorResponse = await axios.get(`/api/vendors/vendorid=${vendorId}`, {
            headers: { Authorization: `Bearer ${token}` },
          });
          setVendor(vendorResponse.data);
        } catch (err) {
          vendorError = "Failed to fetch vendor data.";
          console.error(vendorError, err);
        }
    
        // Fetch stockist data
        try {
          const stockistResponse = await axios.get(`/api/stockistVendorMapping/stockist/vendorId=${vendorId}`, {
            params: { page: pageNumber },
            headers: { Authorization: `Bearer ${token}` },
          });
          setStockists(stockistResponse.data.content);
          setCurrentPage(stockistResponse.data.number);
          setTotalPages(stockistResponse.data.totalPages);
        } catch (err) {
          stockistError = "Failed to fetch stockist data.";
          console.error(stockistError, err);
        }
    
        // Fetch product data
        try {
          const productResponse = await axios.get(`/api/product/orgcode=${orgcode}/vendorid=${vendorId}`, {
            headers: { Authorization: `Bearer ${token}` },
          });
                   setProduct([...productResponse.data]);

          // setProduct([...productResponse.data.content]);
          // setCurrentPage1(productResponse.data.number);
          // setTotalPages1(productResponse.data.totalPages);
        } catch (err) {
          productError = "Failed to fetch product data.";
          console.error(productError, err);
        }
    
        // Fetch super products data
        try {
          const superProductsResponse = await axios.get(`/api/super-products`, {
            headers: { Authorization: `Bearer ${token}` },
          });
          setSuperProducts(superProductsResponse.data);
        } catch (err) {
          superProductsError = "Failed to fetch super products data.";
          console.error(superProductsError, err);
        }
      } catch (err) {
        console.error("Unexpected error:", err);
        toast.error("An unexpected error occurred.");
      } finally {
        setLoading(false);
    
        if (vendorError) toast.error(vendorError);
        if (stockistError) toast.error(stockistError);
        if (productError) toast.error(productError);
        if (superProductsError) toast.error(superProductsError);
    
        const allErrors = [vendorError, stockistError, productError, superProductsError].filter(Boolean).join("\n");
        if (allErrors) setError(allErrors); 
      }
    };
    useEffect(() => {
        if (vendorId) {
          fetchVendorData();
        }
    }, [vendorId]);
    const handleError = (error) => {
      if (error.response && error.response.status === 404) {
        toast.error(error.response.data.message);
      } else {
        toast.error("An error occurred while fetching data");
      }
      setLoader(false);
    };
   
  
    const handleEdit = (vendorId) => {
      setSelectedVendorId(vendorId);
      setIsEditModalOpen(true);
    };
  
    const closeEditModal = () => {
      setIsEditModalOpen(false);
      fetchVendorData();
    };
  
    const handleAdd = () => {
      setSelectedVendorId(vendorId);
      setIsModalOpen(true);
    };
  
    const closeModal = () => {
      setIsModalOpen(false);
      fetchVendorData();
    };
  
    const confirmDelete = (id) => {
      if (window.confirm("Are you sure you want to delete this Contact person?")) {
        onClickHandler(id); // Proceed with the delete if confirmed
      }
    };
    
    const onClickHandler = async ( id) => { 
      const token = JSON.parse(localStorage.getItem("data")).token;
      setLoading(true);
      try {
        await axios.delete("/api/vendorcontactperson", {
          headers: { Authorization: `Bearer ${token}` },
          data: {
            id: `${id}`,
            orgcode: `${JSON.parse(localStorage.getItem("data")).orgcode}`,
          },
        });
        toast.success("Deleted successfully");
        fetchVendorData();
      } catch (error) {
        toast.error("Error deleting vendor");
      } finally {
        setLoading(false);
      }
    };
  
    const handleEdit1 = async (vendorId) => {
        setSelectedVendorId(vendorId);
     
          setIsEditModalOpen1(true);
       
      };
      const closeEditModal1 = () => {
        setIsEditModalOpen1(false);
        fetchVendorData();
     
      };
      const confirmDelete1 = (id) => {
        if (window.confirm("Are you sure you want to delete this Stockist?")) {
          onClickHandler1(id); // Proceed with the delete if confirmed
        }
      };
      const onClickHandler1 = async ( id) => { 
        const token = JSON.parse(localStorage.getItem("data")).token;
        setLoading(id);
        try{
          await axios({
          url: "/api/stockistVendorMapping",
          method: "delete",
     
          headers: { Authorization: `Bearer ${token}` },
     
          data: {
            id: `${id}`,
           
            orgcode: `${JSON.parse(localStorage.getItem("data")).orgcode}`,
          },
        });
        toast.success("deleted successfully");
        fetchVendorData();
    
        setLoading(false); 
      } catch (error) {
        setLoading(false); 
      }
    };
    const handleAdd1= async () => {
      setSelectedVendorId(vendorId);

          setIsModalOpen1(true);
       
      };
      const closeaddModal = () => {
        setIsModalOpen1(false);
        fetchVendorData();
     
      };
      const handleEdit2 = async (vendorId) => {
        setSelectedVendorId(vendorId);
     
          setIsEditModalOpen2(true);
       
      };
      const closeEditModal2 = () => {
        setIsEditModalOpen2(false);
        fetchVendorData();
     
      };
      const confirmDelete2 = (id) => {
        if (window.confirm("Are you sure you want to delete this product?")) {
          onClickHandler2(id); // Proceed with the delete if confirmed
        }
      };
            const onClickHandler2 = async ( id) => { 
        const token = JSON.parse(localStorage.getItem("data")).token;
        setLoading(id);
        try{
          await axios({
          url: "/api/vendorproduct",
          method: "delete",
     
          headers: { Authorization: `Bearer ${token}` },
     
          data: {
            id: `${id}`,
           
            orgcode: `${JSON.parse(localStorage.getItem("data")).orgcode}`,
          },
        });
        toast.success("deleted successfully");
        fetchVendorData();
    
        setLoading(false); 
      } catch (error) {
        setLoading(false); 
      }
    };
    const handleAdd2= async () => {
      setSelectedVendorId(vendorId);

          setIsModalOpen2(true);
       
      };
      const closeaddproductModal = () => {
        setIsModalOpen2(false);
        fetchVendorData();
     
      };
      const handleUpdateVendor = (StockistId) => {
        setSelectedStockistId(StockistId);
        setIsUpdateVendorModalOpen(true);
      };
    
      const closeUpdateVendorModal = () => {
        setIsUpdateVendorModalOpen(false);
        setSelectedStockistId(null);
      };
      const openAddMemberModal = (productId, productname) => {
        setSelectedProduct({ productId, productname });
        setIsAddMemberModalOpen(true);
      };
     
      const closeAddMemberModal = () => {
        setSelectedProduct(null);
        setIsAddMemberModalOpen(false);
        fetchVendorData();
      };
      const handleViewStockist = (productId, productname) => {
        setSelectedProduct({ productId, productname });
        setIsViewModalOpen(true);
      };
     
      const closeviewModal = () => {
        setSelectedProduct(null);
        setIsViewModalOpen(false);
        fetchVendorData();
      };


      // Filter contacts based on search term
    const filteredContacts = vendor.vcp?.filter(contact => 
        contact.name.toLowerCase().includes(contactSearchTerm.toLowerCase()) ||
        contact.email.toLowerCase().includes(contactSearchTerm.toLowerCase()) ||
        contact.phone.includes(contactSearchTerm)
    ) || [];

    const filteredStockists = stockists.filter((stockist) => 
      (stockist.stockist.name && stockist.stockist.name.toLowerCase().includes(stockistSearchTerm.toLowerCase())) ||
      (stockist.stockist.email && stockist.stockist.email.toLowerCase().includes(stockistSearchTerm.toLowerCase())) ||
      (stockist.stockist.phone && stockist.stockist.phone.toLowerCase().includes(stockistSearchTerm.toLowerCase())) ||
      (stockist.stockist.address && stockist.stockist.address.toLowerCase().includes(stockistSearchTerm.toLowerCase()))
    );
    const getUniqueCategories = () => {
      return [...new Set(product.map((p) => p.productCategory?.categoryname))];
  };
  useEffect(() => {
    const uniqueCategories = getUniqueCategories();
    if (uniqueCategories.length > 0) {
        setCategoryFilter(uniqueCategories[0]); // Set the first category as default
    }
}, [product]);
  const filteredProducts = (product || [])
  .filter((product) =>
      (product.productname && product.productname.toLowerCase().includes(productSearchTerm.toLowerCase())) ||
      (product.productcode && product.productcode.toLowerCase().includes(productSearchTerm.toLowerCase()))
  )
  .filter((product) => 
      !categoryFilter || product.productCategory?.categoryname === categoryFilter
  );

  const handlePageChange = (pageNumber) => {
    fetchVendorData(pageNumber);
  };
  const renderPageNumbers = () => {
    const pageNumbers = [];
    const maxPageNumbersToShow = 5;
    let startPage = Math.max(0, currentPage - 2);
    let endPage = Math.min(totalPages - 1, currentPage + 2);

    if (currentPage <= 2) {
      endPage = Math.min(totalPages - 1, maxPageNumbersToShow - 1);
    }

    if (currentPage >= totalPages - 3) {
      startPage = Math.max(0, totalPages - maxPageNumbersToShow);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <Pagination.Item key={i} active={i === currentPage} onClick={() => handlePageChange(i)}>
          {i + 1}
        </Pagination.Item>
      );
    }
    return pageNumbers;
  };

  const onClickhandler = async (e, id) => {
    setLoading(id);
    const checked = e.target.checked;
    const token = JSON.parse(localStorage.getItem("data")).token;

    try {
      await axios.get(`/api/product/productUpdate/id=${id}/value=${checked}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setLoading(null);
      toast.success("Product status updated");
      fetchVendorData();

    } catch (error) {
      handleError(error);
    }
  };

  const handleProductEdit = async (productId) => {
    setSelectedProductId(productId);
      setIsProductEditModalOpen(true);
  };
 
  const closeProductEditModal = () => {
    setIsProductEditModalOpen(false);
    fetchVendorData();
 
  };

  const handleProductPriceUpdate = async (productId) => {
    setSelectedProductId(productId);
   setIsProductPriceUpdateModal(true);
  };
 
  const closeProductPriceUpdateModal = () => {
    setIsProductPriceUpdateModal(false);
    fetchVendorData();
 
  };
 
  const openPriceHistoryModal = async (productId) => {
    setSelectedProductId(productId);
    try {
      const token = JSON.parse(localStorage.getItem("data")).token;
      const response = await axios.get(`/api/ProductPrice/productid=${productId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setPriceHistory(response.data);
    } catch (error) {
      handleError(error);
    }
    setIsPriceHistoryModalOpen(true);
  };
 
  const closePriceHistoryModal = () => {
    setSelectedProductId(null);
    setIsPriceHistoryModalOpen(false);
  };

  const openAdditionalPriceHistoryModal = async (productId) => {
    try {
      const token = JSON.parse(localStorage.getItem("data")).token;
      const response = await axios.get(`/api/additionalprice/productid=${productId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setAdditionalPriceHistory(response.data);
    } catch (error) {
      handleError(error);
    }
    setIsAdditionalPriceHistoryModalOpen(true);
  };
 
  const closeAdditionalPriceHistoryModal = () => {
    setIsAdditionalPriceHistoryModalOpen(false);
  };

  const openNewConPriceHistoryModal = async (productId) => {
    try {
      const token = JSON.parse(localStorage.getItem("data")).token;
      const response = await axios.get(`/api/newconnectionprice/productid=${productId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setNewConPriceHistory(response.data);
    } catch (error) {
      handleError(error);
    }
    setIsNewConPriceHistoryModalOpen(true);
  };
 
  const closeNewConPriceHistoryModal = () => {
    setIsNewConPriceHistoryModalOpen(false);
  };

const [salesPoints, setSalesPoints] = useState([]);
const [additionalPoints, setAdditionalPoints] = useState([]);
const [newConPoints, setNewConPoints] = useState([]);
const [pointsType, setPointsType] = useState(null); // Track which points type is being displayed

const openPointsHistoryModal = async (productId, pointsType) => {
  setSelectedProductId(productId);
  setPointsType(pointsType); // Set the points type
  try {
    const token = JSON.parse(localStorage.getItem("data")).token;
    const response = await axios.get(`/api/product-points/admin-productid=${productId}/pointstype=${pointsType}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    
    // Update state based on pointsType
    if (pointsType === 'sales') {
      setSalesPoints(response.data);
    } else if (pointsType === 'additional') {
      setAdditionalPoints(response.data);
    } else if (pointsType === 'new connection') {
      setNewConPoints(response.data);
    }
  } catch (error) {
    handleError(error);
  }
  setIsPointsHistoryModalOpen(true);
};

const closePointsHistoryModal = () => {
  setIsPointsHistoryModalOpen(false);
};

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
 
  const handleFirst = () => fetchVendorData(0);
  const handlePrevious = () => fetchVendorData(currentPage1 - 1);
  const handleNext = () => fetchVendorData(currentPage1 + 1);
  const handleLast = () => fetchVendorData(totalPages1 - 1);

  const renderPageNumbers1 = () => {
    const pageNumbers = [];
    const maxPageNumbersToShow = 5;
    let startPage = Math.max(0, currentPage1 - 2);  
    let endPage = Math.min(totalPages1 - 1, currentPage1 + 2); 

    if (currentPage1 <= 2) {
      endPage = Math.min(totalPages1 - 1, maxPageNumbersToShow - 1);
    }

    if (currentPage1 >= totalPages1 - 3) {
      startPage = Math.max(0, totalPages1 - maxPageNumbersToShow);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <Pagination.Item
          key={i}
          active={i === currentPage1}
          onClick={() => fetchVendorData(i)}
        >
          {i + 1}
        </Pagination.Item>
      );
    }
    return pageNumbers;
  };

      return (
        <div>
          <AdminDashboard />
        <br/>
        <br/>
        <br/>
        <br/>
          <div className="row col-12">
                <div className="col-12 ps-xl-10">
                    <div className="card card-raised">
                        <div className="card-body">
                            <div className="d-flex justify-content-between align-items-center">
                                <div className="card-title mb-3">Vendor Details</div>
                            </div>
                            <div style={{ textAlign: "center" }}>
                            <div style={{ display: 'flex', justifyContent: 'space-between', textAlign: "center", alignItems: 'center' }}>
                            <div style={{ flex: '0 0 10%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>

                            {vendor.image && vendor.imageMeta ? (
        <img
          className="img-fluid rounded-circle mb-1"
          src={`data:image/png;base64,${vendor.image}`}
          alt="vendor"
          style={{ maxWidth: "50px", maxHeight: "70px", marginRight: '10px' }}
        />
      ) : (
        <div style={{ width: "50px", height: "70px", marginRight: '10px' }}></div>
      )}
      <span style={{ marginRight: '10px' }}></span>
     
    

  </div>
                            {/* Middle 40% (Name, Email, Address, Phone) */}
  <div style={{ flex: '0 0 40%', textAlign: 'left' }}>
    <div className="mb-2">Name: {vendor.name}</div>
    <div className="mb-2">Email: {vendor.email}</div>
    <div className="mb-2">Phone: {vendor.phone}</div>
    <div className="mb-2">Address: {vendor.address}</div>
  </div>

  {/* Right 30% (PAN, GST) */}
  <div style={{ flex: '0 0 50%', textAlign: 'left', position: 'relative', top: '-30px' }}>
  <div className="mb-2">PAN: {vendor.pan}</div>
    <div className="mb-2">GST: {vendor.gst}</div>
  </div>
</div>
</div>
<br/>
<br/>
        {/* <div className="d-flex align-items-center">
         
          <button
            className="btn btn-primary ml-2"
            onClick={handleAdd}
            style={{
              fontSize: '12px',
              padding: '5px 10px',
            }}
          >
            ADD
          </button>
        </div> */}
       

                            <Tabs activeKey={activeTab} onSelect={(tab) => setActiveTab(tab)} className="mb-3">
                            <Tab eventKey="contactPersons" title="Contact Persons">
                            <div className="d-flex align-items-center mb-2">
                                        <input 
                                            type="text" 
                                            placeholder="Search Contact Persons..." 
                                            value={contactSearchTerm}
                                            onChange={(e) => setContactSearchTerm(e.target.value)}
                                            style={{ width: '200px' }}
                                            className="form-control me-2"
                                        />
                                        <Button variant="primary" onClick={handleAdd}
                                         style={{
                                            padding: '5px 10px', 
                                            fontSize: '12px',
                                            marginLeft: 'auto' 
                                        }}>Add Contact</Button>
                                    </div>
                                    <br/>
                            <br/>
                            {loading ? (
  <>
    <style>
      {`
        @keyframes spin {
          0% { transform: rotate(0deg); }
          100% { transform: rotate(360deg); }
        }
      `}
    </style>
    <div
      style={{
        border: '4px solid rgba(0, 0, 0, 0.1)',
        borderLeftColor: 'red',
        borderRadius: '50%',
        width: '40px',
        height: '40px',
        animation: 'spin 1s linear infinite'
      }}
    ></div>
  </>
) : error ? (
  <p className="text-danger">{error}</p>
                                    ) : (
                                        
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th>Name</th>
                                                    <th>Email</th>
                                                    <th>Phone</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {filteredContacts.length > 0 ? (
                                                    filteredContacts.map((contact) => (
                                                        <tr key={contact.id}>
                                                            <td>{contact.name}</td>
                                                            <td>{contact.email}</td>
                                                            <td>{contact.phone}</td>
                                                            <td>
                                                                <button
                                                                 style={{
                                                                    border: "none",
                                                                    background: "white",
                                                                    size: "150px",
                                                                  }}
                                                                   onClick={() => handleEdit(contact.id)}><BiEditAlt /></button>
                                                                <button 
                                                                style={{
                                                                    border: "none",
                                                                    background: "white",
                                                                    color: "grey",
                                                                    position: "relative",
                                                                    left: "10px",
                                                                    fontSize: "24px",
                                                                  }}
                                                                   onClick={() => confirmDelete(contact.id)}><MdDeleteForever /></button>
                                                                   
                                                            </td>
                                                        </tr>
                                                    ))
                                                ) : (
                                                    <tr>
                                                        <td colSpan="4" className="text-center">No data available</td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    )}
                                </Tab>

                                <Tab eventKey="stockList" title="Stockist">
                                <div className="d-flex align-items-center mb-2">
                                        <input 
                                            type="text" 
                                            placeholder="Search Stockists..." 
                                            value={stockistSearchTerm}
                                            onChange={(e) => setStockistSearchTerm(e.target.value)}
                                            style={{ width: '200px' }}
                                            className="form-control me-2"
                                        />
                                        <Button variant="primary" onClick={handleAdd1}
                                         style={{
                                            padding: '5px 10px', // Adjust padding for size
                                            fontSize: '12px', // Adjust font size
                                            marginLeft: 'auto' // Move button to the right end
                                        }}>Link Stockist</Button>
                                    </div>
                            <br/>
                            {loading ? (
  <>
    <style>
      {`
        @keyframes spin {
          0% { transform: rotate(0deg); }
          100% { transform: rotate(360deg); }
        }
      `}
    </style>
    <div
      style={{
        border: '4px solid rgba(0, 0, 0, 0.1)',
        borderLeftColor: 'red',
        borderRadius: '50%',
        width: '40px',
        height: '40px',
        animation: 'spin 1s linear infinite'
      }}
    ></div>
  </>
) : error ? (
  <p className="text-danger">{error}</p>
                                      ) : filteredStockists.length > 0 ? (
                                        <div className="row">
                                          {filteredStockists.map((stockist) => (
                                            <div className="col-12 mb-4" key={stockist.id}>                                              <div className="card">
                                              <div className="card-body d-flex">
                                                {/* Image - 10% */}
              <div style={{ flex: '0 0 10%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                {stockist.stockist.image && stockist.stockist.imageMeta ? (
                  <img
                    className="img-fluid rounded-circle mb-1"
                    src={`data:image/png;base64,${stockist.stockist.image}`}
                    alt="stockist"
                    style={{ maxWidth: "50px", maxHeight: "70px", marginRight: '10px' }}
                  />
                ) : (
                  <div style={{ width: "50px", height: "70px", marginRight: '10px' }}></div>
                )}
              </div>
   
              <div style={{ flex: '0 0 20%', textAlign: 'left' }}>
              <td
  onClick={() => navigate(`/users/stockistViewmodal/${stockist.stockistId}`)}
  style={{
    cursor: 'pointer',
  }}
>
  <h5
    className="card-title"
    style={{
      color: 'black',
      textDecoration: 'none',
    }}
    onMouseEnter={(e) => (e.currentTarget.style.color = 'blue')}
    onMouseLeave={(e) => (e.currentTarget.style.color = 'black')}
  >
    {stockist.stockist.name}
  </h5>
</td>

                <p className="card-text">Email: {stockist.stockist.email}</p>
                <p className="card-text">Phone: {stockist.stockist.phone}</p>
                <p className="card-text">Address: {stockist.stockist.address}</p>
              </div>

 {/* PAN, GST, and Actions */}
 <div style={{ flex: '1', textAlign: 'right', marginTop: '10px', marginRight: '20px' }}>
 <p className="card-text">PAN: {stockist.stockist.pan}</p>
                <p className="card-text">GST: {stockist.stockist.gst}</p>
                <div className="d-flex justify-content-end mt-2">
                                                    <button
                                                      style={{
                                                        border: 'none',
                                                        background: 'white',
                                                      }}
                                                      onClick={() => handleEdit1(stockist.stockistId)}
                                                    >
                                                      <BiEditAlt />
                                                    </button>
                                                    <button
                                                      style={{
                                                        border: 'none',
                                                        background: 'white',
                                                        color: 'grey',
                                                        fontSize: '24px',
                                                      }}
                                                      onClick={() => confirmDelete1(stockist.id)}
                                                    >
                                                      <MdDeleteForever />
                                                    </button>
                                                    <button
                                                      onClick={() => handleUpdateVendor(stockist.stockistId)}
                                                      className="btn btn-primary btn-sm"
                                                    >
                                                      VIEW
                                                      </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
       <div className="d-flex justify-content-center">
                <Pagination>
                  <Pagination.First onClick={() => handlePageChange(0)} disabled={currentPage === 0} />
                  <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 0} />
                  {renderPageNumbers()}
                  <Pagination.Next onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages - 1} />
                  <Pagination.Last onClick={() => handlePageChange(totalPages - 1)} disabled={currentPage === totalPages - 1} />
                </Pagination>
              </div>
    </div>
  ) : (
    <p className="text-center">No data available</p>
  )}
</Tab>
<Tab eventKey="product" title="Product">
                                <div className="d-flex align-items-center mb-2">
                                        <input 
                                            type="text" 
                                            placeholder="Search Products..." 
                                            value={productSearchTerm}
                                            onChange={(e) => setProductSearchTerm(e.target.value)}
                                            style={{ width: '200px' }}
                                            className="form-control me-2"
                                        />
    <select
                    value={categoryFilter}
                    onChange={(e) => setCategoryFilter(e.target.value)}
                    style={{ width: '200px', marginRight: '10px' }}
                    className="form-control"
                >
                    <option value="">All Categories</option>
                    {getUniqueCategories().map((category, index) => (
                        <option key={index} value={category}>
                            {category}
                        </option>
                    ))}
                </select>

                                        <Button variant="primary" onClick={handleAdd2}
                                         style={{
                                            padding: '5px 10px', // Adjust padding for size
                                            fontSize: '12px', // Adjust font size
                                            marginLeft: 'auto' // Move button to the right end
                                        }}>Add Product</Button>
                                    </div>
                            <br/>
                            {loading ? (
  <>
    <style>
      {`
        @keyframes spin {
          0% { transform: rotate(0deg); }
          100% { transform: rotate(360deg); }
        }
      `}
    </style>
    <div
      style={{
        border: '4px solid rgba(0, 0, 0, 0.1)',
        borderLeftColor: 'red',
        borderRadius: '50%',
        width: '40px',
        height: '40px',
        animation: 'spin 1s linear infinite'
      }}
    ></div>
  </>
) : error ? (
  <p className="text-danger">{error}</p>
) : (
                                        
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                <th>Category Name</th>

                                                    <th> Product Name</th>
                                                    <th>Product Code</th>
                                                    <th>HSN Code</th>
                                                    {/* <th>Base Price</th> */}
                                                    <th>GST</th>
                                                    {!filteredProducts.some(
      (product) => product.productCategory?.productCategoryTypeId === 2
    ) && <th>Net Weight</th>}
                <th>Sales</th> 

    {!filteredProducts.some(
      (product) => product.productCategory?.productCategoryTypeId === 2
    ) && <th>Additional</th>}
    {!filteredProducts.some(
      (product) => product.productCategory?.productCategoryTypeId === 2
    ) && <th>New Connection</th>}
                                                  
 <th>Edit</th>
                  <th>Status</th>
                  <th>Action</th>   
    <th>Stockist Mapping</th>


                                                </tr>
                                            </thead>
                                            <tbody>
                                            {filteredProducts.length === 0 ? (
                    <tr>
                      <td colSpan="15" className="text-center">
                        No Data Available
                      </td>
                    </tr>
          ) : (
                                                    filteredProducts.map((product) =>  {  
                                                      const isTracked = superProducts.some(
                                                        (superProduct) => superProduct.productName === product.productname
                                                      );
                                                      const trackingClass = isTracked ? "pill-green" : "pill-yellow";
                                                      const salesPoints =( product.productPoints?.filter(point => point.pointsType === "sales") || []).map(point => point.points).join(", ");
                                                      const additionalPoints = (product.productPoints?.filter(point => point.pointsType === "additional") || []).map(point => point.points).join(", ");
                                                      const newConPoints = (product.productPoints?.filter(point => point.pointsType === "new connection") || []).map(point => point.points).join(", ");
                                                      const isSalesCategory = product.productCategory?.productCategoryTypeId === 2;
                                                      return (
                                                        <tr key={product.productid}>
                                                           {/* <td>{product.productCategory?.categoryType?.categorytype}</td>
                                                          <td>{product.productCategory?.categoryname}</td> */}
                                                          {/* <td>{product.productCategory.categoryType.categorytype}</td> */}
                                                          <td>{product.productCategory.categoryname}</td>

                                                          <td>
                                                            <div style={{ textAlign: "center" }}>
                                                              <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                <div style={{ width: "70px", height: "70px", marginRight: '10px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                                  {product.image && product.imageMeta ? (
                                                                    <img
                                                                      className="img-fluid rounded-circle mb-1"
                                                                      src={`data:image/png;base64,${product.image}`}
                                                                      alt="Product"
                                                                      style={{ maxWidth: "100%", maxHeight: "100%" }}
                                                                    />
                                                                  ) : (
                                                                    <div style={{ width: "50px", height: "50px", borderRadius: "50%" }}></div>
                                                                  )}
                                                                </div>
                                                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '100%' }}>
                                                                  <span style={{ marginBottom: '5px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '150px' }}>
                                                                    {product.productname}
                                                                  </span>
                                                                  <span
                                                                    style={{
                                                                      display: "inline-block",
                                                                      padding: "0.2em 0.4em",
                                                                      borderRadius: "0.4em",
                                                                      fontSize: "0.8em",
                                                                      color: "white",
                                                                      backgroundColor: isTracked ? "green" : "black",
                                                                      minWidth: '60px',
                                                                      textAlign: 'center',
                                                                    }}
                                                                  >
                                                                    {isTracked ? "Tracked" : "Untracked"}
                                                                  </span>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </td>
                                                            <td>{product.productcode}</td>
                                                              <td>{product.hsnCode}</td>
                                                              <td>{product?.gst} %</td>
                                                              {!isSalesCategory && (
                                                      <td>{product.netWeight} Kg</td>
                                                    )}      
                                                                     <td>
                                                                  <div style={{ display: 'flex',justifyContent: 'space-between', alignItems: 'center' }}>
                                                                  {product.pprice?.price && (
                                                                        <>
                                                                         <FaRupeeSign/> {product.pprice.price}
                                                                          <span>
                                                                            <FiInfo onClick={() => openPriceHistoryModal(product.productid)} style={{ cursor: 'pointer' }} title="View Price History"/>
                                                                          </span>
                                                                        </>
                                                                      )}
                                                                  </div>
                                                                  <div style={{ display: 'flex', justifyContent: 'space-between',alignItems: 'center' }} className="mt-3">
                                                                  {salesPoints && (
                                                                    <>
                                                                   <FaMedal/>{salesPoints}
                                                                    <span>
                                                                      <FiInfo onClick={() => openPointsHistoryModal(product.productid, 'sales')} style={{cursor: 'pointer' }} title="View Points History" />
                                                                    </span>
                                                                    </>
                                                                  )}
                                                                  </div>
                                                                </td>
                                                                {!isSalesCategory && (  
                                                                  <>    
                                        
                                        <td>
                                        <div style={{ display: 'flex',justifyContent: 'space-between', alignItems: 'center' }}>
                                        {product.addPrice?.additionalPrice && (
                                              <>
                                        <FaRupeeSign/>{product.addPrice.additionalPrice}
                                          <span>
                                            <FiInfo onClick={() => openAdditionalPriceHistoryModal(product.productid)} style={{cursor: 'pointer' }} title="View Price History"/>
                                          </span>
                                          </>
                                        )}
                                        </div>
                                        <div style={{ display: 'flex',justifyContent: 'space-between', alignItems: 'center' }} className="mt-3">
                                        {additionalPoints && (
                                          <>
                                         <FaMedal/>{additionalPoints}
                                          <span>
                                            <FiInfo onClick={() => openPointsHistoryModal(product.productid, 'additional')} style={{cursor: 'pointer' }} title="View Points History"/>
                                          </span>
                                          </>
                                        )}
                                        </div>
                                        </td>
                                        <td>
                                        <div style={{ display: 'flex',justifyContent: 'space-between', alignItems: 'center' }}>
                                        {product.newConPrice?.newConPrice && (
                                          <>
                                           <FaRupeeSign/> {product.newConPrice.newConPrice}
                                          <span>
                                            <FiInfo onClick={() => openNewConPriceHistoryModal(product.productid)} style={{cursor: 'pointer' }} title="View Price History"/>
                                          </span>
                                          </>
                                        )}
                                        </div>
                                        
                                        <div style={{ display: 'flex', justifyContent: 'space-between',alignItems: 'center' }} className="mt-3">
                                        {newConPoints && (
                                          <>
                                          <FaMedal/>{newConPoints}
                                          <span>
                                            <FiInfo onClick={() => openPointsHistoryModal(product.productid, 'new connection')} style={{cursor: 'pointer' }}  title="View Points History"/>
                                          </span>
                                          </>
                                        )}
                                        </div>
                                        </td>
                                        </>
                                                                )}
                                                            {/* <td>
                                                    <button
                                                      style={{
                                                        border: 'none',
                                                        background: 'white',
                                                      }}
                                                      onClick={() => handleEdit2(product.id)}
                                                    >
                                                      <BiEditAlt />
                                                    </button>
                                                    <button
                                                      style={{
                                                        border: 'none',
                                                        background: 'white',
                                                        color: 'grey',
                                                        fontSize: '24px',
                                                      }}
                                                      onClick={() => confirmDelete2(product.id)}
                                                    >
                                                      <MdDeleteForever />
                                                    </button>
                                                    <button
                                                      onClick={() => handleUpdateVendor(product.id)}
                                                      className="btn btn-primary btn-sm"
                                                    >
                                                      VIEW
                                                      </button>
            </td> */}

           
            {/* <button
                            onClick={() =>
                              openAddMemberModal(product.id, product.productname)
                            }
                          >
                          <IoMdAddCircleOutline />


                          </button> */}

<td> 
                     <button
                      onClick={() => handleProductEdit(product.productid)}
                  style={{
                         border: "none",
                         background: "white",
                         size: "150px",
                       }}
                     >
                       <BiEditAlt />
                     </button>
                   </td>
                      <td>
                        <div className="form-check form-switch">
                          {loading == product.productid && (
                            <div
                              className="loader"
                              style={{
                                position: "relative",
                                left: "55px",
                                top: "8px",
                              }}
                            ></div>
                          )}{" "}
                          
                          <input
                            className="form-check-input"
                            id="flexSwitchCheckDefault"
                            type="checkbox"
                            defaultChecked={product.productstatus}
                            onClick={(e) => onClickhandler(e, product.productid)}
                            value={product.productid}
                          />
                        </div>
                      </td>
                      <td>
      <button className="btn btn-primary btn-sm" onClick={() => handleProductPriceUpdate(product.productid)}>
        Update Price/Points
      </button>

              </td>
                          <td>
                          {/* <span style={{marginRight:'10px'}}></span> */}
                          <Button
                                      variant="contained"

                                    onClick={() => handleViewStockist(product.productid , product.productname)} // Passing product ID
                                >
                                      <VisibilityIcon />

                                </Button>
                                </td>
           
            </tr>
                  );
                }))}
          
                {" "}
              </tbody>
            </table>

                                    )}
                                      <Pagination style={{ justifyContent: 'center', flexGrow: 1 }}>
              <Pagination.First onClick={handleFirst} disabled={currentPage1 === 0} />
              <Pagination.Prev onClick={handlePrevious} disabled={currentPage1 === 0} />
              {renderPageNumbers1()}
              <Pagination.Next onClick={handleNext} disabled={currentPage1 === totalPages1 - 1} />
              <Pagination.Last onClick={handleLast} disabled={currentPage1 === totalPages1 - 1} />
            </Pagination>
                                </Tab>
                               
                            </Tabs>
                        </div>
                    </div>
                </div>
            </div>

        {/* Edit Modal */}
        {isEditModalOpen && (
          <ContacteditModalPopup
            isOpen={isEditModalOpen}
            onRequestClose={closeEditModal}
            vendorId={selectedVendorId}
          />
        )}
        
        {/* Add Contact Modal */}
        {isModalOpen && (
          <Addcontact
            isOpen={isModalOpen}
            onRequestClose={closeModal}
            vendorId={selectedVendorId}
          />
        )}
         {isEditModalOpen1 && (
              <StockisteditModalPopup
                isOpen={isEditModalOpen1}
                onRequestClose={closeEditModal1}
                vendorId={selectedVendorId}
                />
            )}
 {isModalOpen1 && (
              <Addstockist
                isOpen={isModalOpen1}
                onRequestClose={closeaddModal}
                vendorId={selectedVendorId}
                />
            )}
            {isEditModalOpen2 && (
              <StockistproducteditModalPopup
                isOpen={isEditModalOpen2}
                onRequestClose={closeEditModal2}
                vendorId={selectedVendorId}
                />
            )}
 {isModalOpen2 && (
              <AddVendorProduct
                isOpen={isModalOpen2}
                onRequestClose={closeaddproductModal}
                vendorId={selectedVendorId}
                />
            )}
             {isUpdateVendorModalOpen && (
                <StockistcontactpersonViewModal
                  isOpen={isUpdateVendorModalOpen}
                  onRequestClose={closeUpdateVendorModal}
                  StockistId={selectedStockistId}
                  vendorId={vendorId}

                />
              )}
   {isAddMemberModalOpen && selectedProduct && (
        <Stockistmapping
          isOpen={isAddMemberModalOpen}
          onRequestClose={closeAddMemberModal}
          params={{ id: selectedProduct.productId, productname: selectedProduct.productname }}
          vendorId={vendorId}

        />
      )}
       {isViewModalOpen && selectedProduct && (
        <ViewStockistmapping
          isOpen={isViewModalOpen}
          onRequestClose={closeviewModal}
          params={{ id: selectedProduct.productId, productname: selectedProduct.productname }}
          vendorId={vendorId}


        />
      )}
        <div>
            {isPriceHistoryModalOpen && (
              <PriceHistoryModal
                onClose={closePriceHistoryModal}
                initialPriceHistory={priceHistory}
                productId={selectedProductId}
              />
            )}
              {isAdditionalPriceHistoryModalOpen && (
              <AdditionalPriceHistoryModal
                onClose={closeAdditionalPriceHistoryModal}
                additionalPriceHistory={additionalPriceHistory}
              />
            )}
              {isNewConPriceHistoryModalOpen && (
              <NewConPriceHistoryModal
                onClose={closeNewConPriceHistoryModal}
                newConPriceHistory={newConPriceHistory}
              />
            )}
            {isProductEditModalOpen && (
              <ProducteditModalPopup
                isOpen={isProductEditModalOpen}
                onRequestClose={closeProductEditModal}
                productId={selectedProductId}
              />
            )}

           {isProductPriceUpdateModal && (
              <ProductPriceUpdate
                isOpen={isProductPriceUpdateModal}
                onRequestClose={closeProductPriceUpdateModal}
                productId={selectedProductId}
              />
            )}
          

           {isPointsHistoryModalOpen && (
      <ProductPointsHistory
        onClose={closePointsHistoryModal}
        salesPoints={pointsType === 'sales' ? salesPoints : []}
        additionalPoints={pointsType === 'additional' ? additionalPoints : []}
        newConPoints={pointsType === 'new connection' ? newConPoints : []}
      />
    )}

      </div>

        </div>
    );
}


export default VendorView;
