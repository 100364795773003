import React, { useEffect, useState } from "react";
import AdminDashboard from "./admindashboard";
import axios from "../api/baseurl";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Loader.css";
import { BiEditAlt } from "react-icons/bi";
import { MdDeleteForever } from "react-icons/md";
import "./Spinner.css";
import AddquantityModalPopup from "./Addquantity";
import RemovequantityModalPopup from "./Removequantity";
import { FiInfo } from "react-icons/fi";
import AddHistory from "./AddHistory";
import RemoveHistory from "./Removequantitymodal";
import Search from "./search";
import { Link,useNavigate } from "react-router-dom";

function StockManagement() {
  const [openstock, setOpenstock] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [product, setProduct] = useState([]);
  const [searchbytoutvehicle, setSearchbytoutvehicle] = useState([]);
  const date = new Date();
  const date1 = new Date();
  date.setDate(date.getDate());
  date1.setDate(date1.getDate());
  let day = date.getDate().toString().padStart(2, "0");
  let month = (date.getMonth() + 1).toString().padStart(2, "0");
  let year = date.getFullYear();
  let currentDate = `${year}-${month}-${day}`;
  const [closestock, setClosestock] = useState({
    orgcode: JSON.parse(localStorage.getItem("data")).orgcode,
    datetime: currentDate,
  });
  const [orgStartDate, setOrgStartDate] = useState("");

  const [openingstock, setOpeningstock] = useState([]);
  const [searchstate, setSearchstate] = useState(true);
  const [tsearchstate, setTsearchstate] = useState(true);

  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(false);
 
  const [vehicle, setVehicle] = useState([]);
 
  const [productTypeFilter, setProductTypeFilter] = useState(1); // New state for product type filter

  const [searchbyv, setSearchbyv] = useState(true);
  const [searchbyPro, setSearchbyPro] = useState(true);
  const [vehicledetails, setVehicledetails] = useState({
    vehicleid: "",
  });
  const [productname, setProductname] = useState({
    productname: "",
  });
  const [amountdenominationdetails, setdenominationdetails] = useState([]);
  const [totalamountdetails, setTotalamountdetails] = useState([]);
 
  const [searchDate, setSearchDate]= useState(currentDate);

  const [lastUpdatedClosestock, setLastUpdatedClosestock] = useState("");

  const [errorMessage, setErrorMessage] = useState(false);

  const [consumable, setConsumable] = useState([]);
  const [refill, setRefill] = useState([]);
  const [selectedStockId, setSelectedStockId] = useState(null);
  const [selectedStockproduct, setSelectedStockproduct] = useState(null);
  const [selectedStockquantity, setSelectedStockquantity] = useState(null);
  const [isStockEditModalOpen, setIsStockEditModalOpen] = useState(false);
  const [isStockEditModalOpen1, setIsStockEditModalOpen1] = useState(false);
  const [selectedStockproductId, setSelectedStockproductId] = useState(null);
  const [isAddHistoryModalOpen, setIsAddHistoryModalOpen] = useState(false);
  const [addHistory, setAddHistory] = useState([]); 
  const [isRemoveHistoryModalOpen, setIsRemoveHistoryModalOpen] = useState(false);
  const [removeHistory, setRemoveHistory] = useState([]);
 

  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0'); 
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const monthIndex = date.getMonth();
    const year = date.getFullYear();
    return `${day}/${monthNames[monthIndex]}/${year}`;
  }
  const navigate = useNavigate();
  function dateHandler(e, searchDate) {
    navigate(`/users/updateExceptionopenstock/${searchDate}`);
  }

  function updateStockDateHandler(e, searchDate) {
    navigate(`/users/updateopenstock/${searchDate}`);
  }

  const [productTypes, setProductTypes] = useState([]);
  useEffect(() => {
    const token= JSON.parse(localStorage.getItem("data")).token;
    const orgcode= JSON.parse(localStorage.getItem("data")).orgcode;
    const fetchProductTypes = async () => {
      try {
        const response = await axios.get(`/api/productcategorytype`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setProductTypes(response.data);

        const res = await axios
        .get(
          `/api/product/orgcode=${
            JSON.parse(localStorage.getItem("data")).orgcode
          }/productstatus=true/productTypeId=1`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
          setProduct(res.data);

          const vehicles = await axios
          .get(
            `/api/vehicle/orgid=${
              JSON.parse(localStorage.getItem("data")).orgcode
            }`,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
        setVehicle(vehicles.data);
     
      } catch (error) {
        console.error('Error fetching product types:', error);
      }
    };

    fetchProductTypes();
  }, []);
  const searchName = async (text) => {
    const fSearchDate = formatDate(text);
    const fSearchDateObj = new Date(fSearchDate);
const orgStartDateObj = new Date(orgStartDate);
setSearchstate(false);
setSearchbyPro(true);
setSearchbyv(true);
setSearchDate(text);

setTsearchstate(false);
if (fSearchDateObj < orgStartDateObj) {
  setErrorMessage(true);
  setLoading(false); 
} else {
  setErrorMessage(false);

    setLoading(true);
    const token = JSON.parse(localStorage.getItem("data")).token;
    await Promise.all([
     
      axios
        .get(
          `/api/OpeningstockDo/orgid=${
            JSON.parse(localStorage.getItem("data")).orgcode
          }/date=${text}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          setOpenstock(response.data.prodDo);
          console.log(response.data);
        }),
        axios
        .get(
          `/api/Openingstock/orgcode=${
            JSON.parse(localStorage.getItem("data")).orgcode
          }/datetime=${text}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          setOpeningstock(response.data);
        }),
       
        
        axios
        .get(
          `/api/consumableproduct/orgcode=${
            JSON.parse(localStorage.getItem("data")).orgcode
          }/date=${text}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          setConsumable(response.data);
        }),
        axios
        .get(
          `/api/refillproduct/orgcode=${
            JSON.parse(localStorage.getItem("data")).orgcode
          }/date=${text}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          setRefill(response.data);
        }),

     
      
      
    
    ])


 .finally(() => {
    setLoading(false); // Set loading to false after request completes (both success and failure)
});  
}  
  };
  function denominationdetails(text, vehicleid){
    setModalIsOpen(true);
    const token = JSON.parse(localStorage.getItem("data")).token;
    axios
      .get(
        `/api/DeliveryAgentCashPayment/orgid=${
          JSON.parse(localStorage.getItem("data")).orgcode
        }/datetime=${text}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        console.log(res);
        setTotalamountdetails(res.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      })
      .finally(() => {
        setLoading(false); // Set loading to false after the API call completes (both success and failure)
      });
  
      axios
      .get(
        `/api/DeliveryagentDenomination/orgid=${
          JSON.parse(localStorage.getItem("data")).orgcode
        }/datetime=${text}/vehicleid=${vehicleid}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        setdenominationdetails(res.data);
        console.log(res);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      })
      .finally(() => {
        setLoading(false); // Set loading to false after the API call completes (both success and failure)
      });
  
  }
  const getData = async () => {
    const token = JSON.parse(localStorage.getItem("data")).token;
  
    setLoading(true)

    await Promise.all([
     
      axios
        .get(
          `/api/OpeningstockDo/orgid=${
            JSON.parse(localStorage.getItem("data")).orgcode
          }/date=${currentDate}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          console.log(response);
         // setLoader(false);
          setOpenstock(response.data.prodDo);
        }),

       
     
        axios
        .get(
          `/api/refillproduct/orgcode=${
            JSON.parse(localStorage.getItem("data")).orgcode
          }/date=${currentDate}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          console.log(response);
          setRefill(response.data);
        }),
        axios
        .get(
          `/api/consumableproduct/orgcode=${
            JSON.parse(localStorage.getItem("data")).orgcode
          }/date=${currentDate}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          console.log(response);
          setConsumable(response.data);
        }),
      
     
       
      axios
        .get(
          `/api/Openingstock/orgcode=${
            JSON.parse(localStorage.getItem("data")).orgcode
          }/datetime=${currentDate}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          console.log(response);
        //  setLoader(false);
          setOpeningstock(response.data);
        }),

      ])
      .finally(() => {
        setLoading(false); 
      });
    };

  const getOrganizationProfile = async () => {
    const token = JSON.parse(localStorage.getItem('data')).token;

    try {
      const response = await axios.get(
        `/api/organizationprofiles/Orgcode=${JSON.parse(localStorage.getItem('data')).orgcode}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setOrgStartDate(response.data.createddate);
    } catch (error) {
      if (error.response.status === 404) {
        console.log('Organization profile not found:', error.response.data.message);
        // Handle 404 error (organization profile not found)
      } else {
        console.error('Error fetching organization profile:', error.response.data.message);
        // Handle other errors
      }
    }
  };

  useEffect(() => {
    getData();
    getOrganizationProfile();
  }, []);
 
  const handleOpenModal = (productname, quantity,productcode) => {
    setSelectedStockproduct(productname); 

    setSelectedStockquantity(quantity); 
    setSelectedStockproductId(productcode); 

    setIsStockEditModalOpen(true); 
  };

  const closeModal = () => {
    setIsStockEditModalOpen(false);
    setSelectedStockproduct(null);
    setSelectedStockquantity(null);
    setSelectedStockproductId(null); 
    getData();
  };
 
  const handleOpenModal1 = (productname, quantity ,productcode) => {
    setSelectedStockproduct(productname); 

    setSelectedStockquantity(quantity); 
    setSelectedStockproductId(productcode); 

    setIsStockEditModalOpen1(true); 
  };

  const closeModal1 = () => {
    setIsStockEditModalOpen1(false);
    setSelectedStockproduct(null);
    setSelectedStockquantity(null);
    setSelectedStockproductId(null); 
    getData();
  };
  const openAddQuantityModal = async (id) => {
    setSelectedStockproductId(id); 
  
    setIsAddHistoryModalOpen(true);
  };
  const closeAddHistoryModal = () => {
    setSelectedStockproductId(null);
    setIsAddHistoryModalOpen(false);
    getData();
  };
  const openRemoveQuantityModal = async (id) => {
    setSelectedStockproductId(id); 
   
    setIsRemoveHistoryModalOpen(true);
  };
  const closeRemoveHistoryModal = () => {
    setSelectedStockproductId(null);
    setIsRemoveHistoryModalOpen(false);
    getData();
  };
 

 
  return (
    <div>
      <div>
        <AdminDashboard />
      </div>
    
      <br /> <br /> <br /> <br />
      
      <div
        style={{ position: "relative", top: "40px" }}
        className="col-13  mx-xl-auto ps-xl-10"
      >

              <div >
  <div className="card-body">
    <div className="d-flex">
    <br />
    <table className="table table-bordered ">
      <thead>
        <tr
          style={{
            fontSize: "17px",
            color: "black",
            backgroundColor:"white",
            textAlign: "center",
          }}
        >
          <th>
            Last Day End Transaction Completed on :{" "}
            {lastUpdatedClosestock && (
              <span className="ml-2">{formatDate(lastUpdatedClosestock.dedate)}</span>
              
            )}
            <br/>
            <br/>
              <Search searchName={searchName} />{" "}

          </th>
        </tr>
      </thead>
    </table>
    </div>
  </div>
</div>
</div>{" "}

{(searchstate && tsearchstate) && (
 <>
        {openingstock.length === 0 ? (
          <>
           <div style={{ position: "relative", top: "40px", textAlign: "center" }} className="col-11 mx-xl-auto ps-xl-10">
    <div className="card card-raised">
      <div className="card-body">
                <h5 style={{ color: "red" }}>Please Complete Day End Transaction for Previous Date.</h5>
                <br />

  <button className="btn btn-primary" onClick={(e) => dateHandler(e, searchDate)}>Create Exception</button>

            </div>
            </div>
            </div>
          </>
          
        ) : (
          <>
        <br></br>
       
        
             <div className="col-12  mx-xl-auto ps-xl-10">
        <div className="card card-raised">
          <div className="card-body ">
            <div className="d-flex">
              <div className="card-title">Stock Adjustment Details</div>
              {" "}
             
                   
                    {/* <span style={{ marginLeft: '25px' }}></span> */}

                    <div className="d-flex align-items-center">
                   
          <select
                  value={productTypeFilter}
                  onChange={(e) => setProductTypeFilter(Number(e.target.value))}
                  style={{
                    width: '200px',
                    height: '45px',
                    fontSize: '16px',
                    padding: '10px 14px',
                    border: 'none',
                    borderRadius: '8px',
                    backgroundColor: '#f8f9fa',
                    color: '#495057',
                    fontFamily: 'Segoe UI, Tahoma, Geneva, Verdana, sans-serif',
                    cursor: 'pointer',
                    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                    transition: 'background-color 0.3s, box-shadow 0.3s, border-color 0.3s',
                    outline: 'none',
                    appearance: 'none',
                    backgroundImage: 'url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\' viewBox=\'0 0 16 16\' fill=\'none\'><path d=\'M8 12a1 1 0 0 1-.707-1.707L11.586 6 7.293 1.707A1 1 0 0 1 8 0a1 1 0 0 1 .707 1.707L5.414 6l3.293 3.293A1 1 0 0 1 8 12z\' fill=\'#495057\'/></svg>")',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'right 10px center',
                    backgroundSize: '16px 16px',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    marginLeft:"250px",
                    position:"absolute",
                  }}
                  onFocus={(e) => {
                    e.target.style.backgroundColor = '#e9ecef';
                    e.target.style.boxShadow = '0px 6px 8px rgba(0, 0, 0, 0.2)';
                  }}
                  onBlur={(e) => {
                    e.target.style.backgroundColor = '#f8f9fa';
                    e.target.style.boxShadow = '0px 4px 6px rgba(0, 0, 0, 0.1)';
                  }}
                >
                  <option value="" disabled hidden>Select Product Category Type</option>
                  {productTypes.map((type) => (
                    <option key={type.id} value={type.id}>
                      {type.categorytype}
                    </option>
                  ))}
                </select>
             
              
            </div>
            </div>
            <br />
            {productTypeFilter === 1 ? (
  <>
                  <button className="btn btn-primary"   style={{ fontSize: '10px', padding: '8px 6px', marginLeft: '900px' }}onClick={(e) => updateStockDateHandler(e, searchDate)}>Update Stock</button>

            <table className="table table-bordered ">
              <thead>
                <tr
                  style={{
                    fontSize: "17px",
                    backgroundColor: " #6200ea",
                    color: "white",
                    textAlign: "center",
                  }}
                >
                  <th id="date">Product</th>

                  <th id="full">Full</th>

                  <th id="empty">Empty</th>
                  <th id="defective">Defective</th>
                </tr>{" "}
              </thead>
              <tbody>
              {openstock.length === 0 ? (
            <tr>
              <td colSpan={product.length + 1}  className="text-center">
                No Data Available
              </td>
            </tr>
          ) : (
            searchstate &&
            openstock
              .filter(products => products.product.productCategory?.productCategoryTypeId === 1)
              .map(products => (

                <tr key={products.product.productname}>
                  <th>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div style={{ width: "30px", height: "30px", marginRight: '5px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  {products.product.image && products.product.imageMeta ? (
                            <img
                              className="img-fluid rounded-circle mb-1"
                              src={`data:image/png;base64,${products.product.image}`}
                              alt="Product"
                              style={{ maxWidth: "100%", maxHeight: "100%" }}
                            />
                          ) : (
                            <div style={{ width: "30px", height: "30px", borderRadius: "50%" }}></div>
                          )}
 </div>
                        <span style={{fontSize: '14px', marginBottom: '5px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '150px' }}>
                        {products.product.productname}
                          </span>
                       
                        </div>
                      </th>    
                                    {products.pdo.map((ps, index) => (
                    <th key={index}>{ps.qty}</th>
                  ))}
                </tr>
              ))
          )}
              </tbody>
            </table>
            </>
          ) : null}
            {productTypeFilter === 2 ? (
            <>
    <table className="table table-bordered" style={{ width: '90%', margin: '0 auto', tableLayout: 'fixed' }}>
    <thead>
                <tr
                  style={{
                    fontSize: "17px",
                    backgroundColor: " #6200ea",
                    color: "white",
                    textAlign: "center",         
                  }}
                >
                  <th id="date" style={{ width: '20%', padding: '10px' }}>Product</th>
                  <th id="full" style={{ width: '20%', padding: '10px' }}>Quantity</th>
                  <th id="addquantity" style={{ width: '20%', padding: '10px' }}>Add Quantity</th>
                  <th id="removequantity" style={{ width: '20%', padding: '10px' }}>Remove Quantity</th>
                </tr>{" "}
              </thead>
              <tbody>
              {openstock.length === 0 ? (
            <tr>
              <td colSpan={4}  className="text-center">
                No Data Available
              </td>
            </tr>
          ) : (
            searchstate &&
            openstock
              .filter(products => products.product.productCategory?.productCategoryTypeId === 2)
              .map(products =>  {
                const fullPdo = products.pdo.filter(ps => ps.status === "full");
                return (
                <tr key={products.product.productname}>
 <th>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div style={{ width: "30px", height: "30px", marginRight: '5px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  {products.product.image && products.product.imageMeta ? (
                            <img
                              className="img-fluid rounded-circle mb-1"
                              src={`data:image/png;base64,${products.product.image}`}
                              alt="Product"
                              style={{ maxWidth: "100%", maxHeight: "100%" }}
                            />
                          ) : (
                            <div style={{ width: "30px", height: "30px", borderRadius: "50%" }}></div>
                          )}
 </div>
                        <span style={{fontSize: '14px', marginBottom: '5px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '150px' }}>
                        {products.product.productname}
                          </span>
                       
                        </div>
                      </th>              
                                 <td style={{ textAlign: 'center'}}>
              {products.pdo
                .filter((ps) => ps.status === "full")
                .map((ps, index) => ps.qty)}
            </td>
            <td>
            {fullPdo.map((ps, index) => (
     
  <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
    <button
      className="btn btn-primary btn-sm"
      onClick={() => handleOpenModal(products.product.productname,  ps.qty, products.product.productcode)}
    >
      Add
    </button>
    {/* <span>
      <FiInfo onClick={() => openAddQuantityModal(products.product.id)} style={{ cursor: 'pointer' }} />
    </span> */}
  </div>
  ))}
</td>
<td>
{fullPdo.map((ps, index) => (
                        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>

                          <button
                            className="btn btn-primary btn-sm"
                            onClick={() => handleOpenModal1(products.product.productname, ps.qty,products.product.productcode)}
                          >
                            Remove
                          </button>
                          {/* <span>
      <FiInfo onClick={() => openRemoveQuantityModal(products.product.id)} style={{ cursor: 'pointer' }} />
      </span> */}
                      </div>
                    ))}
                  </td>
                </tr>
              );
            })
        )}
      </tbody>
    </table>
  </>
) : null}
           
          </div>
        </div>
       </div>
</>
)}
</>
)}

 {(!searchstate && !tsearchstate) && (
 <>
        {errorMessage ===true ? (
          <>
          <div style={{ position: "relative", top: "40px", textAlign: "center" }} className="col-11 mx-xl-auto ps-xl-10">
   <div className="card card-raised">
     <div className="card-body">
<h5 style={{ color: "red" }}>No data exists for your organization before {orgStartDate}.</h5>
</div>
           </div>
           </div>
         </>
        ) : (
          <>
            {openingstock.length ===0 ? (
              <>
              <div style={{ position: "relative", top: "40px", textAlign: "center" }} className="col-11 mx-xl-auto ps-xl-10">
   <div className="card card-raised">
     <div className="card-body">
                <h5 style={{ color: "red" }}>Please Complete Day End Transaction for Previous Date.</h5>
                <br />
              
                  <button className="btn btn-primary" onClick={(e) => dateHandler(e, searchDate)}>Create Exception</button>
                  </div>
                  </div>
                  </div>
              </>  
        ) : (
          <>
        <br></br>
       

         <div className="col-12  mx-xl-auto ps-xl-10">
    <div className="card card-raised">
      <div className="card-body ">
        <div className="d-flex">
        <div className="card-title">Stock Adjustment Details</div>
         
                <select
                  value={productTypeFilter}
                  onChange={(e) => setProductTypeFilter(Number(e.target.value))}
                  style={{
                    width: '200px',
                    height: '45px',
                    fontSize: '16px',
                    padding: '10px 14px',
                    border: 'none',
                    borderRadius: '8px',
                    backgroundColor: '#f8f9fa',
                    color: '#495057',
                    fontFamily: 'Segoe UI, Tahoma, Geneva, Verdana, sans-serif',
                    cursor: 'pointer',
                    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                    transition: 'background-color 0.3s, box-shadow 0.3s, border-color 0.3s',
                    outline: 'none',
                    appearance: 'none',
                    backgroundImage: 'url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\' viewBox=\'0 0 16 16\' fill=\'none\'><path d=\'M8 12a1 1 0 0 1-.707-1.707L11.586 6 7.293 1.707A1 1 0 0 1 8 0a1 1 0 0 1 .707 1.707L5.414 6l3.293 3.293A1 1 0 0 1 8 12z\' fill=\'#495057\'/></svg>")',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'right 10px center',
                    backgroundSize: '16px 16px',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    marginLeft:"250px",
                    position:"absolute",
                  }}
                  onFocus={(e) => {
                    e.target.style.backgroundColor = '#e9ecef';
                    e.target.style.boxShadow = '0px 6px 8px rgba(0, 0, 0, 0.2)';
                  }}
                  onBlur={(e) => {
                    e.target.style.backgroundColor = '#f8f9fa';
                    e.target.style.boxShadow = '0px 4px 6px rgba(0, 0, 0, 0.1)';
                  }}
                >
                  <option value="" disabled hidden>Select Product Category Type</option>
                  {productTypes.map((type) => (
                    <option key={type.id} value={type.id}>
                      {type.categorytype}
                    </option>
                  ))}
                </select>
         
        </div>
        <br />
        
       
        {productTypeFilter === 1 ? (
  <>
   <div style={{ position: "relative", marginLeft: "60%" }}>
        
        <button className="btn btn-primary"  style={{ fontSize: '10px', padding: '8px 6px', marginLeft: '240px' }} onClick={(e) => updateStockDateHandler(e, searchDate)}>Update Stock</button>
  
      </div>
            <table className="table table-bordered ">
              <thead>
                <tr
                  style={{
                    fontSize: "17px",
                    backgroundColor: " #6200ea",
                    color: "white",
                    textAlign: "center",
                  }}
                >
                  <th id="date">Product</th>

                  <th id="full">Full</th>

                  <th id="empty">Empty</th>
                  <th id="defective">Defective</th>
                </tr>{" "}
              </thead>
              <tbody>
              {openstock.length === 0 ? (
            <tr>
              <td colSpan={product.length + 1}  className="text-center">
                No Data Available
              </td>
            </tr>
          ) : (
            openstock
              .filter(products => products.product.productCategory?.productCategoryTypeId === 1)
              .map(products => (
                <tr key={products.product.productname}>
 <th>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div style={{ width: "30px", height: "30px", marginRight: '5px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  {products.product.image && products.product.imageMeta ? (
                            <img
                              className="img-fluid rounded-circle mb-1"
                              src={`data:image/png;base64,${products.product.image}`}
                              alt="Product"
                              style={{ maxWidth: "100%", maxHeight: "100%" }}
                            />
                          ) : (
                            <div style={{ width: "30px", height: "30px", borderRadius: "50%" }}></div>
                          )}
 </div>
                        <span style={{fontSize: '14px', marginBottom: '5px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '150px' }}>
                        {products.product.productname}
                          </span>
                       
                        </div>
                      </th>   
                                      {products.pdo.map((ps, index) => (
                    <th key={index}>{ps.qty}</th>
                  ))}
                </tr>
              ))
          )}
              </tbody>
            </table>
            </>
          ) : null}
         {productTypeFilter === 2 ? (
            <>
    <table className="table table-bordered" style={{ width: '90%', margin: '0 auto', tableLayout: 'fixed' }}>
    <thead>
                <tr
                  style={{
                    fontSize: "17px",
                    backgroundColor: " #6200ea",
                    color: "white",
                    textAlign: "center",         
                  }}
                >
                  <th id="date" style={{ width: '20%', padding: '10px' }}>Product</th>
                  <th id="full" style={{ width: '20%', padding: '10px' }}>Quantity</th>
                  <th id="addquantity" style={{ width: '20%', padding: '10px' }}>Add Quantity</th>
                  <th id="removequantity" style={{ width: '20%', padding: '10px' }}>Remove Quantity</th>
                </tr>{" "}
              </thead>
              <tbody>
              {openstock.length === 0 ? (
            <tr>
              <td colSpan={4}  className="text-center">
                No Data Available
              </td>
            </tr>
          ) : (
            searchstate &&
            openstock
              .filter(products => products.product.productCategory?.productCategoryTypeId === 2)
              .map(products =>  {
                const fullPdo = products.pdo.filter(ps => ps.status === "full");
                return (
                <tr key={products.product.productname}>
 <th>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div style={{ width: "30px", height: "30px", marginRight: '5px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  {products.product.image && products.product.imageMeta ? (
                            <img
                              className="img-fluid rounded-circle mb-1"
                              src={`data:image/png;base64,${products.product.image}`}
                              alt="Product"
                              style={{ maxWidth: "100%", maxHeight: "100%" }}
                            />
                          ) : (
                            <div style={{ width: "30px", height: "30px", borderRadius: "50%" }}></div>
                          )}
 </div>
                        <span style={{fontSize: '14px', marginBottom: '5px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '150px' }}>
                        {products.product.productname}
                          </span>
                       
                        </div>
                      </th>              
                                 <td style={{ textAlign: 'center'}}>
              {products.pdo
                .filter((ps) => ps.status === "full")
                .map((ps, index) => ps.qty)}
            </td>
            <td>
            {fullPdo.map((ps, index) => (
     
  <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
    <button
      className="btn btn-primary btn-sm"
      onClick={() => handleOpenModal(products.product.productname,  ps.qty, products.product.productcode)}
    >
      Add
    </button>
    {/* <span>
      <FiInfo onClick={() => openAddQuantityModal(products.product.id)} style={{ cursor: 'pointer' }} />
    </span> */}
  </div>
  ))}
</td>
<td>
{fullPdo.map((ps, index) => (
                        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>

                          <button
                            className="btn btn-primary btn-sm"
                            onClick={() => handleOpenModal1(products.product.productname, ps.qty,products.product.productcode)}
                          >
                            Remove
                          </button>
                          {/* <span>
      <FiInfo onClick={() => openRemoveQuantityModal(products.product.id)} style={{ cursor: 'pointer' }} />
      </span> */}
                      </div>
                    ))}
                  </td>
                </tr>
              );
            })
        )}
      </tbody>
    </table>
</>
          ) : null}


          </div>
        </div>
       </div>
    
</>

)}
</>
)}
</>
)}

{isStockEditModalOpen && (
              <AddquantityModalPopup
                isOpen={isStockEditModalOpen}
                onRequestClose={closeModal}
                productname={selectedStockproduct}
                quantity={selectedStockquantity}
                productcode={selectedStockproductId}


              />
            )}
             {isStockEditModalOpen1 && (
              <RemovequantityModalPopup
                isOpen={isStockEditModalOpen1}
                onRequestClose={closeModal1}
                productname={selectedStockproduct}
                quantity={selectedStockquantity}
                productcode={selectedStockproductId}


              />
            )}

{isAddHistoryModalOpen && (
              <AddHistory
                onClose={closeAddHistoryModal}
                initialPriceHistory={addHistory}
                vendorproductid={selectedStockproductId}
              />
            )}
            {isRemoveHistoryModalOpen && (
              <RemoveHistory
                onClose={closeRemoveHistoryModal}
                initialPriceHistory={removeHistory}
                vendorproductid={selectedStockproductId}
              />
            )}
    </div>
  );
}
export default StockManagement;
