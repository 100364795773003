import React from "react";
// import "./CreditViewPrint.css";

const CreditViewPrint = ({
  orgAddress,
  creditdata,
  selectedProducts,
  selectedCustomer,
  currentDate,
  resultValue,
  calculateSubTotal,
  TotalTaxAmount,
  calculateTotalQuantity,
  numberToWords,
  paperSize = "A4",
}) => {
  // Define paper dimensions in mm for scaling
  const paperDimensions = {
    A4: { width: 210, height: 297 }, // A4 size in mm
    A3: { width: 297, height: 420 }, // A3 size in mm
  };

  // Get dimensions for the selected paper size
  const selectedDimensions = paperDimensions[paperSize];
  const baseDimensions = paperDimensions.A4;

  // Calculate scale factor to fit the content dynamically
  const scale = Math.min(
    selectedDimensions.width / baseDimensions.width,
    selectedDimensions.height / baseDimensions.height
  );

  const styles = {
    wrapper: {
      width: `${selectedDimensions.width}mm`,
      height: `${selectedDimensions.height}mm`,
      overflow: "hidden", // Prevents content overflow
      position: "relative",
      margin: "0 auto",
      backgroundColor: "#fff",
      border: "1px solid #000",
      boxSizing: "border-box",
    },
    container: {
      transform: `scale(${scale})`,
      transformOrigin: "top left",
      width: `${baseDimensions.width}mm`,
      height: `${baseDimensions.height}mm`,
      padding: "10mm",
      fontFamily: "Arial, sans-serif",
      boxSizing: "border-box",
    },
    header: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      borderBottom: "1px solid #000",
      paddingBottom: "10px",
    },
    table: {
      width: "100%",
      borderCollapse: "collapse",
    },
    tableCell: {
      border: "1px solid #000",
      padding: "8px",
      textAlign: "left",
    },
    footer: {
      marginTop: "20px",
      textAlign: "center",
    },
  };

  return (
    <div style={styles.wrapper}>
      <div style={styles.container}>
        {/* Header */}
        <div style={styles.header}>
          <div>
            {orgAddress?.logo && (
              <img
                src={`${orgAddress.imageMeta},${orgAddress.logo}`}
                alt="Company Logo"
                style={{ width: "80px", height: "80px", marginRight: "15px" }}
              />
            )}
            <h2 style={{ margin: 0, fontSize: "1.5rem" }}>
              {orgAddress?.name || ""}
            </h2>
            <p>{orgAddress?.addressLine1 || ""}</p>
            <p>{orgAddress?.addressLine2 || ""}</p>
            <p>
              {orgAddress
                ? `${orgAddress.city}, ${orgAddress.state} - ${orgAddress.pincode}`
                : ""}
            </p>
            <p>GSTIN: {orgAddress?.gstin || ""}</p>
          </div>
          <div style={{ textAlign: "right" }}>
            <p>Date: {currentDate}</p>
            <h3 style={{ margin: 0 }}>Performa Bill</h3>
          </div>
        </div>

        {/* Customer Details */}
        <div>
          <h5>To</h5>
          {selectedCustomer ? (
            <div>
              <p>{selectedCustomer.customername || "N/A"}</p>
              <p>{selectedCustomer.customerNumber || "N/A"}</p>
            </div>
          ) : (
            <p>Loading customer details...</p>
          )}
        </div>

        {/* Credit Note Details */}
        <div>
          <table style={styles.table}>
            <thead>
              <tr>
                <th style={styles.tableCell}>Description</th>
                <th style={styles.tableCell}>Quantity</th>
                <th style={styles.tableCell}>Base Amount</th>
                <th style={styles.tableCell}>Tax</th>
                <th style={styles.tableCell}>Amount</th>
              </tr>
            </thead>
            <tbody>
              {selectedProducts.map((product, index) => (
                <tr key={index}>
                  <td style={styles.tableCell}>{product.productname}</td>
                  <td style={styles.tableCell}>{product.quantity}</td>
                  <td style={styles.tableCell}>{product.price || 0}</td>
                  <td style={styles.tableCell}>
                    <div>
                      Tax: {product.gst}% , ₹
                      {product.gst * product.amount / 100}
                    </div>
                    <div>
                      CGST: {product.gst / 2}% , ₹
                      {(product.gst * product.amount / 100) / 2}
                    </div>
                    <div>
                      SGST: {product.gst / 2}% , ₹
                      {(product.gst * product.amount / 100) / 2}
                    </div>
                  </td>
                  <td style={styles.tableCell}>{product.amount || 0}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* Summary */}
        <div>
          <p>Tariff Charge: ₹{creditdata.tariff || 0}</p>
          <p>Total Quantity: ₹{calculateTotalQuantity}</p>
          <p>Subtotal: ₹{calculateSubTotal()}</p>
          <p>Tax: ₹{TotalTaxAmount(selectedProducts)}</p>
          <p>Total Tariff: ₹{resultValue.Totaltariff}</p>
          <p>Total Amount: ₹{resultValue.Totalamount}</p>
          <p>
            (Amount in words: {numberToWords(resultValue.Totalamount)} rupees
            only)
          </p>
        </div>

        {/* Footer */}
        <div style={styles.footer}>
          <p>Note: This performa bill is applicable only for the date mentioned above.</p>
          <p>For {orgAddress?.name || "Your Company Name"}</p>
          <p>Authorized Signatory</p>
        </div>
      </div>
    </div>
  );
};

export default CreditViewPrint;
