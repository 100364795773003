import React, { useEffect, useState } from "react";
import AdminDashboard from "./admindashboard";
import axios from "../api/baseurl";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Loader.css";
import { Select, MenuItem } from "@mui/material";
import {
    Card,
    CardContent,
    Grid,
    Typography,
    Tooltip,
    Table,
    IconButton,
    TextField,
    Button,
    TableCell,
    TableBody,
    TableHead,
    TableContainer,
    Paper,
    TableRow,
} from "@mui/material";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { useNavigate, useParams } from "react-router-dom";

function PurchaseView() {
    const { purchaseOrderId } = useParams();
    const [purchases, setPurchases] = useState([]);
    const [loader, setLoader] = useState(false);
    // const [isViewMode, setIsViewMode] = useState(!!purchaseOrderId);
    const navigate = useNavigate();
    const [selectedVendorAndStockist, setSelectedVendorAndStockist] = useState(null);
    console.log(selectedVendorAndStockist)
    const [productList, setProductList] = useState([]);


    const [billTo, setBillTo] = useState("");
    const [shipTo, setShipTo] = useState("");
    const [orderDate, setOrderDate] = useState("");
    const [purchaseid, setPurchaseid] = useState("");
    const [receiveDate, setReceiveDate] = useState("");
    const [dueDate, setDueDate] = useState("");
    const [memo, setMemo] = useState("");
    const [description, setDescription] = useState("");
    const [orgAddress, setOrgAddress] = useState("");
    const userid = JSON.parse(localStorage.getItem("data"))?.userid;
    const [stockistId, setStockistId] = useState(null);
    const [loadingProducts, setLoadingProducts] = useState(false);


    useEffect(() => {

        fetchPurchaseDetails();

    }, []);
    useEffect(() => {
        if (stockistId) {
            fetchVendorAndStockistNames(stockistId);
        }
    }, [stockistId]);

    const fetchPurchaseDetails = async () => {

        setLoader(true);
        try {

            const token = JSON.parse(localStorage.getItem("data")).token;
            const response = await axios.get(`/api/purchaseOrderDo/getByPurchaseOrderId/purchaseOrderId=${purchaseOrderId}`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            const purchaseData = response.data;
            console.log(purchaseData);

            if (purchaseData && Array.isArray(purchaseData.purchasedProducts)) {
                setProductList(purchaseData.purchasedProducts);
                console.log("Product List:", purchaseData.purchasedProducts);
            } else {
                setProductList([]);
                console.warn("No purchased products found.");
            }
            setPurchases(purchaseData.purchasedProducts);
            setPurchaseid(purchaseData.purchaseid);
            setBillTo(purchaseData.billTo);
            setShipTo(purchaseData.shipTo);
            setOrderDate(purchaseData.orderdate);
            setDueDate(purchaseData.duedate);
            setReceiveDate(purchaseData.receiveDate);
            setMemo(purchaseData.memo);
            setDescription(purchaseData.description);
            setStockistId(purchaseData.stockistId);



            // await fetchVendorAndStockistNames(purchaseData.stockistId);
        } catch (error) {
            handleError(error);
        } finally {
            setLoader(false);
        }
    };
    useEffect(() => {

    }, [productList]);

    const fetchVendorAndStockistNames = async (stockistId) => {
        setLoadingProducts(true);
        const token = JSON.parse(localStorage.getItem("data")).token;
        try {
            const stockistMappingResponse = await axios.get(`/api/stockistmapping/stockistid=${stockistId}`, {
                headers: { Authorization: `Bearer ${token}` },
            });

            console.log("Stockist Mapping Response:", stockistMappingResponse.data);

            const stockistData = stockistMappingResponse.data[0]?.stockist || stockistMappingResponse.data.stockist;

            const productList = stockistMappingResponse.data.map(item => {
                return {
                    vendorProductId: item.vendorproduct.id,
                    productName: item.vendorproduct.productname || "N/A",
                    basePrice: item.vendorproduct.baseprice,
                };
            }) || [];

            setSelectedVendorAndStockist({
                selectedVendor: stockistData.selectedVendor,
                selectedStockistVendorid: stockistData.selectedStockistVendorid,
                selectedStockistId: stockistData.id,
                selectedStockistName: stockistData.name,
                selectedStockistAddress: stockistData.address,
                selectedStockistEmail: stockistData.email,
                selectedStockistPhone: stockistData.phone,
            });

            setProductList(productList);
        } catch (error) {
            toast.error("Error fetching stockist mapping data");
        } finally {
            setLoadingProducts(false);
        }
    };


    const handleAddPurchaseRow = () => {
        const newPurchaseOrderData = {
            id: "",
            vendorProductId: "",
            description: "",
            quantity: 1
        }
        setPurchases([...purchases, newPurchaseOrderData]);
    };


    const handleFieldValueChange = (index, fieldKey, value) => {
        const updatedPurchases = [...purchases];
        updatedPurchases[index][fieldKey] = value;
        setPurchases(updatedPurchases);
    };

    const fetchOrgAddress = async (userid) => {
        const token = JSON.parse(localStorage.getItem("data")).token;
        try {
            const response = await axios.get(`/api/organizationprofiles/Orgcode=${JSON.parse(localStorage.getItem('data')).orgcode}`, {
                headers: { Authorization: `Bearer ${token}` },
            });

            const { addressLine1, addressLine2, name, state, city, pincode, gstin, pan, contact ,logo,imageMeta} = response.data;
            const fullAddress = `${addressLine1 || ''} ${addressLine2 || ''} ${name || ''} ${state || ''} ${pincode || ''} ${gstin || ''} ${pan || ''}${contact || ''}${logo || ''}${imageMeta || ''}`.trim();
            setOrgAddress({
                addressLine1,
                addressLine2,
                name,
                city,
                state,
                pincode,
                gstin,
                pan,
                gstin,
                contact,
                logo,
                imageMeta
            });
        } catch (error) {
            handleError(error);
        }
    };
    const handleErrors = (error) => {
        if (error.response && error.response.status === 404) {
            toast.error(error.response.data.message);
        } else {
            toast.error("An error occurred while fetching data");
        }
    };
    useEffect(() => {
        if (userid) {
            fetchOrgAddress(userid);
        }
    }, [userid]);

    useEffect(() => {
        if (orgAddress) {
            setShipTo(orgAddress);
        }
        if (orgAddress) {
            setBillTo(orgAddress);
        }
    }, [orgAddress]);


    const handleSavePurchases = async () => {
        if (!dueDate) {
            toast.error("Due date is required. Please provide a valid due date.");
            return;
        }
        const billToAddress = orgAddress ? `${orgAddress.name}, ${orgAddress.addressLine1}, ${orgAddress.addressLine2}, ${orgAddress.city}, ${orgAddress.state}, ${orgAddress.pincode}, GST: ${orgAddress.gstin}, PAN: ${orgAddress.pan}, Contact: ${orgAddress.contact}` : "N/A";
        const shipToAddress = orgAddress ? `${orgAddress.name}, ${orgAddress.addressLine1}, ${orgAddress.addressLine2}, ${orgAddress.city}, ${orgAddress.state}, ${orgAddress.pincode}, GST: ${orgAddress.gstin}, PAN: ${orgAddress.pan}, Contact: ${orgAddress.contact}` : "N/A";

        const updatedProducts = purchases.map((purchase) => ({
            id: purchase.id || null,
            vendorProductId: purchase.vendorProductId,
            quantity: purchase.quantity,
            purchaseOrderId: purchaseOrderId,
        }))


        const purchaseOrderData = {
            purchaseOrderId,
            orgcode: JSON.parse(localStorage.getItem("data")).orgcode,
            vendorId: selectedVendorAndStockist.selectedVendor,
            stockistId: selectedVendorAndStockist.selectedStockistId,
            purchasedate: new Date().toISOString().split("T")[0],
            billTo: billTo,
            shipTo: shipTo,
            orderdate: orderDate,
            duedate: dueDate,
            receiveDate,
            memo,
            description,
            purchasedProducts: updatedProducts
        };

        setLoader(true);
        try {
            const token = JSON.parse(localStorage.getItem("data")).token;
            await axios.post(`/api/purchaseOrderDo/update`, purchaseOrderData, {
                headers: { Authorization: `Bearer ${token}` },
            });
            toast.success("Purchase order updated successfully!");
            navigate(-1);
        } catch (error) {
            handleError(error);
        } finally {
            setLoader(false);
        }
    };

    const handleErr = (error) => {
        if (error.response && error.response.status === 404) {
            toast.error(error.response.data.message);
        } else {
            toast.error("An error occurred while fetching data");
        }
        setLoader(false);
    };
    const handleCancel = () => {
        navigate(-1)
    }

    const handleError = (error) => {
        if (error.response && error.response.status === 404) {
            toast.error(error.response.data.message);
        } else {
            toast.error("An error occurred while fetching data");
        }
        setLoader(false);
    };
    const handlePrint = () => {
        const printStyles = `
            @media print {
                .no-print {
                    display: none !important;
                }
            }
        `;
        const styleSheet = document.createElement("style");
        styleSheet.type = "text/css";
        styleSheet.innerText = printStyles;
        document.head.appendChild(styleSheet);
        window.print();
    };




    return (
        <div>
            <AdminDashboard className="no-print" />
            <br /><br /><br /><br />
            {loader && <div className="loading"></div>}
            <div className="col-12 mx-xl-auto ps-xl-10">
                <Card>
                    <CardContent>
                        <Tooltip title="Back" arrow
                            className="no-print">
                            <IconButton onClick={() => navigate(-1)}>
                                <AiOutlineArrowLeft style={{ fontSize: '25px' }} />
                            </IconButton>
                        </Tooltip>

                        <Grid container justifyContent="space-between" alignItems="center">
                            <Typography variant="h5">View Purchase Order</Typography>
                            <div style={{ marginLeft: 'auto', marginRight: '130px' }}>
                                <img
                                   src={`${orgAddress?.imageMeta},${orgAddress?.logo}`}
                                    alt="Organization Logo"
                                    style={{ height: '100px', width: 'auto' }}
                                />
                            </div>
                        </Grid>
                        <br />

                        <div>
                            <p><strong>Stockist Id:</strong> {selectedVendorAndStockist?.selectedStockistId || "Not Selected"}</p>
                            <p><strong>Stockist:</strong> {selectedVendorAndStockist?.selectedStockistName || "Not Selected"}</p>
                            <p><strong>Address:</strong> {selectedVendorAndStockist?.selectedStockistAddress || "Not Selected"}</p>
                            <p><strong>Email:</strong> {selectedVendorAndStockist?.selectedStockistEmail || "Not Selected"}</p>
                            <p><strong>Phone:</strong> {selectedVendorAndStockist?.selectedStockistPhone || "Not Selected"}</p>
                        </div>
                        <br />
                        <Grid container spacing={3}>
                            <Grid item xs={4}>
                                <Typography variant="h6">Bill To</Typography>
                                <Typography variant="body1">
                                    <strong>Organization Name: </strong>{orgAddress?.name || "Not Available"}
                                </Typography>
                                <Typography variant="body1">
                                    <strong>Address Line 1: </strong>{orgAddress?.addressLine1 || "Not Available"}
                                </Typography>
                                <Typography variant="body1">
                                    <strong>Address Line 2: </strong>{orgAddress?.addressLine2 || "Not Available"}
                                </Typography>
                                <Typography variant="body1">
                                    <strong>City: </strong>{orgAddress?.city || "Not Available"}
                                </Typography>
                                <Typography variant="body1">
                                    <strong>Pincode: </strong>{orgAddress?.pincode || "Not Available"}
                                </Typography>
                                <Typography variant="body1">
                                    <strong>State: </strong>{orgAddress?.state || "Not Available"}
                                </Typography>
                                <Typography variant="body1">
                                    <strong>PAN: </strong>{orgAddress?.pan || "Not Available"}
                                </Typography>
                                <Typography variant="body1">
                                    <strong>GST: </strong>{orgAddress?.gstin || "Not Available"}
                                </Typography>
                                <Typography variant="body1">
                                    <strong>Contact Number: </strong>{orgAddress?.contact || "Not Available"}
                                </Typography>
                            </Grid>

                            <br />
                            <Grid item xs={4}>
                                <Typography variant="h6">Ship To</Typography>
                                <Typography variant="body1">
                                    <strong>Organization Name: </strong>{orgAddress?.name || "Not Available"}
                                </Typography>
                                <Typography variant="body1">
                                    <strong>Address Line 1: </strong>{orgAddress?.addressLine1 || "Not Available"}
                                </Typography>
                                <Typography variant="body1">
                                    <strong>Address Line 2: </strong>{orgAddress?.addressLine2 || "Not Available"}
                                </Typography>
                                <Typography variant="body1">
                                    <strong>City: </strong>{orgAddress?.city || "Not Available"}
                                </Typography>
                                <Typography variant="body1">
                                    <strong>Pincode: </strong>{orgAddress?.pincode || "Not Available"}
                                </Typography>
                                <Typography variant="body1">
                                    <strong>State: </strong>{orgAddress?.state || "Not Available"}
                                </Typography>
                                <Typography variant="body1">
                                    <strong>PAN: </strong>{orgAddress?.pan || "Not Available"}
                                </Typography>
                                <Typography variant="body1">
                                    <strong>GST: </strong>{orgAddress?.gstin || "Not Available"}
                                </Typography>
                                <Typography variant="body1">
                                    <strong>Contact Number: </strong>{orgAddress?.contact || "Not Available"}
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <div style={{ marginLeft: '10px', marginTop: '20px' }}>
                                    <p style={{ fontWeight: 'bold' }}>#No. {purchaseid}</p>
                                    <div>
                                        <span style={{ fontWeight: 'bold' }}>Order Date:</span> {orderDate || "Not Available"}
                                    </div>
                                    <div>
                                        <span style={{ fontWeight: 'bold' }}>Due Date:</span> {dueDate || 'Not Available'}
                                    </div>
                                    <div>
                                        <span style={{ fontWeight: 'bold' }}>Received Date:</span> {receiveDate || 'Not Available'}
                                    </div>
                                </div>
                            </Grid>
                        </Grid>

                    <br />

                    {loadingProducts ? (
                        <div className="loading">Loading products...</div>
                    ) : (
                        <TableContainer component={Paper}>
                           <div style={{ padding: '20px' }}>
    <Table style={{ borderCollapse: 'collapse', width: '100%' }}>
        <TableHead>
            <TableRow>
                <TableCell style={{ fontWeight: 'bold', border: '1px solid #ddd', padding: '8px' }}>Product</TableCell>
                <TableCell style={{ fontWeight: 'bold', border: '1px solid #ddd', padding: '8px' }}>Description</TableCell>
                <TableCell style={{ fontWeight: 'bold', border: '1px solid #ddd', padding: '8px' }}>Quantity</TableCell>
            </TableRow>
        </TableHead>
        <TableBody>
            {purchases.map((purchase, index) => (
                <TableRow key={index}>
                    <TableCell style={{ border: '1px solid #ddd', padding: '8px' }}>
                        <Select
                            value={purchase.vendorProductId || ""}
                            onChange={(e) => handleFieldValueChange(index, "vendorProductId", e.target.value)}
                            displayEmpty
                            fullWidth
                            disabled
                            style={{ backgroundColor: '#f9f9f9', width: '100%' }}
                        >
                            <MenuItem value="" disabled>Select Product</MenuItem>
                            {productList.map((product) => (
                                <MenuItem key={product.vendorProductId} value={product.vendorProductId}>
                                    {product.productName || "N/A"}
                                </MenuItem>
                            ))}
                        </Select>
                    </TableCell>
                    <TableCell style={{ border: '1px solid #ddd', padding: '8px' }}>
                        <TextField
                            type="text"
                            value={purchase.description || ""}
                            onChange={(e) => handleFieldValueChange(index, "description", e.target.value)}
                            fullWidth
                            disabled
                            style={{ backgroundColor: '#f9f9f9' }}
                        />
                    </TableCell>
                    <TableCell style={{ border: '1px solid #ddd', padding: '8px' }}>
                        <TextField
                            type="number"
                            value={purchase.quantity || 0}
                            onChange={(e) => handleFieldValueChange(index, "quantity", e.target.value)}
                            fullWidth
                            disabled
                            style={{ backgroundColor: '#f9f9f9' }}
                        />
                    </TableCell>
                </TableRow>
            ))}
        </TableBody>
    </Table>
</div>
                        </TableContainer>
                    )}


                    <br />
                    <Grid item xs={6}>
                        <Grid item xs={2}>
                            <TextField
                                label="Memo"
                                value={memo}
                                onChange={(e) => setMemo(e.target.value)}
                                fullWidth
                                multiline
                                rows={2}
                                disabled
                            />
                        </Grid>
                    </Grid>
                    <br />
                    <Grid item xs={12} container justifyContent="flex-end">
                        <Button
                            onClick={handleCancel}
                            variant="outlined"
                            color="secondary"
                            className="no-print"
                            style={{ marginRight: "10px" }}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handlePrint}
                            className="no-print"
                            style={{ marginLeft: '10px', height: '35px' }}
                        >
                            Print
                        </Button>
                    </Grid>
                </CardContent>
            </Card>
        </div>
        </div >
    );
}

export default PurchaseView;

