import React, { useEffect, useState } from "react";
import AdminDashboard from "./admindashboard";
import axios from "../api/baseurl";
import {useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Loader.css";
import { BiEditAlt } from "react-icons/bi";
import "./Spinner.css";
import { FiInfo } from "react-icons/fi";
import CreateService from "./Createservice";
import UpdateService from "./Updateservice";
import Servicecostmodal from "./Salescostmodal";
function ProductService() {
  const [selectedProductId, setSelectedProductId] = useState(null);
  const [priceHistory, setPriceHistory] = useState([]); 
  const [isProductEditModalOpen, setIsProductEditModalOpen] = useState(false);
  const [isPriceHistoryModalOpen, setIsPriceHistoryModalOpen] = useState(false);
  const [product, setProduct] = useState([]);
  const [loading, setLoading] = useState(null);
  const [loader, setLoader] = useState(false);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const navigate = useNavigate();
 

    const getData = async () => {
      setLoader(true);
      try {
        const token = JSON.parse(localStorage.getItem("data")).token;
        const orgCode = JSON.parse(localStorage.getItem("data")).orgcode;

        const productResponse = await axios.get(`/api/product-services/orgid=${orgCode}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setProduct([...productResponse.data]);

       
        setLoader(false);
      } catch (error) {
        setLoader(false);
        handleError(error);
      }
    };

    function handleError(error) {
      if (error.response) {
        if (error.response.status === 401) {
          navigate(`/signin`);
          toast.error("Your session has expired. Please login.");
        } else if (error.response.data && error.response.data.message) {
          toast.error(error.response.data.message);
        }  else if (error.response.data && error.response.data.code) {
          toast.error(error.response.data.code);
        }
         else {
          toast.error("An error occurred.");
        }
      } else if (error.message) {
        toast.error(error.message);
      } else {
        toast.error("An error occurred.");
      }
    }
  useEffect(() => {
    getData();
    
  }, [isCreateModalOpen]);
 
 
  function onClickhandler(e, id, disabled) {
    // setLoading(id);
    setLoading(true)
    const token = JSON.parse(localStorage.getItem("data")).token;
   
    console.log(token);
    axios.post(`/api/product-services/update`, {
      id: id,
      disabled: disabled
    }, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        setLoading(false);
        toast.success("Status updated");
        getData();
      })
      .catch((error)=>{
        setLoading(false);
        handleError(error);
      })
  }

  const handleProductEdit = async (Id) => {
    setSelectedProductId(Id);
      setIsProductEditModalOpen(true);
  };
 
  const closeProductEditModal = () => {
    setIsProductEditModalOpen(false);
    getData();
 
  };

  
 
  const openPriceHistoryModal = async (Id) => {
    setSelectedProductId(Id);
    try {
      const token = JSON.parse(localStorage.getItem("data")).token;
      const response = await axios.get(`/api/service-cost/serviceid=${Id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setPriceHistory(response.data);
    } catch (error) {
      handleError(error);
    }
    setIsPriceHistoryModalOpen(true);
  };
 
  const closePriceHistoryModal = () => {
    setSelectedProductId(null);
    setIsPriceHistoryModalOpen(false);
  };

  
 
  
  const handleCreateModalOpen = () => {
    setIsCreateModalOpen(true);
    setProduct([]);

  };

  const handleCreateModalClose = () => {
    setIsCreateModalOpen(false);
    setProduct([]);
  };

  return (
    <div>
      <div>
        <AdminDashboard />
      </div>
      <br />
      <br />
      <br />
      <br />
      
      {loader && <div className="loading"></div>} 
      <div className="col-12  mx-xl-auto ps-xl-10 ">
        <div className="card card-raised">
          <div className="card-body  ">
            <div className="card-title form-group d-flex align-items-center justify-content-between">Service Details
            
              <button className="btn btn-primary" onClick={handleCreateModalOpen}>
                ADD
              </button>
            
            </div>
  
            <br />
            <div className="table-responsive">

           

            <table className="table table-bordered ">
              <thead>
                <tr
                  style={{
                    fontSize: "17px",
                    backgroundColor: "blue",
                    color: "white",
                  }}
                >
                   <th>Service Name</th>
                   <th>Service Code</th>

                  <th>Points</th>
                  <th>Service Cost</th>
                  <th>GST</th>

                  <th>Status</th>
                  <th>Edit</th>

                </tr>
              </thead>
              <tbody>
              {product.length === 0 ? (
                    <tr>
                      <td colSpan="15" className="text-center">
                        No Data Available
                      </td>
                    </tr>
          ) : (

            product.map((product) => {      
             
              return (
                <tr key={product.id}>
                  <td>{product.serviceName}</td>
                  <td>{product.serviceCode}</td>

                    <td>{product.points}</td>
                      <td>
                          <div style={{ display: 'flex',justifyContent: 'space-between', alignItems: 'center' }}>
                          {product.serviceCost.cost && (
                                <>
                                  {product.serviceCost.cost}
                                  <span>
                                    <FiInfo onClick={() => openPriceHistoryModal(product.id)} style={{ cursor: 'pointer' }} />
                                  </span>
                                </>
                              )}
                          </div>
                        </td>
                        <td>{product.gst}</td>

                       
                        <td>
                        <div className="form-check form-switch">
                          {loading == product.id && (
                            <div
                              className="loader"
                              style={{
                                position: "relative",
                                left: "55px",
                                top: "8px",
                              }}
                            ></div>
                          )}{" "}
                          
                          <input
                            className="form-check-input"
                            id="flexSwitchCheckDefault"
                            type="checkbox"
                            defaultChecked={product.disabled=== false}
                            style={{ cursor: "pointer"}}
                            onClick={(e) => {
                                e.stopPropagation();
                                onClickhandler(e, product.id, !product.disabled);
                              }}
                            value={product.id}
                          />
                        </div>
                      </td>
                       
                       
                      <td> 
                     <button
                      onClick={() => handleProductEdit(product.id)}
                  style={{
                         border: "none",
                         background: "white",
                         size: "150px",
                       }}
                     >
                       <BiEditAlt />
                     </button>
                   </td>
                     
                    </tr>
                  );
                }))}
          
                {" "}
              </tbody>
            </table>
         


            </div>


            <div>
            {isPriceHistoryModalOpen && (
              <Servicecostmodal
                onClose={closePriceHistoryModal}
                initialPriceHistory={priceHistory}
                Id={selectedProductId}
              />
            )}
             
             
            {isProductEditModalOpen && (
              <UpdateService
                isOpen={isProductEditModalOpen}
                onRequestClose={closeProductEditModal}
                Id={selectedProductId}
              />
            )}

          
            {isCreateModalOpen && (
           <CreateService isOpen={isCreateModalOpen} onRequestClose={handleCreateModalClose} />
            )}

        

      </div>


          </div>
        </div>{" "}
      </div>
    </div>   
  );
}

export default ProductService;
