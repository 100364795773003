import React, { useEffect, useState, useReducer } from "react";
import AdminDashboard from "./admindashboard";
import axios from "../api/baseurl";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { BiEditAlt } from "react-icons/bi";
import OpenGoogleMap from "./OpenGoogleMap";
import Dagentandvehiclemapping from "./Dagentandvehiclemapping";
import VehicleeditsModalPopup from "./Vehicleedit";
import AddVehiclesmodalpopup from "./Addvehicle";
import {FaSearch } from "react-icons/fa";
import { FiInfo } from "react-icons/fi";
import VehicleHistoryModal from "./Vehiclehistory";

import "./Loader.css";
// import DaVehicleMappingInMatrix from "./davehicleMappingInMatrix";
function Vehicledetails() {
  const [vehicle, setVehicle] = useState([]);
  const [selectedImage, setSelectedImage] = useState({
    image: null,
    imageMeta: null,
    imageName: null,
   
  });
  const url = "/api/vehicle";
  const [reducerValue, forceUpdate] = useReducer((x) => x + 1, 0);
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(null);
  const [api, setapi] = useState({});
  const [isAddMemberModalOpen, setIsAddMemberModalOpen] = useState(false);
  const [isDaVehicleMapModalOpen, setIsDaVehicleMapModalOpen] = useState(false);
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const [selectedVehicleId, setSelectedVehicleId] = useState(null);
  const [isVehicleEditModalOpen, setIsVehicleEditModalOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deliveryAgents, setDeliveryAgents] = useState([]);
  const [searchTruckNumber, setSearchTruckNumber] = useState(""); 
  const [product, setProduct] = useState([]);
  const [searchtruck, setSearchtruck] = useState([]);
  const [searchDevice, setSearchDevice] = useState([]);
  const [searchDeviceNumber, setSearchDeviceNumber] = useState(""); 
  const [selectedvehicleHistoryId, setSelectedvehicleHistoryId] = useState(null);
  const [isvehicleHistoryModalOpen, setIsvehicleHistoryModalOpen] = useState(false);
  const [filter, setFilter] = useState("ACTIVE"); 

 
  function onClickhandler(e, id, disabled) {
    setLoading(id); // Show loader for the current vehicle
    const token = JSON.parse(localStorage.getItem("data")).token;
  
    axios
      .post(
        "/api/vehicle/update",
        {
          id: id,
          disabled: disabled,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((response) => {
        setLoading(null); // Hide loader
  
        // Success message based on the action
        const message = disabled
          ? "Vehicle deactivated successfully"
          : "Vehicle activated successfully";
        toast.success(message);
  
        // Refresh the vehicle list to reflect the updated state
        getData(); // Ensure getData() updates the vehicles list in state
      })
      .catch((error) => {
        setLoading(null); // Hide loader in case of an error
        handleError(error);
      });
  }
  


  function handleError(error) {
    if (error.response) {
      if (error.response.status === 401) {
        navigate(`/signin`);
        toast.error("Your session has expired. Please login.");
      } else if (error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      }  else if (error.response.data && error.response.data.code) {
        toast.error(error.response.data.code);
      }
       else {
        toast.error("An error occurred.");
      }
    } else if (error.message) {
      toast.error(error.message);
    } else {
      toast.error("An error occurred.");
    }
  }

  const  onClickhandlerdelete = async (e, id) => { 
    const token = JSON.parse(localStorage.getItem("data")).token;
    setLoading(id);
    const vehicles = await axios({
      url: "/api/DeliveryAgentVehicleMapping",
      method: "delete",
 
      headers: { Authorization: `Bearer ${token}` },
 
      data: {
        id: `${id}`,
        
        orgid: `${JSON.parse(localStorage.getItem("data")).orgcode}`,
      },
    }).then((res) => {
      toast.success(" Delivery Agent deleted successfully");
      getData();
      setLoading(null);
    });
  };
  function searchByTruckNumber(truckinfo) {
    console.log("Searching by truck number:", truckinfo);

    const token = JSON.parse(localStorage.getItem("data")).token;
    setSearchtruck(false);
    setLoader(true);
    axios
      .get(`/api/vehicle/truckinfo=${truckinfo}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        console.log("Search response:", res.data);

        setVehicle(res.data);
        setLoader(false);
      })
      .catch((error) => {
        console.error("Error searching by truck number:", error);
        setLoader(false);
      });
  }
  function searchByDeviceNumber(deviceid) {
    console.log("Searching by truck number:", deviceid);

    const token = JSON.parse(localStorage.getItem("data")).token;
    setSearchDevice(false);
    setLoader(true);
    axios
      .get(`/api/vehicle/deviceid=${deviceid}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        console.log("Search response:", res.data);

        setVehicle(res.data);
        setLoader(false);
      })
      .catch((error) => {
        console.error("Error searching by truck number:", error);
        setLoader(false);
      });
  }
 

 
  function onClickviewvehicle(e, vehicletruckinfo, latitude, longitude) {
    return <OpenGoogleMap latitude={22.3511148} longitude={78.6677428} />;
  }
  const getData = async () => {
    const token = JSON.parse(localStorage.getItem("data")).token;
 
    try {
      setLoader(true);
 
      // Fetch location data
      const locationData = await axios.get(`/api/Locationtrack/userid=${JSON.parse(localStorage.getItem("data")).userid}`, {
                                           
        headers: { Authorization: `Bearer ${token}` },
      });
     
      setapi(locationData.data);
      console.log(locationData.data);
 
      console.log(locationData.data);
      console.log(locationData.data.url);
 
      // Fetch vehicle data based on orgid
      const vehicles = await axios.get(
        `/api/vehicle/orgid=${JSON.parse(localStorage.getItem("data")).orgcode}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
 
      setLoader(false);
      setVehicle(vehicles.data);
       
    
    } catch (error) {
      if (error.response && error.response.status === 404) {
        toast.success(error.response.data.message);
      } else {
        toast.error(error.response.data.message);
      }
 
      setLoader(false);
    }
  };
  useEffect(() => {
    getData();
  }, [reducerValue]);
 
  const openAddMemberModal = (vehicleId, truckinfo) => {
    setSelectedVehicle({ vehicleId, truckinfo });
    setIsAddMemberModalOpen(true);
  };
 
  const closeAddMemberModal = () => {
    setSelectedVehicle(null);
    setIsAddMemberModalOpen(false);
    getData();
  };

  const openDaVehicleMapModal = () => {
    setIsDaVehicleMapModalOpen(true);
  };
 
  const closeDaVehicleMapModal = () => {
    setIsDaVehicleMapModalOpen(false);
    getData();
  };
 
  function onClickviewvehicle(e, vtruckinfo) {
    navigate(`/users/vehicledocuments/${vtruckinfo}`);
  }
  function onClickviewlocation(e, truckinfo, deviceid, image, imageMeta) {
    setSelectedImage({
      image: image,
      imageMeta: imageMeta,
    });
  
    const userid = JSON.parse(localStorage.getItem("data")).userid;
    console.log("imagevehicl:", image);
  console.log("imageMetavehicle:", imageMeta);
    navigate(`/users/OpenGoogleMap/${userid}/${truckinfo}/${deviceid}`, {
      state: {
        image: image,
        imageMeta: imageMeta,
      }
    });
  }

    const Vehicleedit = (id) => {
      setSelectedVehicleId(id);
console.log("Vehicleedit:", id);
    setIsVehicleEditModalOpen(true);
    
 
 
  }
  const closeVehicleEditModal = () => {
    setSelectedVehicleId(null);
 
    setIsVehicleEditModalOpen(false);
    getData();
 
  };
  const handleaddvehicle = () => {
    setIsModalOpen(true);
  };
 
  const handleCloseModal = () => {
    setIsModalOpen(false);
    getData();
  }
  const [itemsPerPage, setItemsPerPage] = useState(10); // Number of items to display per page
  const [currentPage, setCurrentPage] = useState(1);

  // Function to change the current page
  const changePage = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Function to change the number of items per page
  const changeItemsPerPage = (e) => {
    setItemsPerPage(parseInt(e.target.value));
    setCurrentPage(1); // Reset to the first page when changing items per page
  };

  // Calculate the index of the first and last items of the current page
  const lastIndex = currentPage * itemsPerPage;
  const firstIndex = lastIndex - itemsPerPage;

  // Get the current page's data using array slicing
  const Currentvehicle = vehicle.slice(firstIndex, lastIndex);

  // Calculate the total number of pages
  const totalPages = Math.ceil(vehicle.length / itemsPerPage);

  const handleFilterChange = (e) => {
    setFilter(e.target.value);
  };

  const filteredData = filter === "ALL" ? Currentvehicle : Currentvehicle.filter(v => v.disabled === (filter === "ACTIVE" ? false : true));

  const openvehicleHistoryModal = async (vehicleid) => {
    setSelectedvehicleHistoryId(vehicleid);
    setIsvehicleHistoryModalOpen(true);
  };
 
  const closevehicleHistoryModal = () => {
    setSelectedvehicleHistoryId(null);
    setIsvehicleHistoryModalOpen(false);
  };


 
  return (
    <div>
      <div>
        <AdminDashboard />
      </div>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
    
      <div className="col-12  mx-xl-auto ps-xl-10 ">
        <div className="card card-raised">
          <div className="card-body  ">
            
            <div>
  <div className="card-title">Vehicle Details</div>

  <div className="d-flex align-items-center mt-2">
    <input 
      type="text" 
      value={searchTruckNumber} 
      placeholder="search truckno..." 
      onChange={(e) => {
        setSearchTruckNumber(e.target.value);
        console.log("Search truck number value:", e.target.value);
      }} 
      list="truckinfo" 
      style={{ marginRight: '10px', height: "30px" }} 
    />
    <datalist id="truckinfo">
      {vehicle.map((item) => (
        <option key={item.id} value={item.truckinfo} />
      ))}
    </datalist>
    <button 
      onClick={() => searchByTruckNumber(searchTruckNumber)} 
      className="btn btn-primary ml-2 me-2" 
      style={{ border: "2px solid #6200ea", background: "#6200ea", borderRadius: "5px", width: "50px", height: "30px", marginRight: '10px' }}
    >
      <FaSearch style={{ background: "#6200ea", color: "white" }} />
    </button>

    <input 
      type="text" 
      value={searchDeviceNumber} 
      placeholder="search Device ..." 
      onChange={(e) => setSearchDeviceNumber(e.target.value)} 
      list="deviceid" 
      style={{ marginRight: '10px', height: "30px" }} 
    />
    <datalist id="deviceid">
      {vehicle.map((item) => (
        <option key={item.id} value={item.deviceid} />
      ))}
    </datalist>
    <button 
      onClick={() => searchByDeviceNumber(searchDeviceNumber)} 
      className="btn btn-primary ml-2 me-8" 
      style={{ border: "2px solid #6200ea", background: "#6200ea", borderRadius: "5px", width: "50px", height: "30px", marginRight: '10px' }}
    >
      <FaSearch style={{ background: "#6200ea", color: "white" }} />
    </button>
    
    {loader && <div className="loading ml-3"></div>}

              <label htmlFor="statusFilter" className="form-status me-2">
                Filter by Status:
              </label>
              <select value={filter} onChange={handleFilterChange} className="me-6">
        <option value="ALL">ALL</option>
        <option value="ACTIVE">ACTIVE</option>
        <option value="INACTIVE">INACTIVE</option>
      </select>
        
    
    <button 
      className="btn btn-primary ml-3 me-10" 
      onClick={() => window.open(api.url, '_blank')}
    >
      View all
    </button> 
    
    <button className="btn btn-primary ml-3" onClick={handleaddvehicle}>
      ADD
    </button>
  </div>
</div>

            <br />
            <table className="table table-bordered ">
              <thead>
                <tr
                  style={{
                    fontSize: "17px",
                    backgroundColor: " #6200ea",
                    color: "white",
                    textAlign: "center",
                  }}
                >
                  <th>Vehicle Number</th>
                  <th>Device</th>
                  {/* <th>Delivery Agent</th>
                  <th>Add Agent</th> */}

                  <th>Manage Vehicle</th>

                  <th>View Vehicle</th>
                  <th>Edit</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
              {filteredData.length === 0 ? (
            <tr>
              <td colSpan="7" className="text-center">
                No Data Available
              </td>
            </tr>
          ) : (
            filteredData.map((vehicle) => {
                  return (
                    <tr key={vehicle.vehicleid}>
                    <td style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
  
                    {vehicle.image && vehicle.imageMeta ? (
          <img
            className="img-fluid rounded-circle mb-1"
            src={`data:image/png;base64,${vehicle.image}`}
            alt="..."
            style={{ maxWidth: "50px", maxHeight: "70px", marginRight: '10px' }}
          />
        ) : (
          <div style={{ width: "50px", height: "70px", marginRight: '10px' }}></div>
        )}
        <span>{vehicle.truckinfo}</span>
      </div>
                    <span style={{ marginLeft: '40px' }}>
                        <FiInfo onClick={() => openvehicleHistoryModal(vehicle.id)} />
                      </span>
                      </td>
                    <td>{vehicle.deviceid}</td>

                    {/* <td>
                  

                    {Array.isArray(vehicle.deliveryAgent) && vehicle.deliveryAgent.length > 0 ? (
    <ul style={{ display: 'inline-block', margin: 0, padding: 0 }}>
    {vehicle.deliveryAgent.map((agent) => (
                            <li key={agent.id} style={{ display: 'inline-block', marginRight: '10px' }}>
                            {agent.name}
                            <button
                              key={agent.id}
                              style={{
                                border: "none",
                                background: "white",
                                color: "grey",
                                position: "relative",
                                left: "5px",
                                fontSize: "15px",
                              }}
                              onClick={(e) => {
                                onClickhandlerdelete(e, agent.id);     
                              }}
                            >
                              <MdDeleteForever />
                            </button>
                          </li>
                        ))}
                      </ul>
                    ) : (
                      <p style={{ color: "red" }}>No Delivery Agent Added</p>
                    )}
                  
                    <span style={{ marginLeft: '5px' }}></span>
                  </td> */}
                   
                   {/* <span style={{ marginRight: '25px' }}></span> */}
                   {/* <td>
                      <div   style={{left:"40px"}}>
                          <button
                            onClick={() =>
                              openAddMemberModal(vehicle.vehicleid, vehicle.truckinfo)
                            }
                          >
                          <IoMdAddCircleOutline />


                          </button>
                          </div  > */}
                          {/* <span style={{ marginRight: '15px' }}></span>


                          {Array.isArray(vehicle.deliveryAgent) && vehicle.deliveryAgent.map((agent) => (
                          <button
                          key={agent.id}

                          style={{
                            border: "none",
                            background: "white",
                            color: "grey",
                            position: "relative",
                            left: "20px",
                            fontSize: "30px",
                          }}
                          onClick={(e) => {
                            onClickhandlerdelete(e, agent.id);     
                          }}
                        >
                          <MdDeleteForever />
                        </button>
                        ))} */}
                        {/* </td> */}
                        {/* </div> */}
                      
                      <td>
                      {vehicle.deviceid ? (
                        <button
                        style={{ padding: '3px 7px', fontSize: '13px'  }}

                          className="btn btn-primary"
                          onClick={(e) =>
                            onClickviewvehicle(e, vehicle.truckinfo)
                          }
                        >
                          Manage Vehicle
                        </button>
                         ) : (
                          <p  style={{ color: "red" }}></p>
                           )}
                      </td>
                     
                     
                      <td>
                      {vehicle.deviceid ? (
                 <button
                 style={{ padding: '3px 7px', fontSize: '13px' }}

                 className="btn btn-primary"
               onClick={(e) => onClickviewlocation(e, vehicle.truckinfo, vehicle.deviceid,  vehicle.image,
                vehicle.imageMeta)}
                >
                View Vehicle
              </button>
                ) : (
               <p  style={{ color: "red" }}>Device not added</p>
                )}
                      </td>
                      <td>
                        <button
                          onClick={() => Vehicleedit(vehicle.id)}
                          style={{
                            border: "none",
                            background: "white",
                            size: "150px",
                          }}
                        >
                          <BiEditAlt />
                        </button>
                      </td>
                      <td>
    <div className="form-check form-switch">
      {loading === vehicle.id && (
        <div
          className="loader"
          style={{
            position: "relative",
            left: "40px",
            top: "8px",
          }}
        ></div>
      )}
      <input
        className="form-check-input"
        id="flexSwitchCheckDefault"
        type="checkbox"
        defaultChecked={!vehicle.disabled} // Reflect the correct state of "disabled"
        onClick={(e) => onClickhandler(e, vehicle.id, !vehicle.disabled)} // Toggle "disabled" state
        value={vehicle.id}
        style={{ cursor: "pointer" }}
        title={vehicle.disabled === false ? "Deactivate vehicle" : "Activate vehicle"}
      />
    </div>
  </td>
                    </tr>
                  );
                }))}
              </tbody>
            </table>
         

        <div className="pagination-container">
        {/* Previous arrow */}
        {currentPage > 1 && (
          <button className="btn btn-primary" onClick={() => changePage(currentPage - 1)}>
            Previous
          </button>
        )}

        {/* Current page */}
        <div className="pagination-current">
          &lt;&lt;{currentPage}&gt;&gt;
        </div>

        {/* Next arrow */}
        {currentPage < Math.ceil(vehicle.length / itemsPerPage) && (
          <button className="btn btn-primary" onClick={() => changePage(currentPage + 1)}>
            Next
          </button>
        )}
        <div style={{marginLeft:"5%"}}>
        <label>Rows per page</label>
        <select value={itemsPerPage} onChange={changeItemsPerPage}>
          <option value={10}>10</option>
          <option value={15}>15</option>
          <option value={20}>20 </option>
          <option value={25}>25 </option>
          <option value={30}>30 </option>
          {/* Add more options if needed */}
        </select>
        </div>
        </div>
        </div>
        </div>
      </div>
      
      {isvehicleHistoryModalOpen && (
              <VehicleHistoryModal
              isOpen={isvehicleHistoryModalOpen}
                onRequestClose={closevehicleHistoryModal}
                vehicleId={selectedvehicleHistoryId}
              />
            )}
      {isAddMemberModalOpen && selectedVehicle && (
        <Dagentandvehiclemapping
          isOpen={isAddMemberModalOpen}
          onRequestClose={closeAddMemberModal}
          params={{ vid: selectedVehicle.vehicleId, truckinfo: selectedVehicle.truckinfo }}
        />
      )}
{/* 
{isDaVehicleMapModalOpen && (
        <DaVehicleMappingInMatrix
          isOpen={isDaVehicleMapModalOpen}
          onRequestClose={closeDaVehicleMapModal}
        />
      )} */}

{isVehicleEditModalOpen && (
       <VehicleeditsModalPopup
        isOpen={isVehicleEditModalOpen}
        onRequestClose={closeVehicleEditModal}
        params={{ id: selectedVehicleId }} // Pass id as a parameter
        />
)}
         {isModalOpen && (
             <AddVehiclesmodalpopup isOpen={isModalOpen} onRequestClose={handleCloseModal} />
         )}



    </div>
  );
}
 
export default Vehicledetails;