import React, { useEffect, useState } from "react";
import axios from "../api/baseurl";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AiFillCheckSquare } from "react-icons/ai";
import { BiDownArrow, BiUpArrow } from "react-icons/bi";
import { Modal } from "react-bootstrap";

function OverHeadBio({ isOpen, onRequestClose, id }) {
  const [overHeadDetails, setOverHeadDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const getData = async () => {
    setLoading(true);
    try {
      const token = JSON.parse(localStorage.getItem("data")).token;
      const response = await axios.get(`/api/overhead/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setOverHeadDetails(response.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      handleError(error);
    }
  };

  const rsubmit = async (e, id, status) => {
    e.stopPropagation();
    try {
      const token = JSON.parse(localStorage.getItem("data")).token;
      const response = await axios.put(
        `/api/overhead/update/${id}`,
        { completed: status },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      toast.success(response.data.message || "Status updated successfully");
      getData();
    } catch (error) {
      handleError(error);
    }
  };

  const handleError = (error) => {
    if (error.response && error.response.status === 401) {
      navigate(`/signin`);
      toast.error("Your session has expired. Please login.");
    } else {
      const errorMessage =
        error.response?.data?.message || "An error occurred.";
      toast.error(errorMessage);
    }
  };

  useEffect(() => {
    if (id) getData();
  }, [id]);

  return (
    <Modal show={isOpen} onHide={onRequestClose} centered size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Overhead Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <p>Loading...</p>
        ) : overHeadDetails ? (
          <div
            className="card shadow-sm"
            style={{
              borderRadius: "10px",
              overflow: "hidden",
              backgroundColor: "#f9f9f9",
              border: "1px solid #ddd",
            }}
          >
            <div
              className="card-header"
              style={{
                backgroundColor: "#4a90e2",
                color: "white",
                textAlign: "center",
                padding: "10px 15px",
              }}
            >
              <h5>{overHeadDetails.title}</h5>
            </div>
            <div
              className="card-body"
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                gap: "15px",
                padding: "20px",
              }}
            >
              <div>
                <strong>Due Date:</strong> <br />
                {overHeadDetails.duedate}
              </div>
              <div>
                <strong>Consumer Number:</strong> <br />
                {overHeadDetails.consumerNumber}
              </div>
              <div>
                <strong>Consumer Name:</strong> <br />
                {overHeadDetails.consumerName}
              </div>
              <div>
                <strong>Phone Number:</strong> <br />
                {overHeadDetails.phoneNumber}
              </div>
              <div>
                <strong>Status:</strong> <br />
                {overHeadDetails.completed ? (
                  <span style={{ color: "green" }}>Completed</span>
                ) : (
                  <AiFillCheckSquare
                    style={{
                      color: "green",
                      fontSize: "20px",
                      cursor: "pointer",
                    }}
                    onClick={(e) => rsubmit(e, overHeadDetails.id, true)}
                  />
                )}
              </div>
              <div>
                <strong>Created By:</strong> <br />
                {overHeadDetails.userrole}
              </div>
              <div>
                <strong>Description:</strong> <br />
                {overHeadDetails.description}
              </div>
              <div>
                <strong>Product:</strong> <br />
                {overHeadDetails.productname}
              </div>
              <div>
                <strong>Qty/Amount:</strong> <br />
                {overHeadDetails.type === "Stock" ? (
                  <>
                    {overHeadDetails.takenQty && (
                      <>
                        <BiUpArrow style={{ color: "green" }} />{" "}
                        {overHeadDetails.takenQty}{" "}
                      </>
                    )}
                    {overHeadDetails.givenQty && (
                      <>
                        <BiDownArrow style={{ color: "red" }} />{" "}
                        {overHeadDetails.givenQty}
                      </>
                    )}
                  </>
                ) : overHeadDetails.type === "Finance" ? (
                  `${overHeadDetails.amount} ₹`
                ) : (
                  <>
                    {overHeadDetails.takenQty && (
                      <>
                        <BiUpArrow style={{ color: "green" }} />{" "}
                        {overHeadDetails.takenQty}{" "}
                      </>
                    )}
                    {overHeadDetails.givenQty && (
                      <>
                        <BiDownArrow style={{ color: "red" }} />{" "}
                        {overHeadDetails.givenQty}
                      </>
                    )}
                    {overHeadDetails.amount && ` ${overHeadDetails.amount} ₹`}
                  </>
                )}
              </div>
            </div>
          </div>
        ) : (
          <p>No Overhead Details Found</p>
        )}
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-secondary" onClick={onRequestClose}>
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default OverHeadBio;
