import React, { useEffect, useState, useReducer } from "react";
import axios from "../api/baseurl";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import "./approval.css";
import "./Spinner.css";
import "./Loader.css";
import AddExemptedcustomer from "./addexemptedcustomer";
import CustomerpaymentModal from "./AddCustomerpayment";
import { FaSearch } from "react-icons/fa";

function ExemptedPaymentHistory({ customernumber ,customername, setGrandAmountPaid}) {
  const [paymentDetails, setPaymentDetails] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [deliveryDetails, setDeliveryDetails] = useState([]);
  const [reducerValue, forceUpdate] = useReducer((x) => x + 1, 0);
  const [loading, setLoading] = useState(null);
  const [loader, setLoader] = useState(false);
  const [filterStatus, setFilterStatus] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [modeFilter, setModeFilter] = useState("");
  const [modeOptions, setModeOptions] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10); 

  const applyFilters = () => {
    let filteredDetails = [...deliveryDetails];

    if (searchQuery) {
      filteredDetails = filteredDetails.filter(
        (detail) =>
          detail.paymentreferencenumber?.toLowerCase().includes(searchQuery.toLowerCase()) ||
          detail.amountpaid.toString().includes(searchQuery)
      );
    }

    if (modeFilter) {
      filteredDetails = filteredDetails.filter((detail) => detail.modeofpaymentid === modeFilter);
    }

    if (startDate && endDate) {
      filteredDetails = filteredDetails.filter((detail) => {
        const date = new Date(detail.date);
        return date >= new Date(startDate) && date <= new Date(endDate);
      });
    }

    return filteredDetails;
  };

  const handleSearchChange = (e) => setSearchQuery(e.target.value);
  const handleModeFilterChange = (e) => setModeFilter(e.target.value);

  const handleFilterChange = (e) => {
    setFilterStatus(e.target.value);
    setCurrentPage(1);
  };
  const handleSearch = () => {
    if (!startDate || !endDate) {
      alert("Please select both Start Date and End Date.");
      return;
    }
    setCurrentPage(1); // Reset to first page
  };
  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < Math.ceil(applyFilters().length / itemsPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };

  const filteredDetails = applyFilters();
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentDetails = filteredDetails.slice(indexOfFirstItem, indexOfLastItem);

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(filteredDetails.length / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  const getData = async () => {
    if (!customernumber) return;
    const token = JSON.parse(localStorage.getItem("data")).token;
    const orgcode = JSON.parse(localStorage.getItem("data")).orgcode;

    setLoader(true);

    try {
      const res = await axios.get(
        `/api/customerpayment/orgcode=${orgcode}/customerid=${customernumber}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setDeliveryDetails(res.data);
      setPaymentDetails(res.data);
      const uniqueModes = [...new Set(res.data.map((detail) => detail.modeofpaymentid))];
      setModeOptions(uniqueModes);
    } catch (error) {
      handleError(error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    getData();
  }, [customernumber, reducerValue]);

  useEffect(() => {
    // Debug: Log the payment details array
    console.log("Payment Details:", paymentDetails);
    
    // Calculate the total paid amount
    const totalPaid = paymentDetails.reduce((sum, payment) => sum + (payment.amountpaid || 0), 0);
  
    // Debug: Log the calculated total
    console.log("Total Paid Calculation:", totalPaid);
  
    // Update the parent state with the grand total
    setGrandAmountPaid(totalPaid);
  }, [paymentDetails, setGrandAmountPaid]);

  const navigate = useNavigate();
  function handleError(error) {
    if (error.response && error.response.status === 401) {
      navigate(`/signin`);
      toast.error("Your session has expired. Please login.");
    } else {
      if (error.response && error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error("An error occurred.");
      }
    }
  }
  const handleAddCustomerPayment = () => {
    setIsModalOpen(true);
  };
 
  const handleCloseModal = () => {
    setIsModalOpen(false);
    getData();
  };
  return (
    <div className="delivery-history">
      <div className="card card-raised mb-5">
        <div className="card-body">
           <div className="card-title form-group d-flex align-items-center justify-content-between">Payment Details
         
           <button className="btn btn-primary float-end" onClick={handleAddCustomerPayment}>
               ADD
            </button>
            </div>
            <div className="mb-4 d-flex align-items-center">
                <input
                  type="text"
                  placeholder="Search Payment Reference or Amount"
                  value={searchQuery}
                  style={{ width: "300px" }}        
                            onChange={handleSearchChange}
                  className="form-control me-3"
                />
                <select
                  value={modeFilter}
                  onChange={handleModeFilterChange}
                  className="form-select"
                  style={{ width: "200px", marginRight: "8px", }}
                >
                  <option value="">All Modes</option>
                  {modeOptions.map((mode) => (
                    <option key={mode} value={mode}>
                      {mode}
                    </option>
                  ))}
                </select>

                <input
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              className="form-control me-2"
              style={{ width: "150px" }}
            />
            <input
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              className="form-control me-2"
              style={{ width: "150px" }}
            />
            <button
    className="btn"
    onClick={handleSearch}
    style={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "#6200ea",
      color: "white",
      border: "none",
      borderRadius: "4px",
      padding: "8px",
    }}
  >
    <FaSearch />
  </button>
              </div>
          {loader && (
            <div className="loader"></div>
          )}
          {!loader && (
            <>
           
              <table className="table table-bordered">
                <thead>
                  <tr style={{ fontSize: "17px", backgroundColor: "#6200ea", color: "white", textAlign: "center" }}>
                    {/* <th>Customer Name</th> */}
                    <th>Date</th>
                    <th>Mode Of Payment Id</th>

                    <th>Payment Reference Numberr</th>

                    <th>Description</th>
                    <th>Amount Paid </th>


                  </tr>
                </thead>
                <tbody>
                  {currentDetails.length === 0 ? (
                    <tr>
                      <td colSpan="3" className="text-center">
                        NO DATA AVAILABLE
                      </td>
                    </tr>
                  ) : (
                    currentDetails.map((detail, index) => (
                      <tr key={index}>
                        {/* <td>{detail.customerName}</td> */}
                        <td>{detail.date}</td>
                        <td>{detail.modeofpaymentid}</td>

                        <td>{detail.paymentreferencenumber}</td>

                        <td>{detail.description}</td>
                        <td>{detail.amountpaid}</td>

                      </tr>
                    ))
                  )}
                </tbody>
                <tfoot>
    <tr style={{ fontWeight: "bold", textAlign: "center" }}>
      <td colSpan="4">Grand Amount</td>
      <td>
        {currentDetails
          .reduce((sum, detail) => sum + (detail.amountpaid || 0), 0)
          .toFixed(2)}
      </td>
    </tr>
  </tfoot>
              </table>
              {/* Pagination controls */}
              <nav>
                <ul className="pagination justify-content-center">
                  <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
                    <button className="page-link" onClick={handlePrevPage}>
                      Previous
                    </button>
                  </li>
                  {pageNumbers.map((number) => (
                    <li
                      key={number}
                      className={`page-item ${number === currentPage ? "active" : ""}`}
                    >
                      <button className="page-link" onClick={() => setCurrentPage(number)}>
                        {number}
                      </button>
                    </li>
                  ))}
                  <li className={`page-item ${currentPage === pageNumbers.length ? "disabled" : ""}`}>
                    <button className="page-link" onClick={handleNextPage}>
                      Next
                    </button>
                  </li>
                </ul>
              </nav>
              <CustomerpaymentModal isOpen={isModalOpen} onRequestClose={handleCloseModal} customername={customername} customernumber={customernumber} />

            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default ExemptedPaymentHistory;
