import React, { useEffect, useState } from "react";
import { Modal, Button, Tabs, Tab, Spinner } from 'react-bootstrap';
import axios from "../api/baseurl";
import { MdDeleteForever } from "react-icons/md";
import { BiEditAlt } from "react-icons/bi";
import { toast } from "react-toastify";
import ContacteditModalPopup from "./Contacteditmodal";
import Addcontact from "./Addcontact";
import { useNavigate, useParams } from "react-router-dom";
import StockisteditModalPopup from "./Stockistedit";
import Addstockist from "./Addstockist";
import AdminDashboard from "./admindashboard";
import StockistcontactpersonViewModal from "./Stockistcontactperson";
import AddVendorProduct from "./Createvendorproduct";
import StockistproducteditModalPopup from "./stoclistprodycredit";
import Stockistmapping from "./Stockistmapping";
import { IoMdAddCircleOutline } from "react-icons/io";
import ViewStockistmapping from "./Viewstockist";
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Pagination, Form, Card, Row, Col } from "react-bootstrap";

function StockistpersonViewModal() {
  const { stockistId } = useParams();
  console.log("stockistId:", stockistId);
  const navigate = useNavigate();

  const [vendor, setVendor] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [contactPersons, setContactPersons] = useState([]);
  const [products, setProducts] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [productSearchTerm, setProductSearchTerm] = useState("");
  const [categorySearchTerm, setCategorySearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [activeTab, setActiveTab] = useState('contactPersons');

  const fetchVendorData = async () => {
    setLoading(true);
    try {
      const token = JSON.parse(localStorage.getItem("data")).token;

      // Fetch vendor, contact persons, and products concurrently
      const [vendorResponse, contactResponse, productsResponse] = await Promise.all([
        axios.get(`/api/stocklist/${stockistId}`, {
          headers: { Authorization: `Bearer ${token}` },
        }),
        axios.get(`/api/stockistcontactperson/stockistid=${stockistId}`, {
          headers: { Authorization: `Bearer ${token}` },
        }),
        axios.get(`/api/stockistmapping/stockistid=${stockistId}`, {
          headers: { Authorization: `Bearer ${token}` },
        }),
      ]);

      // Set data for vendor, contact persons, and products
      setVendor(vendorResponse.data);
      setContactPersons(contactResponse.data);
      setProducts(productsResponse.data.content);  
          setCurrentPage(productsResponse.data.number);
      setTotalPages(productsResponse.data.totalPages);
      
    } catch (err) {
      setError("Error fetching data");
      toast.error("Failed to fetch vendor, contact persons, or product data");
    } finally {
      setLoading(false);
    }
  };
  
  const filteredVendors = contactPersons.filter((person) =>
    person.name && person.name.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const filteredProducts = Array.isArray(products)
  ? products.filter((product) => {
      const productName = product.productEntity?.productname || '';
      const categoryName = product.productEntity?.productCategory?.categoryname || '';
      return (
        productName.toLowerCase().includes(productSearchTerm.toLowerCase()) &&
        categoryName.toLowerCase().includes(categorySearchTerm.toLowerCase())
      );
    })
  : [];
  useEffect(() => {
    if (stockistId) {
      fetchVendorData();
    }
  }, [stockistId]);

  const handleFirst = () => fetchVendorData(0);
  const handlePrevious = () => fetchVendorData(currentPage - 1);
  const handleNext = () => fetchVendorData(currentPage + 1);
  const handleLast = () => fetchVendorData(totalPages - 1);

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const maxPageNumbersToShow = 5;
    let startPage = Math.max(0, currentPage - 2);  
    let endPage = Math.min(totalPages - 1, currentPage + 2); 

    if (currentPage <= 2) {
      endPage = Math.min(totalPages - 1, maxPageNumbersToShow - 1);
    }

    if (currentPage >= totalPages - 3) {
      startPage = Math.max(0, totalPages - maxPageNumbersToShow);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <Pagination.Item
          key={i}
          active={i === currentPage}
          onClick={() => fetchVendorData(i)}
        >
          {i + 1}
        </Pagination.Item>
      );
    }
    return pageNumbers;
  };
  return (
    <div>
      <AdminDashboard />
      <br/>
        <br/>
        <br/>
        <br/>
      <div className="row col-12">
        <div className="col-12 ps-xl-10">
          <div className="card card-raised">
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-center">
                <div className="card-title mb-3">Stockist Details</div>
              </div>
              <div style={{ textAlign: "center" }}>
                            <div style={{ display: 'flex', justifyContent: 'space-between', textAlign: "center", alignItems: 'center' }}>
                            <div style={{ flex: '0 0 10%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>

                            {vendor.image && vendor.imageMeta ? (
        <img
          className="img-fluid rounded-circle mb-1"
          src={`data:image/png;base64,${vendor.image}`}
          alt="vendor"
          style={{ maxWidth: "50px", maxHeight: "70px", marginRight: '10px' }}
        />
      ) : (
        <div style={{ width: "50px", height: "70px", marginRight: '10px' }}></div>
      )}
      <span style={{ marginRight: '10px' }}></span>
     
    

  </div>
                            {/* Middle 40% (Name, Email, Address, Phone) */}
  <div style={{ flex: '0 0 40%', textAlign: 'left' }}>
    <div className="mb-2">Name: {vendor.name}</div>
    <div className="mb-2">Email: {vendor.email}</div>
    <div className="mb-2">Phone: {vendor.phone}</div>
    <div className="mb-2">Address: {vendor.address}</div>
  </div>

  {/* Right 30% (PAN, GST) */}
  <div style={{ flex: '0 0 50%', textAlign: 'left', position: 'relative', top: '-30px' }}>
  <div className="mb-2">PAN: {vendor.pan}</div>
    <div className="mb-2">GST: {vendor.gst}%</div>
  </div>
</div>
</div>
<br/>
<br/>

              <Tabs activeKey={activeTab} onSelect={setActiveTab}>
                {/* Contact Person Tab */}
                <Tab eventKey="contactPersons" title="Contact Persons">
                  <br/>
                <input
                  type="text"
                  className="form-control"
                  style={{
                    width: '200px',
                    display: 'inline-block',
                    paddingLeft: '10px',
                    marginLeft: '20px',
                  }}
                  placeholder="Search Contact Person"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
               
                {loading ? (
  <>
    <style>
      {`
        @keyframes spin {
          0% { transform: rotate(0deg); }
          100% { transform: rotate(360deg); }
        }
      `}
    </style>
    <div
      style={{
        border: '4px solid rgba(0, 0, 0, 0.1)',
        borderLeftColor: 'red',
        borderRadius: '50%',
        width: '40px',
        height: '40px',
        animation: 'spin 1s linear infinite'
      }}
    ></div>
   
  </>
  
) : error ? (
  <p className="text-danger">{error}</p>
                                    ) : (
                                     
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Email</th>
                          <th>Phone</th>
                          {/* <th>Actions</th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {filteredVendors.length > 0 ? (
                          filteredVendors.map((person) => (
                            <tr key={person.id}>
                              <td>{person.name}</td>
                              <td>{person.email}</td>
                              <td>{person.phone}</td>
                              {/* <td>
                                <button onClick={() => handleEditContact(person.id)}>
                                  <BiEditAlt />
                                </button>
                                <button onClick={() => handleDeleteContact(person.id)}>
                                  <MdDeleteForever />
                                </button>
                              </td> */}
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="4" className="text-center">No contact persons available</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  )}
                </Tab>

                {/* Products Tab */}
                <Tab eventKey="product" title="Products">
                  <br/>
                  <div style={{ display: 'flex', gap: '10px', marginBottom: '10px' }}>
  <input
    type="text"
    className="form-control"
    style={{ width: '200px' }}
    placeholder="Search Product"
    value={productSearchTerm}
    onChange={(e) => setProductSearchTerm(e.target.value)}
  />
  <input
    type="text"
    className="form-control"
    style={{ width: '200px' }}
    placeholder="Search Category Name"
    value={categorySearchTerm}
    onChange={(e) => setCategorySearchTerm(e.target.value)}
  />
</div>
                {loading ? (
  <>
    <style>
      {`
        @keyframes spin {
          0% { transform: rotate(0deg); }
          100% { transform: rotate(360deg); }
        }
      `}
    </style>
    <div
      style={{
        border: '4px solid rgba(0, 0, 0, 0.1)',
        borderLeftColor: 'red',
        borderRadius: '50%',
        width: '40px',
        height: '40px',
        animation: 'spin 1s linear infinite'
      }}
    ></div>
  </>
) : error ? (
  <p className="text-danger">{error}</p>
                                    ) : (
             
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Product Name</th>
                          <th>Vendor Name</th>

                          <th>HSN Code</th>
                          <th>GST</th>
                          {/* <th>Actions</th>
                          <th>Stockist Mapping</th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {filteredProducts.length > 0 ? (
                          filteredProducts.map((product) => (
                            <tr key={product.id}>


 <td>
  <div style={{ textAlign: "center" }}>
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {product.productEntity.image && product.productEntity.imageMeta ? (
        <img
          className="img-fluid rounded-circle mb-1"
          src={`data:image/png;base64,${product.productEntity.image}`}
          alt="Product"
          style={{ maxWidth: "50px", maxHeight: "70px", marginRight: '10px' }}
        />
      ) : (
        <div style={{ width: "50px", height: "70px", marginRight: '10px' }}></div>
      )}
     <div>
        <span style={{ display: "block", fontWeight: "bold" }}>
          {product.productEntity?.productname}
        </span>
        <span
          style={{
            display: "inline-block",
            backgroundColor: "#d3d3d3", // Grey color
            color: "#000", // Black text
            borderRadius: "10px",
            padding: "2px 8px",
            fontSize: "12px",
            marginTop: "5px"
          }}
        >
          {product.productEntity.productCategory.categoryname}
        </span>
      </div>
     
    </div>
  </div>
</td>              
<td
                                onClick={() => navigate(`/users/vendorview/${product.productEntity.vendorid}`)}
                                style={{
                                    color: 'black',
                                    textDecoration: 'none',
                                    cursor: 'pointer',
                                  }}
                                  onMouseEnter={(e) => (e.currentTarget.style.color = 'blue')}
                                  onMouseLeave={(e) => (e.currentTarget.style.color = 'black')}
                                >                              
                                {product.profileentity?.name}
                              </td>
                              <td>{product.productEntity.hsnCode}</td>
                              <td>{product.productEntity.gst}%</td>
                              {/* <td>
                                <button onClick={() => handleEditProduct(product.id)}>
                                  <BiEditAlt />
                                </button>
                                <button onClick={() => handleDeleteProduct(product.id)}>
                                  <MdDeleteForever />
                                </button>
                              </td> */}
                              {/* <td>
                                <Button onClick={() => handleViewStockistMapping(product.id)}>
                                  <VisibilityIcon />
                                </Button>
                              </td> */}
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="7" className="text-center">No products available</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                    
                  )}
                  <Pagination style={{ justifyContent: 'center', flexGrow: 1 }}>
              <Pagination.First onClick={handleFirst} disabled={currentPage === 0} />
              <Pagination.Prev onClick={handlePrevious} disabled={currentPage === 0} />
              {renderPageNumbers()}
              <Pagination.Next onClick={handleNext} disabled={currentPage === totalPages - 1} />
              <Pagination.Last onClick={handleLast} disabled={currentPage === totalPages - 1} />
            </Pagination>
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StockistpersonViewModal;
