import React, { useEffect, useState } from "react";
import AdminDashboard from "./admindashboard";
import axios from "../api/baseurl";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Loader.css";
import "./Spinner.css";
import ViewQuotation from "./ViewQuotation";

import {
  Card, CardContent, Grid, Typography, TextField, Button,
  Table, TableHead, TableBody, TableRow, TableCell,
  IconButton, Tooltip, InputAdornment,MenuItem, Select
} from '@mui/material';
import { BiEditAlt } from "react-icons/bi";
import { AiOutlineEye, AiOutlineShareAlt, AiOutlinePlus } from 'react-icons/ai';
import { MdFilterList } from "react-icons/md";
import { FiSearch } from "react-icons/fi";
import { useNavigate } from "react-router-dom";

function InvoiceList(){
  
    
 
      const navigate = useNavigate();
      const [invoice, setInvoice] = useState([]);
      const [loader, setLoader] = useState(false);
      const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
      const [searchTerm, setSearchTerm] = useState("");
      const [isEditModalOpen, setIsEditModalOpen] = useState(false);
      const [selectedPurchase, setSelectedPurchase] = useState(null);
      const [searchType, setSearchType] = useState("consumername");

    
      const getCurrentFiscalYear = () => {
        const today = new Date();
        const currentYear = today.getFullYear();
        const fiscalStartMonth = 3; 
    
        const fiscalYearStart = new Date(today.getFullYear(), fiscalStartMonth, 1);
        const fiscalYearEnd = new Date(today.getFullYear() + 1, fiscalStartMonth - 1, 31);
    
        return today >= fiscalYearStart ? { startYear: currentYear, endYear: currentYear + 1 }
          : { startYear: currentYear - 1, endYear: currentYear };
      };
    
      const { startYear, endYear } = getCurrentFiscalYear();
      const defaultStartDate = `${startYear}-04-01`; 
      const defaultEndDate = `${endYear}-03-31`; 
      const [dateRange, setDateRange] = useState({ startDate: defaultStartDate, endDate: defaultEndDate });
      const orgcodes = JSON.parse(localStorage.getItem("data")).orgcode;


      const getData = async () => {
        setLoader(true);
        try {
          const token = JSON.parse(localStorage.getItem("data")).token;
          const response = await axios.get(`/api/invoice/orgid=${orgcodes}`, {
            headers: { Authorization: `Bearer ${token}` },
          });
          setInvoice(response.data || []);
          setLoader(false);
        } catch (error) {
          setLoader(false);
          handleError(error);
        }
      };
    
      const handleError = (error) => {
        if (error.response && error.response.status === 404) {
          toast.error(error.response.data.message);
        } else {
          toast.error("An error occurred while fetching data");
        }
        setLoader(false);
      };
    
      useEffect(() => {
        getData();
      }, [isCreateModalOpen, isEditModalOpen]);
    
      const handleCreateModalOpen = () => {
        setIsCreateModalOpen(true);
      };
    
      const handleCreateModalClose = () => {
        setIsCreateModalOpen(false);
      };
    
     
    
      const handleEditInvoice = (id) => {
        navigate(`/users/EditInvoice/${id}`);
      };
    
    
      const handleViewInvoice = (id) => {
        navigate(`/users/ViewInvoice/${id}`);
      };
    
      // const filteredinvoice = invoice.filter((invoices) => {
      //   const quoteNumber = String(invoices.number || "");
      //   return quoteNumber.toLowerCase().includes(searchTerm.toLowerCase());
      // });
    
      const searchInvoice = async () => {
        setLoader(true);
        try {
          const token = JSON.parse(localStorage.getItem("data")).token;
          const params = { orgid: orgcodes };
    
          if (searchType === "consumername") params.consumername = searchTerm;
          if (searchType === "invoiceid") params.invoiceid = searchTerm;
    
          const response = await axios.get(`/api/quotes/search`, {
            headers: { Authorization: `Bearer ${token}` },
            params: params,
          });
    
          const data = Array.isArray(response.data) ? response.data : response.data.data || [];
          setInvoice(data);
        } catch (error) {
          handleError(error);
        } finally {
          setLoader(false);
        }
      };


      const filteredInvoice = invoice.filter((item) => {
        if (searchType === "consumername") {
          return item.consumername?.toLowerCase().startsWith(searchTerm.toLowerCase());
        }
        if (searchType === "quotationid") {
          return item.invoiceid?.toString().toLowerCase().includes(searchTerm.toLowerCase());
        }
        return true; // No filtering if the searchType doesn't match
      });
    
    
      return (
        <div>
          <AdminDashboard />
          <br />
          <br />
          <br />
          <br />
    
          {loader && <div className="loading"></div>}
          <div className="col-12 mx-xl-auto ps-xl-10">
            <div className="card card-raised">
    
              <Card>
                <CardContent>
                  <Grid container justifyContent="space-between" alignItems="center">
                    <Typography variant="h5" component="div">Invoice</Typography>
                    <Grid container item xs={12} justifyContent="flex-end" alignItems="center"> {/* Align everything to the right */}
                    <Grid item sx={{ marginRight: '6px' }}>
                    {/* <FormControl sx={{ minWidth: 100, marginRight: '4px' }}> */}
                    <Select
                      value={searchType}
                      onChange={(e) => setSearchType(e.target.value)}
                      displayEmpty
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            {/* <BsCalendar style={{ fontSize: '15px' }} /> */}
                          </InputAdornment>
                        ),
                        sx: { height: '30px', fontSize: '15px' },
                      }}
                      sx={{ width: '150px', height: '30px' }}
                    >
                      <MenuItem value=""> Select Search </MenuItem>
                      <MenuItem value="consumername">Consumer Name</MenuItem>
                      <MenuItem value="invoiceid">Invoice ID</MenuItem>
                    </Select>


                    <TextField
                      variant="outlined"
                      placeholder={`Search by ${searchType === "consumername" ? "Consumer Name" : "Invoice ID"}`}
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            {/* <BsCalendar style={{ fontSize: '15px' }} /> */}
                          </InputAdornment>
                        ),
                        sx: { height: '30px', fontSize: '15px' },
                      }}
                      sx={{ width: '150px', height: '30px' }}
                    />

                    <Button
                      variant="contained"
                      onClick={searchInvoice}
                      sx={{
                        padding: '5px',
                        backgroundColor: 'blue',
                        color: 'white',
                        borderRadius: '4px',
                        width: '80px',
                        height: '30px',
                        '&:hover': { backgroundColor: 'darkblue' }
                      }}                    >
                      Search
                    </Button>
                  </Grid>
    
                      <Grid item sx={{ marginRight: '8px' }}>
                        <TextField
                          variant="outlined"
                          type="date"
                          value={dateRange.startDate}
                          onChange={(e) => setDateRange({ ...dateRange, startDate: e.target.value })}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                {/* <BsCalendar style={{ fontSize: '15px' }} /> */}
                              </InputAdornment>
                            ),
                            sx: { height: '30px', fontSize: '15px' },
                          }}
                          sx={{ width: '150px', height: '30px' }}
                        />
                      </Grid>
    
                      <Grid item sx={{ marginRight: '8px' }}>
                        <TextField
                          variant="outlined"
                          type="date"
                          value={dateRange.endDate}
                          onChange={(e) => setDateRange({ ...dateRange, endDate: e.target.value })}
                          InputProps={{
                            sx: { height: '30px', fontSize: '15px' },
                          }}
                          sx={{ width: '150px', height: '30px' }}
                        />
                      </Grid>
    
                      {/* <Grid item container alignItems="center" spacing={1} sx={{ marginLeft: '8px' }}> Move icons closer to fields */}
                      <Tooltip title="Filter">
                        <IconButton sx={{ padding: '5px' }}>
                          <MdFilterList style={{ fontSize: '20px' }} /> {/* Smaller filter icon */}
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="View">
                        <IconButton sx={{ padding: '5px' }}>
                          <AiOutlineEye style={{ fontSize: '20px' }} /> {/* Smaller eye icon */}
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Share">
                        <IconButton sx={{ padding: '5px' }}>
                          <AiOutlineShareAlt style={{ fontSize: '20px' }} /> {/* Smaller share icon */}
                        </IconButton>
                      </Tooltip>
    
                      <Tooltip title={<span style={{ color: 'white' }}>New Invoice</span>} arrow>
                        <NavLink to="/users/AddInvoice" style={{ textDecoration: 'none' }}>
                          <IconButton
                            sx={{
                              padding: '5px',
                              backgroundColor: 'blue',
                              color: 'white',
                              borderRadius: '4px',
                              width: '100px',
                              height: '40px',
                              '&:hover': { backgroundColor: 'darkblue' }
                            }}
                          >
                            <AiOutlinePlus style={{ fontSize: '15px', marginRight: '5px' }} />
                            <span style={{ fontSize: '12px' }}>New Invoice</span>
                          </IconButton>
                        </NavLink>
                      </Tooltip>
    
    
    
    
                    </Grid>
    
                  </Grid>
                  {/* </Grid> */}
    
    
    
    
    
    
                  <br />
    
                  {/* Product Table */}
                  <Table>
                    <TableHead>
                      <TableRow>
                      <TableCell>Invoice Id</TableCell>
                        <TableCell>ConsumerName</TableCell>
                        <TableCell>Invoice Date</TableCell>
                        <TableCell>Due Date</TableCell>
                        <TableCell>Total Amount</TableCell>
                        <TableCell>Actions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {filteredInvoice.length === 0 ? (
                        <TableRow>
                          <TableCell colSpan={8} align="center">No Data Available</TableCell>
                        </TableRow>
                      ) : (
                        filteredInvoice.map((invoice) => (
                          <TableRow key={invoice.id}>
                            <TableCell>{invoice.invoiceid}</TableCell>
                            <TableCell>{invoice.consumername}</TableCell>
                            <TableCell>{invoice.invoicedate}</TableCell>
                            <TableCell>{invoice.duedate}</TableCell>
                            <TableCell>{invoice.totalAmount}</TableCell>
                            <TableCell>
                              <IconButton onClick={() => handleEditInvoice(invoice.id)}>
                                <BiEditAlt />
                              </IconButton>
                              
                              <Tooltip title="View">
                                <IconButton sx={{ padding: '5px' }} onClick={() => handleViewInvoice(invoice.id)}>
                                  <AiOutlineEye style={{ fontSize: '25px' }} />
                                </IconButton>
                              </Tooltip>
                            </TableCell>
                          </TableRow>
                        ))
                      )}
                    </TableBody>
                  </Table>
    
                 
                
    
                </CardContent>
              </Card>
            </div>
          </div>
        </div >
      );
  
    
}  export default  InvoiceList;