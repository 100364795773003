import React from 'react';
import { IconButton, Tooltip, Button } from "@mui/material";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';


function PrintableQuotation({ quotationDetails, orgprofile, consumerdata, productData, calculateSubtotal, calculateSubtotalAfterDiscount, resultValue, TotalTaxAmount }) {
    return (

        <div
            style={{
                width: "90%",
                margin: "20px auto",
                padding: "20px",
                fontFamily: "Arial, sans-serif",
                backgroundColor: "#fff",
            }}
        >
            {/* Organization Details Section */}
            <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "20px" }}>
                <div>
                    <p style={{ marginBottom: "5px", fontWeight: "bold", fontSize: "20px" }}>
                        {orgprofile?.name}
                    </p>
                    <p style={{ marginBottom: "5px" }}>
                        {orgprofile?.addressLine1}, {orgprofile?.addressLine2}
                    </p>
                    <p style={{ marginBottom: "5px" }}>{orgprofile?.pincode}</p>
                    <p style={{ marginBottom: "5px" }}>{orgprofile?.contact}</p>
                    <p style={{ marginBottom: "5px" }}>GSTIN: {orgprofile?.gstin}</p>
                </div>
                <div>
                    <img
                        className="img-fluid mb-1"
                        id="image"
                        src={`${orgprofile?.imageMeta},${orgprofile?.logo}`}
                        alt="Organization Logo"
                        style={{
                            width: "100px",
                            height: "100px",
                            borderRadius: "5px",
                            objectFit: "contain",
                            border: "1px solid #ccc",
                            padding: "5px",
                            backgroundColor: "#f9f9f9",
                        }}
                    />
                </div>
            </div>

            {/* Consumer Details Section */}
            <div style={{ marginBottom: "20px" }}>
                <h5>To:</h5>
                {consumerdata ? (
                    <div>
                        <p style={{ marginBottom: "5px" }}>{consumerdata.consumername}</p>
                        <p style={{ marginBottom: "5px" }}>
                            {consumerdata.address1}, {consumerdata.address2}
                        </p>
                        <p style={{ marginBottom: "5px" }}>{consumerdata.address3}</p>
                        <p style={{ marginBottom: "5px" }}>{consumerdata.phone}</p>
                    </div>
                ) : (
                    <p>Loading...</p>
                )}
            </div>

            {/* Quotation Details Section */}
            <div style={{ marginBottom: "20px", textAlign: "right" }}>
                <p>#No: {quotationDetails?.quotationid}</p>
                <p ><span style={{ fontWeight: 'bold' }}>Quote Date </span>: {quotationDetails?.quotesdate || ""}</p>
                <p> <span style={{ fontWeight: 'bold' }}>Due Date </span>: {quotationDetails?.duedate || ""}</p>
                <p> <span style={{ fontWeight: 'bold' }}>Ship By (expected) </span>: {quotationDetails?.shibydate || ""}</p>
            </div>

            {/* Product Table */}
            <table>
                <thead>
                    <th style={{ width: "10%" }}>Product</th>
                    <th style={{ width: "10%" }}>Product Code</th>
                    <th style={{ width: "10%" }}>Description</th>
                    <th style={{ width: "2%" }}>Quantity</th>
                    <th style={{ width: "4%" }}>Unit</th>
                    <th style={{ width: "5%" }}>Unit Price</th>
                    <th style={{ width: "5%" }}>Before tax</th>
                    <th style={{ width: "10%" }}>Tax</th>
                    <th style={{ width: "8%" }}>Amount</th>

                </thead>
                <tbody>
                    {productData.map((product, index) => (
                        <tr key={index}>
                            <td>{product.product}</td>
                            <td>{product.productcode}</td>
                            <td>{product.description}</td>
                            <td>{product.quantity}</td>
                            <td>{product.uom}</td>
                            <td>{(product.price).toFixed(2)}</td>
                            <td>{(product.beforetax).toFixed(2)}</td>
                            <td>
                                <div>
                                    Tax: {product.tax}% , ₹{(product.tax * product.beforetax) / 100}
                                </div>
                                <div>
                                    CGST: {product.tax / 2}% , ₹{(product.tax * product.beforetax) / 200}
                                </div>
                                <div>
                                    SGST: {product.tax / 2}% , ₹{(product.tax * product.beforetax) / 200}
                                </div>
                            </td>
                            <td>{(product.amount).toFixed(2)}</td>
                        </tr>
                    ))}
                </tbody>
            </table>

            <div >
                <textarea
                    className={`form-control`}
                    value={quotationDetails.memo || ''}
                    readOnly
                />

            </div>

            {/* Summary Section */}
            <div style={{ textAlign: "right" }}>
                <p>Subtotal: ₹{calculateSubtotal()}</p>
                <p>Discount: ₹{quotationDetails.discount}</p>
                <p>Before Tax: ₹{calculateSubtotalAfterDiscount()}</p>
                <p>Total Tax: ₹{TotalTaxAmount(productData)}

                    <h6>Tax Breakdown:</h6>  <p>CGST: ₹ {productData.reduce((sum, product) => sum + ((product.tax * product.beforetax / 100) / 2), 0).toFixed(2)}</p>
                    <p>SGST: ₹ {productData.reduce((sum, product) => sum + ((product.tax * product.beforetax / 100) / 2), 0).toFixed(2)}</p>

                </p>
                <div className="col-md-7 d-flex justify-content-end align-items-center">TCS: ₹ 0.00</div>
                <p>Rounding Off: ₹{resultValue.roundOff}</p>
                <p style={{ fontWeight: "bold", fontSize: "18px" }}>Total: ₹{resultValue.roundedTotal.toFixed(2)}</p>
            </div>
        </div>

    );
}

export default PrintableQuotation;
