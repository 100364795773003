import React, { useEffect, useState } from "react";
import {Tab, Tabs } from 'react-bootstrap';  
import axios from "../api/baseurl";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";
import AdminDashboard from "./admindashboard";
import "@material/mwc-tab-bar/mwc-tab-bar.js";
import "@material/mwc-tab/mwc-tab.js";
import "@material/mwc-textfield/mwc-textfield.js";
import DeliveryDetailsHistory from "./deliveryDetailsHistory";
import LoyaltyPoints from "./loyaltyPoints";
import RedeemedPoints from "./redeemedPoints";
import ExemptedDeliveryHistory from "./Exempteddeliveryhistory";
import ExemptedPaymentHistory from "./Exemptedpaymentdetails";
 
  function ExemptedBio(props) {

  const { Id } = useParams();


  const[loading ,setLoading]=useState(false);
  const [activeTab, setActiveTab] = useState('deliveryDetails');
  const [selectedConsumer, setSelectedConsumer] = useState(Id);
  const [password, setPassword] = useState('');
  const [consumerDetails, setConsumerDetails] = useState([])
  const [grandTotal, setGrandTotal] = useState(0);
  const [grandAmountPaid, setGrandAmountPaid] = useState(0);
  useEffect(() => {
    const fetchConsumerDetails= async () => {
      try {
        const token = JSON.parse(localStorage.getItem("data")).token;
        const response = await axios.get(`/api/exemptedcustomer/${Id}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setConsumerDetails(response.data);
      } catch (error) {
        console.error("Error fetching user profile:", error);
      }
    };

    fetchConsumerDetails();
  }, [Id]);

  

  return (
    <div>
      <AdminDashboard />
      <br/>
              <br/>
              <br/>
      <br />
<div className="row col-12">
      <div className="col-9 ps-xl-10">
  <div className="card card-raised">
    <div className="card-body">
      <div className="d-flex justify-content-between align-items-center">
        <div className="card-title mb-3">{consumerDetails.customername}</div>
        <div>
          <div className="d-inline me-8">Distance: {consumerDetails.distance}</div>
          <div className="d-inline">Tarrif: {consumerDetails.tariff}</div>
        </div>
      </div>
      <div className="mb-2">Customer Name: {consumerDetails.customername}</div>
      <div className="mb-2">Customer Category: {consumerDetails.customercategory}</div>
      <div className="mb-2">Customer Number: {consumerDetails.customerNumber}</div>
      <div className="mb-2">Delivery Date: {consumerDetails.deliverydate}</div>
      {/* <div className="mt-4 text-center">
        <div className="display-5">Loyalty Points: {consumerDetails.loyaltyPoints}</div>
      </div> */}
    </div>
  </div>
</div>
<div className="col-3">
          <div className="card card-raised">
            <div className="card-body">
            <div className="card-title mb-3">Financial Details</div>
            {/* <div className="mb-2">
                Delivery History Total: ₹{grandTotal.toFixed(2)}
              </div>
              <div className="mb-2">
                Payment History Total: ₹{grandAmountPaid.toFixed(2)}
              </div> */}
              <div
        className="mb-2"
        style={{
          color: grandAmountPaid > grandTotal ? 'green' : 'red', // Change color based on the condition
          fontWeight: 'bold', // Optional: Make the difference stand out more
        }}
      >
        Total Amount: ₹{Math.abs(grandAmountPaid - grandTotal).toFixed(2)}
      </div>
            </div>
          </div>
        </div>
      </div>
      <br />

<div className="col-12 mx-xl-auto ps-xl-10">
        <div className="card card-raised">
          <div className="card-body">
            <Tabs
              id="consumer-bio-tabs"
              activeKey={activeTab}
              onSelect={(tab) => setActiveTab(tab)}
              className="mb-3"
            >
              <Tab eventKey="deliveryDetails" title="Delivery History">
              {activeTab === 'deliveryDetails' && (
                <ExemptedDeliveryHistory customernumber={consumerDetails.customerNumber}  setGrandTotal={setGrandTotal}
                />
              )}
              </Tab>
              <Tab eventKey="Payment" title="Payment History">
              {activeTab === 'Payment' && (
                <ExemptedPaymentHistory customernumber={consumerDetails.customerNumber}  customername={consumerDetails.customername}  setGrandAmountPaid={setGrandAmountPaid}
                />
              )}
              </Tab>
             
            </Tabs>
          </div>
        </div>
      </div>
      <br/>
     
    </div>
  );
}
 
export default ExemptedBio;