import React, { useEffect, useState } from "react";
import AdminDashboard from "./admindashboard";
import axios from "../api/baseurl";
import { toast } from "react-toastify";
import "react-toastify/ReactToastify.css"; // Updated import
import "./Loader.css";
import Search from "./search";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Card, Button, Table } from "react-bootstrap";
import { useNavigate } from "react-router";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMedal } from '@fortawesome/free-solid-svg-icons';

function Leaderboard() {
  const [reward, setReward] = useState([]);
  const [loader, setLoader] = useState(false);
  const [searchbydate, setSearchbydate] = useState(true);

  const date = new Date();
  const currentDate = date.toISOString().split("T")[0];
  const currentMonth = date.getMonth(); 
  const currentYear = date.getFullYear(); 
  
  const [searchOption, setSearchOption] = useState("DATE");
  const [selectedYear, setSelectedYear] = useState(currentYear);
  const [selectedMonth, setSelectedMonth] = useState(currentMonth);
  const navigate = useNavigate();

  const getData = async () => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    const orgcode = JSON.parse(localStorage.getItem("data")).orgcode;

    setLoader(true);

    try {
      const url = `/api/rewardpoints/totalrewards/orgcode=${orgcode}/date=${currentDate}`;
      const response = await axios.get(url, {
        headers: { Authorization: `Bearer ${token}` },
      });

      setReward(response.data.user_details);
      setLoader(false);
    } catch (error) {
      handleError(error);
    }
  };

  const searchName = (text) => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    setSearchbydate(false);
    setLoader(true);

    axios
      .get(`/api/rewardpoints/totalrewards/orgcode=${JSON.parse(localStorage.getItem("data")).orgcode}/date=${text}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        setReward(res.data.user_details);
        setLoader(false);
      })
      .catch((error) => {
        setLoader(false);
        handleError(error);
      });
  };

  const searchByMonth = async (month, year) => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    setSearchbydate(false);
    setLoader(true);

    try {
      const searchResponse = await axios.get(`/api/rewardpoints/totalrewards/orgcode=${JSON.parse(localStorage.getItem("data")).orgcode}/month=${month}/year=${year}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      setReward(searchResponse.data.user_details);
      setLoader(false);
    } catch (error) {
      handleError(error);
    }
  };

  const searchByYear = async (year) => {
    const tokenData = JSON.parse(localStorage.getItem("data"));
    if (!tokenData || !tokenData.token) {
      console.error("Token not found in localStorage");
      return;
    }

    const token = tokenData.token;
    const orgCode = tokenData.orgcode;

    setSearchbydate(false);
    setLoader(true);

    try {
      const searchResponse = await axios.get(`/api/rewardpoints/totalrewards/orgcode=${orgCode}/year=${year}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      console.log("API response:", searchResponse.data);
      setReward(searchResponse.data.user_details || []);
    } catch (error) {
      console.error("API error:", error.response?.data || error.message);
      handleError(error);
    } finally {
      setLoader(false);
    }
  };

  const handleError = (error) => {
    if (error.response && error.response.status === 404) {
      toast.error(error.response.data.message);
    } else {
      toast.error("An error occurred while fetching data");
    }
    setLoader(false);
  };

  useEffect(() => {
    getData();
  }, []);

  const handleFilterChange = (event) => {
    const selectedOption = event.target.value;
    setSearchOption(selectedOption);
    setReward([]); // Reset reward data on filter change

    // Trigger the API based on the selected filter
    if (selectedOption === "MONTH") {
      const month = currentMonth + 1; // Adjust for 0-indexed month
      searchByMonth(month, currentYear); // Call the API with the current month and year
    } else if (selectedOption === "YEAR") {
      searchByYear(currentYear); // Call the API with the current year
    } else {
      getData(); // Call the API for the current date if DATE is selected
    }
  };

  const handleBack = () => {
    navigate("/users/Adminleaderboard");
  };

  // Calculate total points for each user and sort by points in descending order
  const sortedReward = reward.map(user => {
    const totalPoints = user.products.reduce((acc, product) => acc + product.total_points, 0);
    return { ...user, totalPoints };
  }).sort((a, b) => b.totalPoints - a.totalPoints); // Sort by total points descending

  return (
    <div>
    <AdminDashboard />
    <br />
    <br />
    <br />
    <br />
  
    {loader && <div className="loading"></div>}
    <div className="col-12 mx-xl-auto ps-xl-10">
      <Card>
        <Card.Body>
          <Card.Title>
            Organization Reward Points Details
            <Button style={{ marginLeft: "90%" }} variant="secondary" onClick={handleBack}>
              Back
            </Button>
          </Card.Title>
          <br />
          <div className="d-flex align-items-center">
            <div className="filter-dropdown">
              <label>FILTER :</label>
              <select value={searchOption} onChange={handleFilterChange}>
                <option value="DATE">Date</option>
                <option value="MONTH">Month</option>
                <option value="YEAR">Year</option>
              </select>
            </div>
            {searchOption === "DATE" && (
              <>
                <span style={{ marginLeft: "10px" }}></span>
                <label style={{ position: "relative", left: "40px" }}>Date:</label>
                <Search searchName={searchName} />
              </>
            )}
            {searchOption === "MONTH" && (
              <>
                <label>Month:</label>
                <DatePicker
                  selected={new Date(selectedYear || new Date().getFullYear(), selectedMonth || new Date().getMonth())}
                  onChange={(date) => {
                    setSelectedYear(date.getFullYear());
                    setSelectedMonth(date.getMonth());
                    searchByMonth(date.getMonth() + 1, date.getFullYear());
                  }}
                  showMonthYearPicker
                  dateFormat="MM/yyyy"
                />
              </>
            )}
            {searchOption === "YEAR" && (
              <>
                <span style={{ marginLeft: "10px" }}></span>
                <label>Year:</label>
                <DatePicker
                  selected={new Date(selectedYear || new Date().getFullYear(), 0)}
                  onChange={(date) => {
                    setSelectedYear(date.getFullYear());
                    searchByYear(date.getFullYear());
                  }}
                  showYearPicker
                  dateFormat="yyyy"
                />
              </>
            )}
          </div>
  
          <br />
          <br />
  
          {/* Render a separate table for each user */}
          {sortedReward.length === 0 ? (
            <div style={{ textAlign: "center" }}>
              No data available for the selected filter.
            </div>
          ) : (
            sortedReward.map((user, userIndex) => (
              <Card key={userIndex} className="mb-3">
                <Card.Body>
                  <Card.Title>
                    {userIndex === 0 && <FontAwesomeIcon icon={faMedal} style={{ color: "gold" }} />}
                    {userIndex === 1 && <FontAwesomeIcon icon={faMedal} style={{ color: "silver" }} />}
                    {userIndex === 2 && <FontAwesomeIcon icon={faMedal} style={{ color: "bronze" }} />}
                    {user.name || "Unknown User"} ({user.total_points} points)
                  </Card.Title>
                 {/* Add a container for horizontal scrolling */}
              <div style={{ overflowX: "auto", width: "100%" }}>
                <Table striped bordered hover size="sm" style={{ minWidth: "100%" }}>
                  <thead>
                    <tr>
                      {/* Display "Productname" header */}
                      {user.products.length > 0 && (
                        <th colSpan={user.products.length} style={{ textAlign: "center" }}>
                          Products
                        </th>
                      )}

                      {/* Display "Campaigns" header */}
                      {user.campaigns.length > 0 && (
                        <th colSpan={user.campaigns.length} style={{ textAlign: "center" }}>
                          Campaigns
                        </th>
                      )}

                      {/* Total Points column */}
                      <th style={{ textAlign: "center" }}>Total Points</th>
                    </tr>
                    <tr>
                      {/* Display product names */}
                      {user.products.map((prod, index) => (
                        <td key={index} style={{ textAlign: "center", padding: "5px" }}>
                          {prod.productname}
                        </td>
                      ))}

                      {/* Display campaign names */}
                      {user.campaigns.map((campaign, index) => (
                        <td key={index + user.products.length} style={{ textAlign: "center", padding: "5px" }}>
                          {campaign.campaignname}
                        </td>
                      ))}

                      {/* Display empty cell for total points */}
                      <td style={{ textAlign: "center" }}></td>
                    </tr>
                  </thead>

                  <tbody>
                    <tr>
                      {/* Display total points for each product */}
                      {user.products.map((prod, pIndex) => {
                        const productPoints = user.products.find((item) => item.productname === prod.productname);
                        return (
                          <td key={pIndex} style={{ textAlign: "center", padding: "5px" }}>
                            {productPoints ? productPoints.total_points : 0} {/* Display total points */}
                          </td>
                        );
                      })}

                      {/* Display total points for each campaign */}
                      {user.campaigns.map((campaign, cIndex) => {
                        const campaignPoints = user.campaigns.find((item) => item.campaignname === campaign.campaignname);
                        return (
                          <td key={cIndex + user.products.length} style={{ textAlign: "center", padding: "5px" }}>
                            {campaignPoints ? campaignPoints.total_points : 0} {/* Display total points */}
                          </td>
                        );
                      })}

                      {/* Display total points for the user */}
                      <td style={{ textAlign: "center", padding: "5px" }}>{user.total_points}</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
               </Card.Body>
              </Card>
            ))
          )}
        </Card.Body>
      </Card>
    </div>
  </div>
  
  );
}

export default Leaderboard;
