import React, { useEffect, useState } from "react";
import Dashboard from "./dashboard";
import axios from "../api/baseurl";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MdDeleteForever } from "react-icons/md";
import RegisterMd from "./registermandd";

import { Pagination, Form, Card, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { FaSearch } from "react-icons/fa";
import { FaRegUserCircle } from "react-icons/fa";
import { AiFillCheckSquare } from "react-icons/ai";
import { BiDownArrow, BiEditAlt, BiUpArrow } from "react-icons/bi";
import Overheadmodal from "./overheadmodal";
import Overheadeditmodal from "./OverheadEdit";
import OverHeadBio from "./Overheadbio";

function OverHeadDetails() {
  const [overHeadDetails, setOverHeadDetails] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isoverheadEditModalOpen, setIsoverheadEditModalOpen] = useState(false);
  const [isoverheadModalOpen, setIsoverheadModalOpen] = useState(false);
  const [selectedoverheadId, setSelectedoverheadId] = useState(null);
  const [selectedStatusFilter, setSelectedStatusFilter] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [highlightedRow, setHighlightedRow] = useState(null);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [isoverheadModalOpen1, setIsoverheadModalOpen1] = useState(false);
  const [selectedoverheadId1, setSelectedoverheadId1] = useState(null);
  const [selectedVehicleFilter, setSelectedVehicleFilter] = useState('');

  const navigate = useNavigate();

  const getData = async (pageNumber = 0) => {
    setLoading(true);
    try {
      const token = JSON.parse(localStorage.getItem("data")).token;
      const orgcode = JSON.parse(localStorage.getItem("data")).orgcode;
      const trans = await axios.get(`/api/overhead/product/orgcode=${orgcode}`, {
        headers: { Authorization: `Bearer ${token}` },
        params: { page: pageNumber }, 
      });

      setOverHeadDetails(trans.data.content); 
      setCurrentPage(trans.data.number);
      setTotalPages(trans.data.totalPages);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      handleError(error);
    }
  };

  const handleFilterChange = () => {
    setCurrentPage(1);
  };

  const handleAdd = () => {
    setIsoverheadModalOpen(true);
  };

  const handleoverheadCloseModal = () => {
    setIsoverheadModalOpen(false);
    getData();
  };

  const handleoverheadedit = async (id) => {
    setSelectedoverheadId(id);
    setIsoverheadEditModalOpen(true);
  };

  const closeoverheadEditModal = () => {
    setIsoverheadEditModalOpen(false);
    getData();
  };

  // New function to update status
  const rsubmit = async (e, id, status) => {
    e.stopPropagation(); // Prevent the event from bubbling up
    try {
      const token = JSON.parse(localStorage.getItem("data")).token;
      const response = await axios.put(`/api/overhead/update/${id}`, { completed: status }, {
        headers: { Authorization: `Bearer ${token}` },
      });
      toast.success(response.data.message || 'Status updated successfully');
      getData(); // Refresh data after updating status
    } catch (error) {
      handleError(error);
    }
  };

  useEffect(() => {
    getData(currentPage);
  }, [currentPage]); // Fetch data on page or items per page change

  function handleError(error) {
    if (error.response && error.response.status === 401) {
      navigate(`/signin`);
      toast.error("Your session has expired. Please login.");
    } else {
      if (error.response && error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error('An error occurred.');
      }
    }
  }

  const getCurrentItems = () => {
    const filteredData = overHeadDetails.filter(oh => {
      const statusFilterPass = selectedStatusFilter === '' ||
        (selectedStatusFilter === 'true' && oh.completed) ||
        (selectedStatusFilter === 'false' && !oh.completed);


        const vehicleFilterPass =
        selectedVehicleFilter === "" || oh.vehicleid === selectedVehicleFilter;

        return statusFilterPass && vehicleFilterPass; // Combine both filters
      });

    return filteredData;
  };
  const handleCardClick = async (id) => {

   
    setSelectedoverheadId1(id);

    setIsoverheadModalOpen1(true);
  };
  const closeRowClickModal = () => {
    setIsoverheadModalOpen1(false);
    getData();
  };
  
  const handlePageChange = (pageNumber) => {
    getData(pageNumber);
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const maxPageNumbersToShow = 5;
    let startPage = Math.max(0, currentPage - 2);
    let endPage = Math.min(totalPages - 1, currentPage + 2);

    if (currentPage <= 2) {
      endPage = Math.min(totalPages - 1, maxPageNumbersToShow - 1);
    }

    if (currentPage >= totalPages - 3) {
      startPage = Math.max(0, totalPages - maxPageNumbersToShow);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <Pagination.Item key={i} active={i === currentPage} onClick={() => handlePageChange(i)}>
          {i + 1}
        </Pagination.Item>
      );
    }
    return pageNumbers;
  };
    
  
  return (
    <div>
      <div>
        <Dashboard />
      </div>
      {loading && <div className="loading"></div>}
      <div>
        <div className="col-13 mx-xl-auto ps-xl-10">
          <div className="card card-raised mt-10">
            <div className="card-body">
              <div className="card-title">
                Over Head Details
                <button className="btn btn-primary float-end" onClick={handleAdd}>
                  ADD
                </button>
              </div>
              <br></br>
              <div className="filter-dropdowns">
              <div className="row align-items-center">
              <div className="col-4">
              <label htmlFor="statusFilter">FILTER BY STATUS:</label>
              <select
                        as="select"
                        value={selectedStatusFilter}
                        onChange={(e) => {
                          setSelectedStatusFilter(e.target.value);
                          handleFilterChange();
                        }}
                        className="form-select"

                      >
                        <option value="">ALL</option>
                        <option value="true">COMPLETED</option>
                        <option value="false">PENDING</option>
                      </select>
                    </div>

                    <div className="col-4">
                    <label htmlFor="vehicleFilter">FILTER BY VEHICLE:</label>
                    <select
                    value={selectedVehicleFilter}
                    onChange={(e) => setSelectedVehicleFilter(e.target.value)}
                    className="form-select"

                  >
                    <option value="">ALL</option>
                    {[...new Set(overHeadDetails.map((oh) => oh.vehicleid))].map((vehicle) => (
                      <option key={vehicle} value={vehicle}>
                        {vehicle}
                      </option>
                    ))}
                  </select>
                  </div>
  </div>
</div>
              <br />
              <Row>
                {overHeadDetails.length === 0 ? (
                  <div className="text-center">No Data Available</div>
                ) : (
                  getCurrentItems().map((product) => (
                    <Col md={4} key={product.id} className="mb-3">
                      <div className="card"
                       style={{
                        backgroundColor: product.completed ? "#d4edda" : "#f8d7da", // Green for completed, red for pending
                        borderColor: product.completed ? "#c3e6cb" : "#f5c6cb", // Match border colors
                        color: product.completed ? "#155724" : "#721c24", // Text colors for better readability
                      }}
                      onClick={() => handleCardClick(product.id)}

                      >
                        <div className="card-body">
                          <div className="form-group d-flex align-items-center justify-content-between">
                          {product.overheadType === "office" ? (
    <div>Office</div>
  ) : (
    <div>{product.vehicleid ? product.vehicleid : ""}</div>
  )}
                            <div>{product.duedate}</div>
                          </div>
                          <hr />
                          <div style={{ fontWeight: "bold" }}>{product.title}</div>
                         
                         
                          <div className="form-group d-flex align-items-center justify-content-between mt-2">
                            {product.completed ? (
                              <div style={{ color: "green", fontSize: "16px" }}>Completed</div>
                            ) : (
                              <div id="icon" value={product.completed}>
                                <div>
                                  <AiFillCheckSquare
                                    style={{ color: "green", fontSize: "25px", cursor: "pointer" }}
                                    title="update status"
                                    onClick={(e) => rsubmit(e, product.id, true)} // Call rsubmit to update status
                                  />
                                </div>
                              </div>
                            )}
                            <button
                              onClick={() => handleoverheadedit(product.id)}
                              style={{
                                border: "none",
                                background: "white",
                                marginLeft: "10px",
                                fontSize: "20px",
                                cursor: "pointer",
                              }}
                              title="Edit"
                            >
                              <BiEditAlt />
                            </button>
                          </div>
                          <hr />
                          <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div style={{ cursor: "pointer" }} title="Created By">
                              {product.userrole}
                            </div>
                            <div style={{ cursor: "pointer" }} title="Product">
                              {product.productname}
                            </div>
                            <div>
                              {product.type === "Stock" ? (
                                <>
                                  {product.takenQty && (
                                    <>
                                      <BiUpArrow style={{ color: 'green', fontSize: "20px", cursor: "pointer" }} title="Taken Quantity" /> {product.takenQty}
                                    </>
                                  )}
                                  {product.givenQty && (
                                    <>
                                      <BiDownArrow style={{ color: 'red', fontSize: "20px", cursor: "pointer" }} title="Given Quantity" /> {product.givenQty}
                                    </>
                                  )}
                                </>
                              ) : product.type === "Finance" ? (
                                <div style={{ cursor: "pointer" }} title="Pending Amount">
                                  {product.amount} ₹
                                </div>
                              ) : 
                              <>
                              {product.takenQty && (
                                <>
                                  <BiUpArrow style={{ color: 'green', fontSize: "20px", cursor: "pointer" }} title="Taken Quantity" /> {product.takenQty}
                                </>
                              )}
                              {product.givenQty && (
                                <>
                                  <BiDownArrow style={{ color: 'red', fontSize: "20px", cursor: "pointer" }} title="Given Quantity" /> {product.givenQty}
                                </>
                              )}
                        {product.amount && (
                        <div style={{ cursor: "pointer" }} title="Pending Amount">
                                  {product.amount} ₹
                                </div>
                                )}
                              </>
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  ))
                )}
              </Row>
              <div className="d-flex justify-content-center">
                <Pagination>
                  <Pagination.First onClick={() => handlePageChange(0)} disabled={currentPage === 0} />
                  <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 0} />
                  {renderPageNumbers()}
                  <Pagination.Next onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages - 1} />
                  <Pagination.Last onClick={() => handlePageChange(totalPages - 1)} disabled={currentPage === totalPages - 1} />
                </Pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Overhead modal for adding new overhead */}
      {isoverheadModalOpen && (
      <Overheadmodal isOpen={isoverheadModalOpen} onRequestClose={handleoverheadCloseModal} />
      )}
      {/* Overhead edit modal */}
      {isoverheadEditModalOpen && (
      <Overheadeditmodal isOpen={isoverheadEditModalOpen} onRequestClose={closeoverheadEditModal} id={selectedoverheadId} />
      )}
       {isoverheadModalOpen1 && (
      <OverHeadBio isOpen={isoverheadModalOpen1} onRequestClose={closeRowClickModal} id={selectedoverheadId1} />
      )}
    </div>
  );
}

export default OverHeadDetails;
