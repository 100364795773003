import React, { useEffect, useState, useReducer } from "react";
import axios from "../api/baseurl";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import "./approval.css";
import "./Spinner.css";
import "./Loader.css";
import { FaSearch } from "react-icons/fa";

function ExemptedDeliveryHistory({ customernumber  , setGrandTotal}) {
  const [deliveryDetails, setDeliveryDetails] = useState([]);
  const [reducerValue, forceUpdate] = useReducer((x) => x + 1, 0);
  const [loading, setLoading] = useState(null);
  const [loader, setLoader] = useState(false);
  const [filterStatus, setFilterStatus] = useState("");
  const [filterVehicle, setFilterVehicle] = useState("");
  const [filterProduct, setFilterProduct] = useState("");
  const [filterDate, setFilterDate] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10); // Number of items to show per page
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  let grandTotal = 0;
  const applyFilters = () => {
    let filteredDetails = [...deliveryDetails];
    if (filterStatus) {
      filteredDetails = filteredDetails.filter(
        (detail) => detail.status === filterStatus
      );
    }

    // Filter by vehicle
    if (filterVehicle) {
      filteredDetails = filteredDetails.filter(
        (detail) => detail.deliveredVehicleid === filterVehicle
      );
    }

    // Filter by product
    if (filterProduct) {
      filteredDetails = filteredDetails.filter(
        (detail) => detail.productname === filterProduct
      );
    }

    if (startDate || endDate) {
      filteredDetails = filteredDetails.filter((detail) => {
        const date = new Date(detail.date);
        if (startDate && !endDate) return date >= new Date(startDate);
        if (!startDate && endDate) return date <= new Date(endDate);
        return date >= new Date(startDate) && date <= new Date(endDate);
      });
    }

    return filteredDetails;
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    if (name === "status") setFilterStatus(value);
    if (name === "vehicle") setFilterVehicle(value);
    if (name === "product") setFilterProduct(value);
    if (name === "date") setFilterDate(value);
    setCurrentPage(1);
  };
  const handleSearch = () => {
    if (!startDate || !endDate) {
      alert("Please select both Start Date and End Date.");
      return;
    }
    setCurrentPage(1); // Reset to first page
  };
  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < Math.ceil(applyFilters().length / itemsPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };

  const filteredDetails = applyFilters();
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentDetails = filteredDetails.slice(indexOfFirstItem, indexOfLastItem);

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(filteredDetails.length / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  const getData = async () => {
    if (!customernumber) return;
    const token = JSON.parse(localStorage.getItem("data")).token;
    const orgcode = JSON.parse(localStorage.getItem("data")).orgcode;

    setLoader(true);

    try {
      const res = await axios.get(
        `/api/ExemptedDetails/orgcode=${orgcode}/customerid=${customernumber}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setDeliveryDetails(res.data);
    } catch (error) {
      handleError(error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    getData();
  }, [customernumber, reducerValue]);

  const navigate = useNavigate();
  function handleError(error) {
    if (error.response && error.response.status === 401) {
      navigate(`/signin`);
      toast.error("Your session has expired. Please login.");
    } else {
      if (error.response && error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error("An error occurred.");
      }
    }
  }
  useEffect(() => {
    const total = deliveryDetails.reduce((sum, detail) => {
      return (
        sum +
        detail.execustomer.reduce((custSum, customer) => {
          const latestPrice =
            detail.priceentity?.sort((a, b) => new Date(b.date) - new Date(a.date))[0]?.price || 0;
          const deliveredQty = detail.deliveredqty || 0;
          const productPrice = latestPrice * deliveredQty;
          const tariff = deliveredQty * customer.tariff;
          return custSum + productPrice + tariff;
        }, 0)
      );
    }, 0);
    setGrandTotal(total);
  }, [deliveryDetails, setGrandTotal]);
  const uniqueVehicles = [
    ...new Set(deliveryDetails.map((detail) => detail.deliveredVehicleid)),
  ];
  const uniqueProducts = [
    ...new Set(deliveryDetails.map((detail) => detail.productname)),
  ];
  return (
    <div className="delivery-history">
      <div className="card card-raised mb-5">
        <div className="card-body">
          {loader && (
            <div className="loader"></div>
          )}
          {!loader && (
            <>
                 <div className="mb-4 d-flex align-items-center">
                <label htmlFor="vehicle" className="me-2">FILTER BY VEHICLE:</label>
                <select
                  id="vehicle"
                  name="vehicle"
                  value={filterVehicle}
                  onChange={handleFilterChange}
                >
                  <option value="">ALL</option>
                  {uniqueVehicles.map((vehicle, index) => (
                    <option key={index} value={vehicle}>
                      {vehicle}
                    </option>
                  ))}
                </select>

                <label htmlFor="product" className="ms-3 me-2">FILTER BY PRODUCT:</label>
                <select
                  id="product"
                  name="product"
                  value={filterProduct}
                  onChange={handleFilterChange}
                  style={{marginRight:'10px'}}
                >
                  <option value="">ALL</option>
                  {uniqueProducts.map((product, index) => (
                    <option key={index} value={product}>
                      {product}
                    </option>
                  ))}
                </select>

                <input
                  type="date"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                  className="form-control me-2"
                  style={{ width: "150px" }}
                />
                <input
                  type="date"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                  className="form-control me-2"
                  style={{ width: "150px" }}
                />
                <button
                  className="btn"
                  onClick={handleSearch}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "#6200ea",
                    color: "white",
                    border: "none",
                    borderRadius: "4px",
                    padding: "8px",
                  }}
                >
                  <FaSearch />
                </button>
              </div>
              <table className="table table-bordered">
                <thead>
                  <tr style={{ fontSize: "17px", backgroundColor: "#6200ea", color: "white", textAlign: "center" }}>
                    <th>Date</th>
                    <th>Vehicle</th>

                    <th>Product</th>
                    <th>Unit Price</th>
    <th>Quantity</th>
    <th>Product Price</th>
    <th>Tariff</th>
    <th>Total</th>

                  </tr>
                </thead>
                <tbody>
                  {currentDetails.length === 0 ? (
                    <tr>
                      <td colSpan="3" className="text-center">
                        NO DATA AVAILABLE
                      </td>
                    </tr>
                  ) : (
                    currentDetails.map((detail, index) => {
                      const latestPrice = detail.priceentity
                      ? [...detail.priceentity]
                          .sort((a, b) => new Date(b.date) - new Date(a.date))[0]?.price
                      : 0;
                      const deliveredQty = detail.deliveredqty || 0;
                      const productPrice = latestPrice * deliveredQty;
                      return detail.execustomer?.map((customer, custIndex) => {
                        const tariff = deliveredQty * customer.tariff;
                        const total = productPrice + tariff;
                        grandTotal += total;

                    return (
      <tr key={`${index}-${custIndex}`}>
        <td>{customer.deliverydate }</td>
        <td>{detail.deliveredVehicleid}</td>

                        <td>{detail.productname}</td>
                        <td>{latestPrice}</td>
          <td>{detail.deliveredqty}</td>
          <td>{productPrice}</td>
          <td>{tariff.toFixed(2)}</td>
        <td>{total.toFixed(2)}</td>
                      </tr>
                    );
                  });
                })
              )}
                </tbody>
                <tfoot>
    <tr style={{ fontWeight: "bold", textAlign: "center" }}>
      <td colSpan="7">Grand Total</td>
      <td>{grandTotal.toFixed(2)}</td>
    </tr>
  </tfoot>
              </table>
              {/* Pagination controls */}
              <nav>
                <ul className="pagination justify-content-center">
                  <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
                    <button className="page-link" onClick={handlePrevPage}>
                      Previous
                    </button>
                  </li>
                  {pageNumbers.map((number) => (
                    <li
                      key={number}
                      className={`page-item ${number === currentPage ? "active" : ""}`}
                    >
                      <button className="page-link" onClick={() => setCurrentPage(number)}>
                        {number}
                      </button>
                    </li>
                  ))}
                  <li className={`page-item ${currentPage === pageNumbers.length ? "disabled" : ""}`}>
                    <button className="page-link" onClick={handleNextPage}>
                      Next
                    </button>
                  </li>
                </ul>
              </nav>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default ExemptedDeliveryHistory;
