import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "../api/baseurl";
import { Modal, Button } from "react-bootstrap";
import "./Spinner.css";
import { useNavigate } from "react-router";

const AddCampaigns = ({ isOpen, onRequestClose, campaignDetails }) => {
  const date = new Date();
  date.setDate(date.getDate());
  let day = date.getDate().toString().padStart(2, "0");
  let month = (date.getMonth() + 1).toString().padStart(2, "0");
  let year = date.getFullYear();
  let currentDate = `${year}-${month}-${day}`;

  const [campaign, setCampaign] = useState({
    campaignName: "",
    orgcode: JSON.parse(localStorage.getItem("data")).orgcode,
    points: "",
    startDate: "",
    endDate: "",
    description: "",
    product: "",
    associateprice:false,
    id: campaignDetails ? campaignDetails.id : null,
  });

  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState([]);

  useEffect(() => {
    if (campaignDetails) {
      setCampaign({
        campaignName: campaignDetails.campaignName,
        points: campaignDetails.points,
        startDate: campaignDetails.startDate,
        endDate: campaignDetails.endDate,
        description: campaignDetails.description,
      
        id: campaignDetails.id,
        associateprice: campaignDetails.associateprice,  

        product: campaignDetails.product,
      });
    }
  }, [campaignDetails]);
  useEffect(() => {
    const fetchProducts = async () => {
      const token = JSON.parse(localStorage.getItem("data")).token;
      setLoading(true);

      try {
        const productResponse = await axios.get(
          `/api/product/orgcode=${JSON.parse(localStorage.getItem("data")).orgcode}/productstatus=true`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        setProducts(productResponse.data);
        setLoading(false);

      } catch (error) {
        setLoading(false);

        console.error("Error fetching products:", error);
        toast.error("Failed to fetch products.");
      }
    };

    fetchProducts();
  }, []);
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const token = JSON.parse(localStorage.getItem("data")).token;
  
    try {
      // Define endpoint based on presence of campaign id
      const endpoint = campaign.id ? "/api/campaign/update" : "/api/campaign/create";
      
      // Log the endpoint and campaign data for debugging
      console.log("Submitting to endpoint:", endpoint);
      console.log("Campaign data:", campaign);
  
      // Make API request
      const response = await axios.post(endpoint, campaign, {
        headers: { Authorization: `Bearer ${token}` },
      });
  
      // Log the response for debugging
      console.log("API response:", response);
  
      setLoading(false);
      toast.success(`Campaign ${campaign.id ? "updated" : "added"} successfully`);
      onRequestClose();
  
      // Reset campaign state
      setCampaign({
        campaignName: "",
        orgcode: JSON.parse(localStorage.getItem("data")).orgcode,
        points: "",
        startDate: "",
        endDate: "",
        description: "",
        product: "",
        associateprice:false,
        id: null,  // Ensure id is reset to null after submission
      });
    } catch (error) {
      setLoading(false);
      handleError(error);
    }
  };
  
  const navigate = useNavigate();
  function handleError(error) {
    if (error.response) {
      if (error.response.status === 401) {
        navigate(`/signin`);
        toast.error("Your session has expired. Please login.");
      } else if (error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      }  else if (error.response.data && error.response.data.code) {
        toast.error(error.response.data.code);
      }
       else {
        toast.error("An error occurred.");
      }
    } else if (error.message) {
      toast.error(error.message);
    } else {
      toast.error("An error occurred.");
    }
  }


  // const handleCheckboxChange = async () => {
  //   const token = JSON.parse(localStorage.getItem("data")).token;
  //  // Log the current campaign id for debugging

  //  if (!campaign.id) {
  //    toast.error("Campaign ID is missing. Cannot update linkproduct.");
  //    return;
  //  }
  //   try {
  //     // Update the linkproduct value in the backend
  //     const updatedLinkProduct = !campaign.linkproduct;

  //     await axios.post(
  //       "/api/campaign/update",
  //       { id: campaign.id, linkproduct: updatedLinkProduct },
  //       { headers: { Authorization: `Bearer ${token}` } }
  //     );
  
  //     setCampaign((prev) => ({ ...prev, linkproduct: updatedLinkProduct }));
  
  //     // Only fetch products if linkproduct is set to true
  //     if (updatedLinkProduct) {
  //       const productResponse = await axios.get(
  //         `/api/product/orgcode=${JSON.parse(localStorage.getItem("data")).orgcode}/productstatus=true`,
  //         { headers: { Authorization: `Bearer ${token}` } }
  //       );
  //       setProducts(productResponse.data);
  //     } else {
  //       setProducts([]); 
  //     }
  
  //     toast.success("Link Product updated successfully");
  //   } catch (error) {
  //     handleError(error);
  //   }
  // };
  const rhandle = (e, field) => {
    const actualValue = e.target.value;
    setCampaign({
      ...campaign,
      [field]: actualValue,
    });
  };
  const handleAssociatePrice = async () => {
    // Show warning and confirm action
    const confirmProceed = window.confirm(
      "Ensure the product price is not already included in the calculation to avoid multiple calculations. Do you wish to proceed?"
    );

    if (confirmProceed) {
          setLoading(true);

      try {
        const token = JSON.parse(localStorage.getItem("data")).token;

        // Trigger the API to update associateprice
        const response = await axios.post(
          "/api/campaign/update",
          { id: campaign.id, associateprice: true },
          { headers: { Authorization: `Bearer ${token}` } }
        );

        // Log response for debugging
        console.log("API response:", response.data);

        // Update state to reflect the change
        setCampaign({ ...campaign, associateprice: true });
        setLoading(false);

        toast.success("Associate price Updated successfully");
      } catch (error) {
        
        setLoading(false);

        console.error("Error updating associate price:", error);
        handleError(error);
      }
    } else {
      // Reset checkbox state to false if user declines
      setCampaign({ ...campaign, associateprice: false });
    }
  };
  return (
    <>
      <Modal show={isOpen} onHide={onRequestClose} centered>
      {loading && <div className="loading"></div>}
        <Modal.Header closeButton>
          <Modal.Title>{campaign.id ? "Update Campaign" : "Add Campaign"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="col-xl-10 mx-xl-auto">
            <div className="card card-raised mb-3">
              <div className="card-body p-4">
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="form-floating-sm mb-2">
                      <label htmlFor="campaignname">
                        Campaign Name<span style={{ color: "red" }}>* </span>
                      </label>
                      <input
                        className="form-control"
                        id="campaignName"
                        type="text"
                        placeholder="campaign Name"
                        value={campaign.campaignName}
                        onChange={(e) => rhandle(e, "campaignName")}
                        required
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-floating-sm mb-2">
                      <label htmlFor="startDate">
                        Start Date <span style={{ color: "red" }}>* </span>
                      </label>
                      <input
                        className="form-control"
                        id="startDate"
                        type="date"
                        placeholder="Start Date"
                        value={campaign.startDate}
                        onChange={(e) => rhandle(e, "startDate")}
                        required
                      />
                    </div>
                  </div>
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="endDate">End Date</label>
                    <input
                      className="form-control"
                      id="endDate"
                      type="date"
                      placeholder="End Date"
                      value={campaign.endDate}
                      onChange={(e) => rhandle(e, "endDate")}
                    />
                  </div>
                  <div className="row">
                    <div className="form-floating-sm mb-2">
                      <label htmlFor="points">
                        Points<span style={{ color: "red" }}>* </span>
                      </label>
                      <input
                        className="form-control"
                        id="points"
                        type="text"
                        placeholder="0"
                        value={campaign.points}
                        onChange={(e) => rhandle(e, "points")}
                        required
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-floating-sm mb-2">
                      <label htmlFor="description">Description</label>
                      <textarea
                        className="form-control"
                        id="description"
                        placeholder="Description"
                        value={campaign.description}
                        onChange={(e) => rhandle(e, "description")}
                        rows="2"
                      />
                    </div>
                  </div>
                  {campaign.id && (
                    <>
                    <div className="row">
                      <div className="form-floating-sm mb-2">
                        <label htmlFor="product">Link Product</label>
                        <select
                          className="form-control"
                          id="product"
                          value={campaign.product}
                          onChange={(e) => rhandle(e, "product")}
                        >
                          <option value="">Select a product</option>
                          {products.map((product) => (
                            <option key={product.id} value={product.id}>
                              {product.productname}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="associateprice"
                    checked={campaign.associateprice}
                    onChange={handleAssociatePrice}
                  />
                  <label className="form-check-label" htmlFor="associateprice">
                    Associate Price
                  </label>
                </div>
                </>
                  )}
                  <input
                    type="submit"
                    value={campaign.id ? "UPDATE" : "SAVE"}
                    className="btn btn-primary"
                    disabled={loading}
                  />
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <Button variant="secondary" onClick={onRequestClose}>
                    Close
                  </Button>
                </form>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
      <ToastContainer />
    </>
  );
};

export default AddCampaigns;
