import React, { useEffect, useState, useRef } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "../api/baseurl";
import AdminDashboard from "./admindashboard";
import { useNavigate, useParams } from "react-router-dom";
import { Link } from "react-router-dom";

import "./Spinner.css";

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let navigate = useNavigate();
    let params = useParams();

    return <Component {...props} params={params} navigate={navigate} />;
  }
  return ComponentWithRouterProp;
}

function Updateopenstock(props) {
  const date = new Date();

  let day = date.getDate().toString().padStart(2, "0");
  let month = (date.getMonth() + 1).toString().padStart(2, "0");
  let year = date.getFullYear();
  let currentDate = `${year}-${month}-${day}`;

  const searchDate = props.params.searchDate;
 const orgcode=JSON.parse(localStorage.getItem("data")).orgcode;

  const [openstock, setOpenstock] = useState({
    date: searchDate,
    orgcode: JSON.parse(localStorage.getItem("data")).orgcode,
    prodDo: [{ pid: "", qty: "", status: "", productname: "" }],
  });

  const [openingstock, setOpeningstock] = useState({
    date: currentDate,
    orgcode: JSON.parse(localStorage.getItem("data")).orgcode,
    prodDo: [{ pid: "", qty: "", status: "", productname: "" }],
  });
  const [openstock1, setOpenstock1] = useState({
    date: searchDate,
    orgcode: JSON.parse(localStorage.getItem("data")).orgcode,
    prodDo: [{ pid: "", qty: "", status: "", productname: "" }],
  });

  const [openingstock1, setOpeningstock1] = useState({
    date: currentDate,
    orgcode: JSON.parse(localStorage.getItem("data")).orgcode,
    prodDo: [{ pid: "", qty: "", status: "", productname: "" }],
  });

  const [product1, setProduct1] = useState([]);
  const [productstate1, setProductstatus1] = useState([]);
  const [product, setProduct] = useState([]);
  const [productstate, setProductstatus] = useState([]);
  const [productstate2, setProductstatus2] = useState([]);
  const [product2, setProduct2] = useState([]);
  const [productTypeFilter, setProductTypeFilter] = useState(1); // New state for product type filter
  const [productTypes, setProductTypes] = useState([]);

  const [loading, setLoading] = useState(false);
  const inputRef = useRef(null);
  const [loader, setLoader] = useState(false);
  const [quantity, setQuantity] = useState([
    {
      quantity1: "",
      quantity2: "",
      quantity3: "",
    },
  ]);

  useEffect(() => {
    const fetchProductTypes = async () => {
      try {
        const response = await axios.get(`/api/productcategorytype`, {
          headers: { Authorization: `Bearer ${JSON.parse(localStorage.getItem("data")).token}` },
        });
        setProductTypes(response.data);
      } catch (error) {
        console.error('Error fetching product types:', error);
      }
    };

    fetchProductTypes();
  }, []);

  const navigate = useNavigate();
  const url = "/api/Openingstock/update";

  const getData = async () => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    setLoader(true);

    try {
      // Fetch all products
      const trans = await axios.get(
        `/api/product/productstatus=true/orgcode=${JSON.parse(localStorage.getItem("data")).orgcode}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

        // Fetch openstock data
        const openStockResponse = await axios.get(`/api/OpeningstockDo/orgid=${orgcode}/date=${searchDate}`, {
          headers: { Authorization: `Bearer ${token}` },
        });

      const filteredProducts = trans.data.filter(
        (product) => product.productCategory?.productCategoryTypeId === 2
      );

      setProduct(filteredProducts);

      if (filteredProducts.length === 0) {
        toast.error("No products of type 'Sales' found.");
      }

      const ps = await axios.get("/api/entitytype", {
        headers: { Authorization: `Bearer ${token}` },
      });

      setProductstatus(ps.data);

      const proddo = [];
      filteredProducts.forEach((element) => {
        const item = { pid: element.productcode, productname: element.productname };
        ps.data.forEach((psitem) => {
          item[psitem.producttype] = 0;
        });
        proddo.push(item);
      });

       // Map openStock data to `proddo`
       const openstockData = openStockResponse.data;
       const updatedProdDo = proddo.map((item) => {
         const existingProduct = openstockData.prodDo.find(
           (p) => p.product.productcode === item.pid
         );
         
         if (existingProduct) {
           existingProduct.pdo.forEach(pdoItem => {
             if (pdoItem.status === 'full') {
               item.full = pdoItem.qty;
             } else if (pdoItem.status === 'empty') {
               item.empty = pdoItem.qty;
             } else if (pdoItem.status === 'defective') {
               item.defective = pdoItem.qty;
             }
           });
         }
         
         return item;
       });
   
       setOpenstock({
         ...openstock,
         date: openstockData.date,
         prodDo: updatedProdDo,
       });
   

      const trans1 = await axios.get(`/api/Openingstock/orgcode=${JSON.parse(localStorage.getItem("data")).orgcode}/datetime=${searchDate}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      setOpeningstock(trans1.data);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      handleError(error);
    }
  };

  useEffect(() => {
    getData();
  }, []);


  function onClickUpdate(e) {
    const token = JSON.parse(localStorage.getItem("data")).token;
    setLoader(true);

    const newpro = [];
    openstock.prodDo.forEach((element) => {
      productstate.forEach((psitem) => {
        const item = {
          pid: element.pid,
          status: psitem.producttype,
          qty: parseInt(element[psitem.producttype]),
          productname: element.productname,
        };

        newpro.push(item);
      });
    });

    openstock1.prodDo.forEach((element) => {
      productstate.forEach((psitem) => {
        const item = {
          pid: element.pid,
          status: psitem.producttype,
          qty: parseInt(element[psitem.producttype]),
          productname: element.productname,
        };

        newpro.push(item);
      });
    });

    const reqbody = {
      datetime: openstock.date,
      orgcode: JSON.parse(localStorage.getItem("data")).orgcode,
      prodDo: newpro,
    };

    axios
      .post("/api/Openingstock/update", reqbody, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        toast.success("Opening stock updated successfully");
        navigate("/users/Stockadjustment");
        setLoader(false);
      })
      .catch((error) => {
        setLoader(false);
        handleError(error);
      });
  }

  async function rhandle(e, field, index) {
    let actualValue = e.target.value;
  
    // Determine if the event is a delete or backspace
    const isDeleteOrBackspace = e.nativeEvent.inputType === 'deleteContentBackward' || e.nativeEvent.inputType === 'deleteContentForward';
  
    if (isDeleteOrBackspace) {
      actualValue = '0'; // Set to default value for delete or backspace
    } else if (actualValue.trim() === '') {
      actualValue = '0'; // Set to default value if input is empty
    }
  
    const newproddo = [...openstock.prodDo];
    newproddo[index] = { ...openstock.prodDo[index], [field]: actualValue };
    const newos = { ...openstock, prodDo: newproddo };
    if (field === "date") {
      newos.date = actualValue;
    }
  
    setOpenstock(newos);
  
    // Reload data only for delete or backspace
    if (isDeleteOrBackspace) {
      try {
        await getData();
      } catch (error) {
        console.error("Error reloading data:", error);
        // Optionally, you can handle errors here
      }
    } else {
      // For other input events (e.g., typing), just update the state
      const newproddo = [...openstock.prodDo];
      newproddo[index] = { ...openstock.prodDo[index], [field]: actualValue };
      const newos = { ...openstock, prodDo: newproddo };
      
      if (field === "date") {
        newos.date = actualValue;
      }
      
      setOpenstock(newos);
    }
  }

  
  //Refill
  const getData1 = async () => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    setLoader(true);
  
    try {
      // Fetch all products
      const trans = await axios.get(
        `/api/product/productstatus=true/orgcode=${JSON.parse(localStorage.getItem("data")).orgcode}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
  
      // Fetch openstock data
      const openStockResponse = await axios.get(`/api/OpeningstockDo/orgid=${orgcode}/date=${searchDate}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
  
      // Filter products by productType: "Refill Sales"
      const filteredProducts1 = trans.data.filter(
        (product) => product.productCategory?.productCategoryTypeId === 1
      );
  
      setProduct1(filteredProducts1);
  
      if (filteredProducts1.length === 0) {
        toast.error("No products of type 'Refill' found.");
      }
  
      // Fetch product statuses
      const ps1 = await axios.get("/api/entitytype", {
        headers: { Authorization: `Bearer ${token}` },
      });
  
      setProductstatus1(ps1.data);
  
      // Prepare `proddo` array
      const proddo = filteredProducts1.map((element) => {
        const item = { 
          pid: element.productcode, 
          productname: element.productname,
          full: 0,
          empty: 0,
          defective: 0
        };
        return item;
      });
  
      // Map openStock data to `proddo`
      const openstockData = openStockResponse.data;
      const updatedProdDo = proddo.map((item) => {
        const existingProduct = openstockData.prodDo.find(
          (p) => p.product.productcode === item.pid
        );
        
        if (existingProduct) {
          existingProduct.pdo.forEach(pdoItem => {
            if (pdoItem.status === 'full') {
              item.full = pdoItem.qty;
            } else if (pdoItem.status === 'empty') {
              item.empty = pdoItem.qty;
            } else if (pdoItem.status === 'defective') {
              item.defective = pdoItem.qty;
            }
          });
        }
        
        return item;
      });
  
      setOpenstock1({
        ...openstock1,
        date: openstockData.date,
        prodDo: updatedProdDo,
      });
  
      console.log("Updated openstock1:", openstock1);
      const trans1 = await axios.get(`/api/Openingstock/orgcode=${JSON.parse(localStorage.getItem("data")).orgcode}/datetime=${searchDate}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      setOpeningstock1(trans1.data);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      handleError(error);
    }
  };
  
  useEffect(() => {
    getData1();
  }, []);

  // function onClickUpdate1(e) {
  //   const token = JSON.parse(localStorage.getItem("data")).token;

  //   const newpro = [];
  //   openstock1.prodDo.forEach((element) => {
  //     productstate1.forEach((psitem) => {
  //       const item = {
  //         pid: element.pid,
  //         status: psitem.producttype,
  //         qty: parseInt(element[psitem.producttype]),
  //         productname: element.productname,
  //       };

  //       newpro.push(item);
  //     });
  //   });

  //   const reqbody = {
  //     datetime: openstock1.date,
  //     orgcode: JSON.parse(localStorage.getItem("data")).orgcode,
  //     prodDo: newpro,
  //   };

  //   axios
  //     .post("/api/Openingstock/update", reqbody, {
  //       headers: { Authorization: `Bearer ${token}` },
  //     })
  //     .then((response) => {
  //       toast.success("Opening stock updated successfully");
  //       navigate("/users/stock");
  //     })
  //     .catch((error) => {
  //       handleError(error);
  //     });
  // }

  async function rhandle1(e, field, index) {
    let actualValue = e.target.value;
  
    // Handle specific key events and empty values
    if (e.nativeEvent.inputType === 'deleteContentBackward' || e.nativeEvent.inputType === 'deleteContentForward') {
      actualValue = '0'; // Set to default value for backspace or delete
      // Update the local state
      const newproddo = [...openstock1.prodDo];
      newproddo[index] = { ...openstock1.prodDo[index], [field]: actualValue };
      const newos = { ...openstock1, prodDo: newproddo };
      
      if (field === "date") {
        newos.date = actualValue;
      }
      
      setOpenstock1(newos);
  
      // Call getData1 to reload data after state update
      try {
        await getData1();
      } catch (error) {
        console.error("Error reloading data:", error);
        // Optionally, you can handle errors here
      }
    } else {
      // For other input events (e.g., typing), just update the state
      const newproddo = [...openstock1.prodDo];
      newproddo[index] = { ...openstock1.prodDo[index], [field]: actualValue };
      const newos = { ...openstock1, prodDo: newproddo };
      
      if (field === "date") {
        newos.date = actualValue;
      }
      
      setOpenstock1(newos);
    }
  }
  
  function handleError(error) {
    if (error.response && error.response.status === 401) {
      navigate(`/signin`);
      toast.error("Your session has expired. Please login.");
    } else {
      if (error.response && error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error('An error occurred.');
      }
    }
  }

  return (
    <div>
      <div>
        <AdminDashboard />
      </div>

      <br />
      <div
        style={{ position: "relative", top: "100px", left: "150px" }}
        className="col-8 mx-xl-auto ps-xl-6"
      >
        <div className="card card-raised">
          <div className="card-body">
            <div className="d-flex">
              <div className="card-title">OpenStock Update
           
              </div>
              {loader && <div className="loading"></div>}
            </div>
            
            <br />
            <div>
              <label htmlFor="date">Date: </label>
              <input
                id="date"
                type="date"
                value={openstock.date}
                onChange={(e) => rhandle(e, "date")}
              />
            </div>
            <div style={{ marginLeft: "38%", top: "5px" }}>
              <div className="filter-container d-flex align-items-center">
                <select
                  value={productTypeFilter}
                  disabled
                  onChange={(e) => setProductTypeFilter(Number(e.target.value))}
                  style={{
                    width: '200px',
                    height: '45px',
                    fontSize: '16px',
                    padding: '10px 14px',
                    border: 'none',
                    borderRadius: '8px',
                    backgroundColor: '#f8f9fa',
                    color: '#495057',
                    fontFamily: 'Segoe UI, Tahoma, Geneva, Verdana, sans-serif',
                    cursor: 'pointer',
                    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                    transition: 'background-color 0.3s, box-shadow 0.3s, border-color 0.3s',
                    outline: 'none',
                    appearance: 'none',
                    backgroundImage: 'url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\' viewBox=\'0 0 16 16\' fill=\'none\'><path d=\'M8 12a1 1 0 0 1-.707-1.707L11.586 6 7.293 1.707A1 1 0 0 1 8 0a1 1 0 0 1 .707 1.707L5.414 6l3.293 3.293A1 1 0 0 1 8 12z\' fill=\'#495057\'/></svg>")',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'right 10px center',
                    backgroundSize: '16px 16px',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                  }}
                  onFocus={(e) => {
                    e.target.style.backgroundColor = '#e9ecef';
                    e.target.style.boxShadow = '0px 6px 8px rgba(0, 0, 0, 0.2)';
                  }}
                  onBlur={(e) => {
                    e.target.style.backgroundColor = '#f8f9fa';
                    e.target.style.boxShadow = '0px 4px 6px rgba(0, 0, 0, 0.1)';
                  }}
                >
                  <option value="" disabled hidden>Select Product Type</option>
                  {productTypes.map((type) => (
                    <option key={type.id} value={type.id}>
                      {type.categorytype}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <br />
           
            {productTypeFilter === 1 ? (
  <>
            <table className="table table-bordered">
              <thead>
                <tr
                  style={{
                    fontSize: "17px",
                    backgroundColor: "#6200ea",
                    color: "white",
                    textAlign: "center",
                  }}
                >
                  <th>Product</th>
                  <th>Full</th>
                  <th>Empty</th>
                  <th>Defective</th>
                </tr>
              </thead>
              <tbody>
                {openstock1.prodDo.map((products, index) => (
                  <tr key={index}>
                    <td>{products.productname}</td>
                    <td>
                      <input
                        type="text"
                        placeholder={products.full || "0"}
                        // value={products.full || ""}
                        style={{ border: "none" }}
                        onChange={(e) => rhandle1(e, "full", index)}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        placeholder={products.empty || "0"}
                        // value={products.empty || ""}
                        style={{ border: "none" }}
                        onChange={(e) => rhandle1(e, "empty", index)}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        placeholder={products.defective || "0"}
                        // value={products.defective || ""}
                        style={{ border: "none" }}
                        onChange={(e) => rhandle1(e, "defective", index)}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            </>
          ) : null}
         
            {productTypeFilter === 2 ? (
            <>
            <table className="table table-bordered">
              <thead>
                <tr
                  style={{
                    fontSize: "17px",
                    backgroundColor: "#6200ea",
                    color: "white",
                    textAlign: "center",
                  }}
                >
                  <th>Product</th>
                  <th>Quantity</th>
                 
                </tr>
              </thead>
              <tbody>
                {openstock.prodDo.map((products, index) => (
                  <tr key={index}>
                    <td>{products.productname}</td>
                    <td>
                      <input
                        type="text"
                        placeholder={products.full || "0"}
                        // value={products.full || ""}
                        style={{ border: "none" }}
                        onChange={(e) => rhandle(e, "full", index)}
                      />
                    </td>
                  </tr>
                  
                ))}
              </tbody>
            </table>
            </>
          ) : null}
          
           <div>
            <button
              className="btn btn-primary"
              type="submit"
              onClick={(e) => onClickUpdate(e)}
            >
              Update 
            </button>
            <Link  to="/users/Stockadjustment" style={{textDecoration:"none", position: "absolute", left: "150px"}}>
 <button className="btn btn-secondary" type="button">Back</button>
  </Link>
  </div>
          
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(Updateopenstock);
